import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {Auth} from "aws-amplify";
import axios from "axios";
import moment from "moment";
import {Chart} from "react-google-charts";
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";
import {
    Backdrop,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {CSVLink} from "react-csv/lib";
import {SensorThresholds} from "../../constants";

const SensorGraph = () => {

    const history = useHistory()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingText, setIsLoadingText] = useState("")
    const robotSensorsId = location.search.split("=")[1]

    const [graphData, setGraphData] = useState([])
    const [robotSensor, setRobotSensor] = useState({})
    const [robot, setRobot] = useState({})
    const [sensor, setSensor] = useState({})

    const [csvData, setCsvData] = useState([])
    const csvLink = useRef()

    const [timeInterval, setTimeInterval] = useState(5)
    const timeIntervalRef = useRef(5)

    // const chartAxis = [{type: "datetime", label: "Time"}, {type: "number", label: "Sensor Reading"}]

    const generateChartOptions = (sensor) => {
        var threshold = SensorThresholds[sensor.sensorId]

        return {
            title: sensor.sensorName,
            curveType: "continuous",
            hAxis: {
                format: "dd-MM-yyyy HH:mm",
                gridlines: {
                    units: {
                        hours: {format: ['HH:mm', 'ha']},
                        minutes: {format: ['HH:mm a Z', ':mm']}
                    }
                },
                minorGridlines: {
                    units: {
                        hours: {format: ['hh:mm:ss a', 'ha']},
                        minutes: {format: ['HH:mm a Z', ':mm']}
                    }
                }
            },
            explorer: {
                actions: ['dragToZoom', 'rightClickToReset'],
                axis: 'horizontal',
                keepInBounds: true,
                maxZoomIn: 0.05,
                maxZoomOut: 1
            },
            legend: {position: 'none'},
            isStacked: true,
            series: {
                0: {type: "line", pointSize: 1, viewWindowMode: 'maximized'},
                1: {
                    lineWidth: 0,
                    type: 'area',
                    visibleInLegend: false,
                    enableInteractivity: false,
                    color: threshold.direction === "greater" ? "green" : "red",
                },
                2: {
                    lineWidth: 0,
                    type: 'area',
                    visibleInLegend: false,
                    enableInteractivity: false,
                    color: threshold.direction === "greater" ? "red" : "green",
                }
            }
        }
    }

    const generateChartAxis = (sensor) => {
        return [{type: "datetime", label: "Time"}, {type: "number", label: sensor.sensorName}, {
            role: "style",
            type: "string"
        }, {type: "number", label: "color band 1"}, {type: "number", label: "color band 2"}]
    }

    const formatChartData = (datum, sensorId) => {
        let chartData = []
        let threshold = SensorThresholds[sensorId].value

        chartData = chartData.concat(datum.map((data) => {
            return [
                moment.utc(data.creationTimestamp).local().toDate(),
                data.data,
                pointColor(data.data, sensorId),
                threshold,
                100 - threshold
            ]
        }))

        return chartData;
    }

    const GraphTimeScaleDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Time Interval</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timeInterval}
                    label="Log Type"
                    onChange={(e) => {
                        setTimeInterval(e.target.value)
                        timeIntervalRef.current = e.target.value
                        getGraphData(e.target.value).then(() => {
                        })
                    }}
                >
                    <MenuItem value={5}>5 minutes</MenuItem>
                    <MenuItem value={10}>10 minutes</MenuItem>
                    <MenuItem value={15}>15 minutes</MenuItem>
                    <MenuItem value={30}>30 minutes</MenuItem>
                    <MenuItem value={60}>60 minutes</MenuItem>
                </Select>
            </FormControl>
        )
    }

    const pointColor = (value, sensorId) => {
        var threshold = SensorThresholds[sensorId]

        if (threshold.direction === "greater") {
            if (value > threshold.value)
                return "red"
            else
                return "green"
        } else {
            if (value < threshold.value)
                return "red"
            else
                return "green"
        }
    }

    const getGraphData = async (timeInterval) => {
        setIsLoadingText("Getting Sensor Data")
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        await axios.post("/api/sensordatummethods/graphData", {
            RobotSensorsIds: [robotSensorsId],
            TimeInterval: timeInterval,
            Timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                setGraphData([generateChartAxis(sensor), ...formatChartData(response.data, sensor.sensorId)])
            })
        setIsLoadingText("")
    }

    const generateCsv = (data) => {
        data.shift()
        setCsvData([["Timestamp", "Data" + " (" + sensor.units + ")"], ...data])
    }

    useEffect(() => {
        let localSensor = {}

        async function fetchGraphData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            let localRobotSensor = {}

            setIsLoadingText("Getting Sensor and Robot information")
            await axios.get("/api/robotsensorsmethods/getsensor/" + robotSensorsId + "/" + email,
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    localRobotSensor = response.data
                    setRobotSensor(response.data)
                })

            await axios.get("/api/robotmethods/robot/" + localRobotSensor.robotId + "/" + email,
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setRobot(response.data)
                })

            await axios.get("/api/sensormethods/" + localRobotSensor.sensorId + "/" + email,
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    localSensor = response.data
                    setSensor(response.data)
                })

            setIsLoadingText("Getting Sensor Data")
            await axios.post("/api/sensordatummethods/graphData", {
                RobotSensorsIds: [robotSensorsId],
                TimeInterval: timeInterval,
                Timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setGraphData([generateChartAxis(localSensor), ...formatChartData(response.data, localSensor.sensorId)])
                })

            setIsLoadingText("")
            setIsLoading(false)
        }

        fetchGraphData()

        let timestamp = moment.utc();

        const asyncInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/sensorGraph') {
                await clearIntervalAsync(asyncInterval)
            }
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.post('/api/sensordatummethods/mostrecent', {
                    RobotSensorsIds: [robotSensorsId],
                    Timestamp: timestamp,
                    Username: email
                },
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    response.data.forEach((datapoint) => {
                        if (moment.utc(datapoint.creationTimestamp).isSameOrAfter(timestamp)) {
                            timestamp = moment.utc(datapoint.creationTimestamp)
                        }
                    })

                    setGraphData((graphData) => {
                        if (response.data.length > 0) {
                            graphData.shift()
                            
                            while (graphData.length + response.data.length > timeIntervalRef.current * 60) {
                                graphData.pop()
                            }
                            
                            return [generateChartAxis(localSensor), ...formatChartData(response.data, localSensor.sensorId), ...graphData]
                        } else {
                            return graphData
                        }
                    })
                }).catch(error => {

                })
        }, 5000)

        return () => {
            clearIntervalAsync(asyncInterval).then(() => {
            })
        }
    }, [])

    return (
        <div className="SensorGraph">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoadingText.length > 0}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className="header">
                <div className="title">
                    Sensor Graph
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            {!isLoading && <div className="content-container">
                <div className="graph-container">
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="100%"
                        data={graphData}
                        options={generateChartOptions(sensor)}
                    />
                </div>
                <div className="graph-options">
                    {GraphTimeScaleDropdown()}
                    <Tooltip
                        title="Download Data"
                        className="download-tooltip"
                        onClick={() => {
                            generateCsv(graphData)
                            setTimeout(() => {
                                csvLink.current.link.click()
                            })
                        }}
                    >
                        <div>
                            <IconButton aria-label="add">
                                <DownloadIcon/>
                            </IconButton>
                        </div>
                    </Tooltip>
                    {!isLoading && <CSVLink data={csvData} filename={robot.robotName + "-" + sensor.sensorName + ".csv"}
                                            ref={csvLink}
                                            target="_blank"/>}
                </div>
            </div>}
        </div>
    )
}

export default withRouter(SensorGraph);