import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {useHistory} from "react-router-dom";

const ConfirmationDialog = ({openConfirmationDialog, setOpenConfirmationDialog}) => {
    
    const history = useHistory();
    
    return (
        <Dialog open={openConfirmationDialog.open}>
            <DialogContent>
                <DialogContentText>
                    {openConfirmationDialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpenConfirmationDialog({open: false, message: ""})
                    history.go()
                }}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog