import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    TextField
} from "@mui/material";
import React, {useState} from "react";

import axios from "axios";
import moment from "moment";
import { Auth } from 'aws-amplify';

const AgnetMessageDialog = ({openAgnetDialog, setOpenAgnetDialog, setAgnetMessageStatus}) => {
    
    const [agnetMessage, setAgnetMessage] = useState("")
    const [dialogError, setDialogError] = useState(false)
    
    const handleSendAgnetMessage = async () => {
        setDialogError(false)
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "AGNETMESSAGE",
            robotParams: agnetMessage,
            robotId: openAgnetDialog.robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setAgnetMessage("")
                setAgnetMessageStatus({open: true, message: "Agnet Message sent successfully"})
                setOpenAgnetDialog({open: false, robotId: null})
            }).catch(() => {
                setAgnetMessage("")
                setAgnetMessageStatus({open: true, message: "Agnet Message could not be sent at this time. Please try again"})
                setOpenAgnetDialog({open: false, robotId: null})
            })
    }
    
    return (
        <Dialog open={openAgnetDialog.open}>
            <DialogTitle>Agnet Message</DialogTitle>
            <DialogContent
                id="agnet-settings-dialog"
                style={{display: "flex", flexDirection: "column"}}
            >
                <DialogContentText style={{marginBottom: "1rem"}}>
                    Please type your message below:
                </DialogContentText>

                <div className="form-container">
                    <TextField
                        error={dialogError && !agnetMessage}
                        fullWidth
                        margin="dense"
                        id="outlined-multiline-static"
                        label="Agnet Message"
                        multiline
                        rows={4}
                        value={agnetMessage}
                        onChange={(e) => {
                            setAgnetMessage(e.target.value)
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{padding: "1rem"}}>
                <Button onClick={() => {
                    setAgnetMessage("")
                    setDialogError(false)
                    setOpenAgnetDialog({open: false, robotId: null})
                }}>Cancel</Button>
                <Button onClick={() => {
                    handleSendAgnetMessage()
                }} variant="contained" style={{
                    backgroundColor: "rgb(25, 118, 210)",
                    borderRadius: ".25rem",
                    padding: ".375rem 1rem",
                    color: "white"
                }}>Send Message</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AgnetMessageDialog;