import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import axios from "axios";
import {Alert, Backdrop, CircularProgress, IconButton, Pagination, Snackbar, Tooltip} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import NumericalTile from "./NumericalTile";
import EventTable from "./EventTable";
import SensorSettingsDialog from "./SensorSettingsDialog";

const SensorData = () => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingText, setIsLoadingText] = useState("")

    const [robots, setRobots] = useState([])
    const [robotSensors, setRobotSensors] = useState([])
    const [sensors, setSensors] = useState([])
    const [sensorSettings, setSensorSettings] = useState([])

    const [numericalTiles, setNumericalTiles] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [openSensorSettingsDialog, setOpenSensorSettingsDialog] = useState(false)

    const [messageStatus, setMessageStatus] = useState({
        open: false,
        message: "",
        severity: ""
    })

    const generateNumericalTiles = (tempRobots, tempSensors, tempRobotSensors) => {
        let idCounter = 0;

        let tiles = []

        tempRobots.forEach((robot) => {
            idCounter++;
            let mySensors = tempRobotSensors.filter((robotSensor) => robotSensor.robotId == robot.robotId)

            if (mySensors.length > 10) {
                const mySensorsChunks = []
                for (let i = 0; i < mySensors.length; i += 10) {
                    const chunk = mySensors.slice(i, i + 10)
                    mySensorsChunks.push(chunk)
                }

                let additionalTileNumber = 1
                mySensorsChunks.forEach((groupOfSensors) => {
                    tiles.push(
                        <NumericalTile key={`${idCounter}-${additionalTileNumber}`} robot={robot}
                                              sensors={tempSensors}
                                              myRobotSensors={groupOfSensors} tileNumber={additionalTileNumber++}/>
                    )
                })
            } else {
                tiles.push(
                    <NumericalTile key={idCounter} robot={robot} sensors={tempSensors}
                                          myRobotSensors={mySensors} tileNumber={1}/>
                )
            }
        })

        let tilePages = []
        for (let i = 0; i < tiles.length; i = i + 18)
            tilePages.push(tiles.slice(i, i + 18))

        setNumericalTiles(tilePages)
    }

    useEffect(() => {
        let localRobotSensors;

        async function getInfo() {
            let tempRobots;

            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            //TODO: Fetch Sensor Data somehow
            setIsLoadingText("Fetching Robots")
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setRobots(response.data)
                    tempRobots = response.data
                }).catch((error) => {

                })

            let tempRobotSensors = []

            setIsLoadingText("Fetching Sensor Info")
            for (const robot of tempRobots) {
                await axios.get('/api/robotsensorsmethods/' + robot.robotId + "/" + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then((response) => {
                        tempRobotSensors = tempRobotSensors.concat(response.data)
                    }).catch((error) => {

                    })
            }
            localRobotSensors = tempRobotSensors

            const sensorIds = [...new Set(tempRobotSensors.map(robotSensor => robotSensor.sensorId))];

            let tempSensors;

            await axios.post('/api/sensormethods', {
                SensorIds: sensorIds,
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setSensors(response.data)
                    tempSensors = response.data
                })

            setIsLoadingText("Fetching Sensor Settings")
            await axios.post('/api/sensorsettingsmethods', {
                RobotSensorIds: tempRobotSensors.map((robotSensor) => robotSensor.robotSensorsId),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setSensorSettings(response.data)
                })

            setRobotSensors(tempRobotSensors)
            generateNumericalTiles(tempRobots, tempSensors, tempRobotSensors)
            setIsLoading(false)
            setIsLoadingText("")
            // getNewDatapoints(tempRobotSensors, localRecentData)
        }

        getInfo()
    }, [])

    return (
        <div className="SensorData">
            <Snackbar open={messageStatus.open} autoHideDuration={5000}
                      onClose={() => setMessageStatus({open: false, message: "", severity: ""})}>
                <Alert onClose={() => setMessageStatus({open: false, message: "", severity: ""})} severity={messageStatus.severity}
                       sx={{width: '100%'}}>
                    {messageStatus.message}
                </Alert>
            </Snackbar>
            {!isLoading && <SensorSettingsDialog
                openSensorSettingsDialog={openSensorSettingsDialog}
                setOpenSensorSettingsDialog={setOpenSensorSettingsDialog}
                robots={robots}
                sensors={sensors}
                robotSensors={robotSensors}
                sensorSettings={sensorSettings}
                setIsLoadingText={setIsLoadingText}
                setMessageStatus={setMessageStatus}
                setSensorSettings={setSensorSettings}
            />}
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || isLoadingText}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className="header">
                <div className="title">
                    Sensor Data
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            <div className="content-container">
                <div className="view-options">
                    <Tooltip
                        title="Settings"
                        onClick={() => setOpenSensorSettingsDialog(true)}
                    >
                        <div>
                            <IconButton aria-label="add">
                                <SettingsIcon/>
                            </IconButton>
                        </div>
                    </Tooltip>

                </div>
                <div className="data-container">
                    <div className="view-container">
                        <div className="tile-container">
                            {!isLoading && numericalTiles[currentPage - 1]}
                        </div>
                        {!isLoading &&
                            <Pagination className="page-scroller" count={numericalTiles.length} page={currentPage}
                                        onChange={(event, value) => {
                                            setCurrentPage(value)
                                        }}/>
                        }
                    </div>
                    {!isLoading && <EventTable robotSensors={robotSensors} robots={robots} sensors={sensors}
                                               sensorSettings={sensorSettings} setIsLoadingText={setIsLoadingText}
                    />}
                </div>


            </div>
        </div>
    )
}

export default React.memo(SensorData);