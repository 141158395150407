import {useHistory} from "react-router-dom";
import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import {
    Alert,
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Snackbar, Switch
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";

import axios from "axios";
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import {ReactComponent as BatteryFullIcon} from "../../assets/images/BatteryFullIcon.svg";
import AgnetMessageDialog from "../AgnetMessageDialog/AgnetMessageDialog";
import AgnetSettingsDialog from "../AgnetSettingsDialog/AgnetSettingsDialog";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import moment from "moment";
import StartLivestreamDialog from "../StartLivestreamDialog/StartLivestreamDialog";
import MediaPlayerContainer from "../MediaPlayerContainer/MediaPlayerContainer";
import MultiCamMediaPlayerContainer from "../MultiCamMediaPlayerContainer/MultiCamMediaPlayerContainer";
import shaka from "shaka-player";
import { Man } from "@mui/icons-material";
import { Auth } from 'aws-amplify';
import CreateContactEmailDialog from "../CreateContactEmailDialog/CreateContactEmailDialog";

const LiveStream = () => {

    const history = useHistory()
    const defaultFleet = useMemo(() => ({
        fleetName: "All",
        fleetId: 0,
        fleetLocation: "-98.3734906468881,38.8825300889946"
    }), [])
    const [robotInfo, setRobotInfo] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [fleetRobots, setFleetRobots] = useState([]);
    const [currentFleet, setCurrentFleet] = useState(defaultFleet);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [agnetAccess, setAgnetAccess] = useState(false)
    
    const [openAgnetDialog, setOpenAgnetDialog] = useState({open: false, robotId: null})
    const [openAgnetSettings, setOpenAgnetSettings] = useState({open: false, robotId: null})
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState({open: false, message: ""})
    const [openStartLivestreamDialog, setOpenStartLivestreamDialog] = useState({open: false, robotId: null})
    const [messageStatus, setMessageStatus] = useState({open: false, message: ""})
    
    const [multiCamView, setMultiCamView] = useState(false);
    const screenWidth = window.innerWidth
    
    const [currentLivestreams, setCurrentLivestreams] = useState([])

    const getBatteryIcon = (batteryPct) => {
        if (batteryPct === 0) {
            return <BatteryAlertIcon className="battery-icon battery-danger-level"/>
        } else if (batteryPct < 15) {
            return <Battery1BarIcon className="battery-icon battery-danger-level"/>
        } else if (batteryPct < 30) {
            if (batteryPct < 25) {
                return <Battery2BarIcon className="battery-icon battery-danger-level"/>
            }
            return <Battery2BarIcon className="battery-icon"/>
        } else if (batteryPct < 45) {
            return <Battery3BarIcon className="battery-icon"/>
        } else if (batteryPct < 60) {
            return <Battery4BarIcon className="battery-icon"/>
        } else if (batteryPct < 75) {
            return <Battery5BarIcon className="battery-icon"/>
        } else if (batteryPct < 90) {
            return <Battery6BarIcon className="battery-icon"/>
        } else {
            return <BatteryFullIcon
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root battery-icon"/>
        }
    }

    const hasAgnetSettings = (robot) => {
        if (robot.agnetChannel && robot.agnetUserName && robot.agnetPassword && robot.agnetSubscriberId && robot.agnetClientId && robot.agnetClientSecret && robot.agnetUrltoken && robot.agnetUrlmessage) {
            return true
        }
        return false
    }

    const sendArchiveCommand = async (robotId) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "RETAINVIDEO",
            robotParams: "Normal",
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Mission has been marked to be archived. Upload to the archive should occur after the mission is complete."
                })
            }).catch(() => {
                setMessageStatus({open: true, message: "Mission could not be archived at this time. Please try again."})
            })
    }

    const sendStopCommand = async (robotId) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "LIVESTREAM",
            robotParams: "STOP",
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Livestream has been successfully stopped."
                })
            }).catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Livestream could not be stopped at this time. Please try again"
                })
            })
    }
    
    const sendChangeCameraCommand = async (robotId) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "VIDEOSELECTION",
            robotParams: "SWITCH",
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Change Camera Command successfully sent."
                })
            }).catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Change Camera Command could not be sent at this time. Please try again"
                })
            })
    }
    
    const sendGoHomeCommand = async (robotId) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "ACTION",
            robotParams: "HOME",
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Go Home command issued successfully."
                })
            }).catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Go Home command could not be issued. Please try again"
                })
            })
    }

    const sendStopMissionCommand = async (robotId) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "MISSION",
            robotParams: "STOP",
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Stop Mission command issued successfully."
                })
            }).catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Stop Mission command could not be issued. Please try again"
                })
            })
    }
    
    const sendGainChangeCommand = async (robotId, value) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "CAMERAGAIN",
            robotParams: value,
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {})
            .catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Could not change camera gain at this time. Please try again"
                })
            })
    }
    
    const sendExposureChangeCommand = async (robotId, value) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "CAMERAEXPOSURE",
            robotParams: value,
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {})
            .catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Could not change camera exposure at this time. Please try again"
                })
            })
    }
    
    const sendCameraNumberChangeCommand = async (robotId, value) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "CAMERASELECT",
            robotParams: value,
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {})
            .catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Could not change camera at this time. Please try again"
                })
            })
    }

    var manifestUrl;
    
    const generateStream = () => {
        var video = document.getElementById('video');
        var player = new shaka.Player(video);
        window.player = player;
        
        const tempEndpoint = "https://missioncontroldev-usct.streaming.media.azure.net/0d084df9-1bf0-49b5-ad6d-16ab531ad9fa/output.ism/manifest(format=mpd-time-cmaf,encryption=cenc)?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cm46bWljcm9zb2Z0OmF6dXJlOm1lZGlhc2VydmljZXM6Y29udGVudGtleWlkZW50aWZpZXIiOiJmMmM0MzY0Ny04M2JiLTQ5NTgtODJmZi03MmRiMDBhY2ZlOWMiLCJuYmYiOjE2NjQ0ODE1NzIsImV4cCI6MTY2NDQ4NTQ3MiwiaXNzIjoibXlJc3N1ZXIiLCJhdWQiOiJteUF1ZGllbmNlIn0.r7bM5Vz0dhQyYnwGIwO5fhehiJbPQgMN8-jY9iFSU8M"
        // const livestream = robot.liveStreamEndpoints.split("?token=")
        const livestream = tempEndpoint.split("?token=")

        console.log(livestream)

        if (livestream.length > 1) {
            player.getNetworkingEngine().registerRequestFilter((type, request) => {
                if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                    request.headers['Authorization'] = 'Bearer ' + livestream[1]
                }
            })
            window.player = player;
        }

        manifestUrl = livestream[0]

        player.load(manifestUrl)
    }
    
    const generateCurrentLivestreams = (robotInfo, fleetRobots, currentFleet) => {
        let livestreams = robotInfo.map(robot => {
            
            const associatedFleetRobot = fleetRobots.find(fleetRobot => fleetRobot.robotId === robot.robotId && fleetRobot.fleetId === currentFleet.fleetId)

            if ((currentFleet.fleetName !== "All" && associatedFleetRobot !== undefined) || (currentFleet.fleetName === "All")) {

                return (
                    <MediaPlayerContainer
                        key={robotInfo.indexOf(robot)}
                        index={robotInfo.indexOf(robot)}
                        robot={robot}
                        setOpenStartLivestreamDialog={setOpenStartLivestreamDialog}
                        sendArchiveCommand={sendArchiveCommand}
                        sendStopCommand={sendStopCommand}
                        sendGoHomeCommand={sendGoHomeCommand}
                        sendStopMissionCommand={sendStopMissionCommand}
                        sendChangeCameraCommand={sendChangeCameraCommand}
                        sendGainChangeCommand={sendGainChangeCommand}
                        sendExposureChangeCommand={sendExposureChangeCommand}
                        sendCameraNumberChangeCommand={sendCameraNumberChangeCommand}
                        setMessageStatus={setMessageStatus}
                        setOpenAgnetSettings={setOpenAgnetSettings}
                        agnetAccess={agnetAccess}
                        setOpenAgnetDialog={setOpenAgnetDialog}
                        hasAgnetSettings={hasAgnetSettings}
                        getBatteryIcon={getBatteryIcon}
                    />
                )
            } else {

            }
        })

        livestreams = livestreams.filter((stream) => stream !== undefined)

        let livestreamPages = []

        for (let i = 0; i < livestreams.length; i = i + 8)
            livestreamPages.push(livestreams.slice(i, i + 8));

        setCurrentLivestreams(livestreamPages)
        // return livestreamPages;
    }

    const generateMultiCamLiveStreams = () => {

        const livestreams = robotInfo.map(robot => {

            const associatedFleetRobot = fleetRobots.find(fleetRobot => fleetRobot.robotId === robot.robotId && fleetRobot.fleetId === currentFleet.fleetId)
             
            if ((currentFleet.fleetName !== "All" && associatedFleetRobot !== undefined) || (currentFleet.fleetName === "All")) {

                return (
                    <MultiCamMediaPlayerContainer
                        key={robotInfo.indexOf(robot)}
                        robot={robot}
                        setOpenStartLivestreamDialog={setOpenStartLivestreamDialog}
                        sendArchiveCommand={sendArchiveCommand}
                        sendStopCommand={sendStopCommand}
                        sendGoHomeCommand={sendGoHomeCommand}
                        sendStopMissionCommand={sendStopMissionCommand}
                        sendGainChangeCommand={sendGainChangeCommand}
                        sendChangeCameraCommand={sendChangeCameraCommand}
                        sendExposureChangeCommand={sendExposureChangeCommand}
                        sendCameraNumberChangeCommand={sendCameraNumberChangeCommand}
                        setMessageStatus={setMessageStatus}
                        setOpenAgnetSettings={setOpenAgnetSettings}
                        agnetAccess={agnetAccess}
                        setOpenAgnetDialog={setOpenAgnetDialog}
                        hasAgnetSettings={hasAgnetSettings}
                        getBatteryIcon={getBatteryIcon}
                    />
                )
            }
        })

        let livestreamPages = []

        for (let i = 0; i < livestreams.length; i = i + 6)
            livestreamPages.push(livestreams.slice(i, i + 6));

        return livestreamPages
    } //Needs to be updated to match above function if we are using this implementation

    useEffect(() => {
        let robotInfo = []
        let fleetRobots = []
        
        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.get('/api/fleetmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    setFleets(response.data)
                }).catch(error => {
                    if (error.response.status === 401) {
                        history.push("/Identity/Account/Login")
                        history.go(0);
                    }
                })
            await axios.get('/api/fleetrobotmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    setFleetRobots(response.data)
                    fleetRobots = response.data
                }).catch(error => {
                    if (error.response.status === 401) {
                        history.push("/Identity/Account/Login")
                        history.go(0);
                    }
                })
            await axios.get('/api/robotmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    setRobotInfo(response.data)
                    robotInfo = response.data
                }).catch(error => {
                    if (error.response.status === 401) {
                        history.push("/Identity/Account/Login")
                        history.go(0);
                    }
                })
            await axios.get('/api/featureaccessmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    response.data.find(featureAccess => featureAccess.featureId === 1) ? setAgnetAccess(true) : setAgnetAccess(false)
                }).catch(error => {
                    if (error.response.status === 401) {
                        history.push("/Identity/Account/Login")
                        history.go(0);
                    }
                })
            
            generateCurrentLivestreams(robotInfo, fleetRobots, defaultFleet)
            setIsLoading(false);
        }

        fetchData()
    }, [])

    return (
        <div className="LiveStream">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Snackbar open={messageStatus.open} autoHideDuration={5000}
                      onClose={() => setMessageStatus({open: false, message: ""})}>
                <Alert onClose={() => setMessageStatus({open: false, message: ""})} severity="success"
                       sx={{width: '100%'}}>
                    {messageStatus.message}
                </Alert>
            </Snackbar>
            <AgnetMessageDialog
                openAgnetDialog={openAgnetDialog}
                setOpenAgnetDialog={setOpenAgnetDialog}
                setAgnetMessageStatus={setMessageStatus}
            />
            {!isLoading && <AgnetSettingsDialog
                openAgnetSettings={openAgnetSettings}
                setOpenAgnetSettings={setOpenAgnetSettings}
                setOpenConfirmationDialog={setOpenConfirmationDialog}
            />}
            <StartLivestreamDialog
                openStartLivestreamDialog={openStartLivestreamDialog}
                setOpenStartLivestreamDialog={setOpenStartLivestreamDialog}
                setMessageStatus={setMessageStatus}
            />
            <ConfirmationDialog
                openConfirmationDialog={openConfirmationDialog}
                setOpenConfirmationDialog={setOpenConfirmationDialog}
            />
            <div className="header">
                <div className="title">
                    LiveView
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            <div className="settings-container">
                <div className="fleet-selection">
                    <FormControl variant="standard" sx={{
                        m: 1,
                        minWidth: 300,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <InputLabel id="demo-simple-select-standard-label">Fleet</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={currentFleet}
                            defaultValue={defaultFleet}
                            style={{width: screenWidth <= 640 ? "10rem" : "15.625rem"}}
                            onChange={e => {
                                setCurrentFleet(e.target.value)
                                setCurrentPage(1)
                                generateCurrentLivestreams(robotInfo, fleetRobots, e.target.value)
                            }}
                            label="Fleet"
                        >
                            <MenuItem value={defaultFleet}>
                                {defaultFleet.fleetName}
                            </MenuItem>

                            {fleets.map(fleet => {
                                return <MenuItem key={fleets.indexOf(fleet)}
                                                 value={fleet}>{fleet.fleetName.trim()}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="organization-container">
                    {/*{!isLoading &&*/}
                    {/*    <div>*/}
                    {/*        <span>Multi Camera View</span>*/}
                    {/*        <Switch onChange={(e) => {*/}
                    {/*            setCurrentPage(1)*/}
                    {/*            setMultiCamView(e.target.checked)*/}
                    {/*        }}/>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {!isLoading && !multiCamView &&
                        <Pagination count={currentLivestreams.length} page={currentPage} onChange={(event, value) => {
                            setCurrentPage(value)
                        }}/>
                    }
                    {/*{!isLoading && multiCamView &&*/}
                    {/*    <Pagination count={Math.ceil(livestreamRobots / 6)} onChange={(event, value) => {*/}
                    {/*        setCurrentPage(value)*/}
                    {/*    }}/>*/}
                    {/*}*/}
                </div>
            </div>
            {!multiCamView && <div className="media-player-container">
                {!isLoading && currentLivestreams[currentPage - 1]}
                {/*{!isLoading && generateCurrentLivestreams(robotInfo, fleetRobots, defaultFleet)[currentPage - 1]}*/}
            </div>}
            {/*{multiCamView && <div className="multi-media-player-container">*/}
            {/*    {!isLoading && generateMultiCamLiveStreams()[currentPage - 1]}*/}
            {/*</div>}*/}
        </div>
    )
}

export default React.memo(LiveStream);