import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, FormGroup, Slider, Switch,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";

import moment from "moment";
import { Auth } from 'aws-amplify';

const StartLivestreamDialog = ({openStartLivestreamDialog, setOpenStartLivestreamDialog, setMessageStatus}) => {
    
    const [archiveStream, setArchiveStream] = useState(true)
    const [archiveLength, setArchiveLength] = useState(1)
    
    const getArchiveLength = (value) => {
        return `${archiveLength} hr(s)`
    }
    
    const handleStartLivestream = async (robotId) => {
        let robotParams = "START:";
        
        archiveStream ? robotParams += "YES:" + archiveLength : robotParams += "NO";

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "LIVESTREAM",
            robotParams: robotParams,
            robotId: robotId,
            issueDateTime: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Livestream has been initiated, please allow 2-5 min for the Livestream to begin."
                })
                setArchiveLength(1)
                setArchiveStream(true)
                setOpenStartLivestreamDialog({
                    open: false, robotId: null
                })
            }).catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Livestream could not be started at this time. Please try again."
                })
                setArchiveLength(1)
                setArchiveStream(true)
                setOpenStartLivestreamDialog({
                    open: false, robotId: null
                })
            })
    }
    
    return (
        <Dialog open={openStartLivestreamDialog.open}>
            <DialogTitle>Agnet Settings</DialogTitle>
            <DialogContent
                id="start-livestream-dialog"
                style={{display: "flex", flexDirection: "column"}}
            >
                <DialogContentText>
                    To start a livestream, please specify the following
                </DialogContentText>

                <div className="form-container">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked={archiveStream}
                                    onChange={(e) => {
                                        setArchiveStream(e.target.checked)
                                    }}
                                />
                            }
                            label="Archive Livestream"/>
                    </FormGroup>
                    <Slider
                        aria-label="Archive Stream Length"
                        getAriaValueText={getArchiveLength}
                        getAriaLabel={getArchiveLength}
                        valueLabelFormat={getArchiveLength}
                        valueLabelDisplay="auto"
                        onChange={(e) => setArchiveLength(e.target.value)}
                        step={1}
                        marks
                        min={1}
                        max={8}
                        disabled={!archiveStream}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{padding: "1rem"}}>
                <Button onClick={() => {
                    setArchiveLength(1)
                    setArchiveStream(true)
                    setOpenStartLivestreamDialog({
                        open: false, robotId: null
                    })
                }}>Cancel</Button>
                <Button onClick={() => {
                    handleStartLivestream(openStartLivestreamDialog.robotId)
                }} variant="contained" style={{
                    backgroundColor: "rgb(25, 118, 210)",
                    borderRadius: ".25rem",
                    padding: ".375rem 1rem",
                    color: "white"
                }}>Start Livestream</Button>
            </DialogActions>
        </Dialog>
    )
}

export default StartLivestreamDialog