import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";

import { Auth } from 'aws-amplify';

const CreateContactEmailDialog = ({
                                      openCreateContactEmail,
                                      setOpenCreateContactEmail,
                                      dialogError,
                                      setDialogError,
                                      setOpenConfirmationDialog
                                  }) => {

    const [newContactEmail, setNewContactEmail] = useState("")
    const validEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    
    const handleCreateContactEmailSubmit = async () => {
        if (validEmail.test(newContactEmail)) {
            let customer;
            setDialogError(false)
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.get('/api/customermethods/1', {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    customer = response.data
                })
            
            if(customer.contactEmails && customer.contactEmails.length + newContactEmail.length + 1 > 500) {
                setOpenCreateContactEmail(false)
                setOpenConfirmationDialog({open: true, message: "You have reached your limit for contact emails. Please remove existing contact emails to add more"})
            } else if(customer.contactEmails && customer.contactEmails.split(',').includes(newContactEmail)) {
                setOpenCreateContactEmail(false)
                setOpenConfirmationDialog({open: true, message: "This email already exists. Please add a different contact email"})
            } else {
                await axios.put("/api/customermethods", {
                    customerId: customer.customerId,
                    customerName: customer.customerName,
                    contactEmails: customer.contactEmails ? customer.contactEmails + "," + newContactEmail : newContactEmail,
                    Username: email
                }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(() => {
                        setOpenCreateContactEmail(false)
                        setOpenConfirmationDialog({open: true, message: "Contact Email created successfully"})
                    }).catch(() => {
                        setOpenCreateContactEmail(false)
                        setOpenConfirmationDialog({open: true, message: "Contact Email could not be created at this time. Please try again"})
                    })   
            }
        } else {
            setDialogError(true)
        }
    }

    return (
        <Dialog open={openCreateContactEmail}>
            <DialogTitle>Create Contact Email</DialogTitle>
            <DialogContent
                id="create-contact-email-dialog"
                style={{display: "flex", flexDirection: "column"}}
            >
                <DialogContentText>
                    To add a new contact email, please specify the following:
                </DialogContentText>

                <div className="form-container">
                    <TextField
                        error={dialogError}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Contact Email"
                        variant="standard"
                        value={newContactEmail}
                        onChange={(e) => {
                            setNewContactEmail(e.target.value)
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{padding: "1rem"}}>
                <Button onClick={() => {
                    setOpenCreateContactEmail(false)
                }}>Cancel</Button>
                <Button onClick={() => {
                    handleCreateContactEmailSubmit()
                }} variant="contained" style={{
                    backgroundColor: "rgb(25, 118, 210)",
                    borderRadius: ".25rem",
                    padding: ".375rem 1rem",
                    color: "white"
                }}>Create Contact Email</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateContactEmailDialog;