import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField
} from "@mui/material";
import React from "react";

const CreateFleetDialog = ({openCreateFleet, handleCreateFleetClose, handleCreateFleetSubmit, dialogError, newFleet, setNewFleet, checkLatRange, checkLongRange}) => {
    return (
        <Dialog open={openCreateFleet} onClose={handleCreateFleetClose}>
            <DialogTitle>Create Fleet</DialogTitle>
            <DialogContent
                id="dialog-content"
                style={{display: "flex", flexDirection: "column"}}
            >
                <DialogContentText>
                    To create a new fleet, please specify the following:
                </DialogContentText>
                <TextField
                    error={dialogError && !newFleet.fleetName}
                    fullWidth
                    margin="dense"
                    id="name-textfield"
                    label="Fleet Name"
                    variant="standard"
                    value={newFleet.fleetName}
                    style={{marginTop: "2rem", marginBottom: "1rem"}}
                    onChange={(e) => {
                        setNewFleet(newFleet => {
                            return {...newFleet, fleetName: e.target.value}
                        })
                    }}
                />
                <div className="coordinate-container">
                    <TextField
                        error={dialogError && !checkLatRange(parseInt(newFleet.latitude))}
                        helperText={"Valid range: -90 to 90"}
                        type={"number"}
                        margin="dense"
                        id="name-textfield"
                        label="Latitude"
                        variant="standard"
                        value={newFleet.latitude}
                        style={{paddingRight: "0.5rem"}}
                        onChange={(e) => {
                            setNewFleet(newFleet => {
                                return {...newFleet, latitude: e.target.value}
                            })
                        }}
                    />
                    <TextField
                        error={dialogError && !checkLongRange(parseInt(newFleet.longitude))}
                        helperText={"Valid range: -180 to 180"}
                        type={"number"}
                        margin="dense"
                        id="name-textfield"
                        label="Longitude"
                        variant="standard"
                        value={newFleet.longitude}
                        style={{paddingLeft: "0.5rem"}}
                        onChange={(e) => {
                            setNewFleet(newFleet => {
                                return {...newFleet, longitude: e.target.value}
                            })
                        }}
                    />
                </div>
                <TextField
                    fullWidth
                    margin="dense"
                    id="name-textfield"
                    label="Fleet Description"
                    variant="standard"
                    value={newFleet.fleetDescription}
                    style={{marginTop: "1rem", marginBottom: "1rem"}}
                    onChange={(e) => {
                        setNewFleet(newFleet => {
                            return {...newFleet, fleetDescription: e.target.value}
                        })
                    }}
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                className="fleet-active-switch-container"
                                defaultChecked
                                onChange={(e) => {
                                    setNewFleet(newFleet => {
                                        return {...newFleet, active: e.target.checked}
                                    })
                                }}
                            />
                        }
                        label="Active"/>
                </FormGroup>
            </DialogContent>
            <DialogActions style={{padding: "1rem"}}>
                <Button onClick={handleCreateFleetClose}>Cancel</Button>
                <Button onClick={handleCreateFleetSubmit} variant="contained" style={{
                    backgroundColor: "rgb(25, 118, 210)",
                    borderRadius: ".25rem",
                    padding: ".375rem 1rem",
                    color: "white"
                }}>Create Fleet</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateFleetDialog;