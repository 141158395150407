import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1500"
            height="1500"
            version="1.1"
            viewBox="0 0 1500 1500"
            className="cctv-icon"
        >
            <defs>
                <linearGradient
                    id="linearGradient110359"
                    x1="1275.531"
                    x2="1279.82"
                    y1="148.386"
                    y2="195.364"
                    gradientTransform="translate(237.693 -337.785) scale(2.04973)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient110353"
                ></linearGradient>
                <linearGradient id="linearGradient110353">
                    <stop offset="0" stopColor="#080808" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#080808" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient110340"
                    x1="1234.533"
                    x2="1243.43"
                    y1="164.322"
                    y2="178.007"
                    gradientTransform="matrix(2.6171 0 0 2.6171 -475.444 -449.01)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient72881"
                ></linearGradient>
                <linearGradient id="linearGradient72881">
                    <stop offset="0" stopColor="#0e0e0e" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#2f2f2f" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient72918"
                    x1="3535.818"
                    x2="3636.812"
                    y1="-1149.144"
                    y2="-1225.618"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient66069"
                ></linearGradient>
                <linearGradient id="linearGradient66069">
                    <stop offset="0" stopColor="#5d5d5d" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#5d5d5d" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient110331"
                    x1="1203.497"
                    x2="1187.755"
                    y1="130.197"
                    y2="165.726"
                    gradientTransform="matrix(2.6171 0 0 2.6171 -475.444 -449.01)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient66049"
                ></linearGradient>
                <linearGradient id="linearGradient66049">
                    <stop offset="0" stopColor="#272727" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#4a4a4a" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient72922"
                    x1="1230.02"
                    x2="1241.41"
                    y1="148.059"
                    y2="159.497"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient66034"
                ></linearGradient>
                <linearGradient id="linearGradient66034">
                    <stop offset="0" stopColor="#4a4a4a" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#4a4a4a" stopOpacity="0"></stop>
                </linearGradient>
                <filter id="filter66042" colorInterpolationFilters="sRGB">
                    <feGaussianBlur stdDeviation="0.739"></feGaussianBlur>
                </filter>
                <radialGradient
                    id="radialGradient72924"
                    cx="1196.325"
                    cy="147.934"
                    r="9.821"
                    fx="1196.325"
                    fy="147.934"
                    gradientTransform="matrix(1.57088 .8567 -.53032 1.29406 -604.505 -1068.403)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient72838"
                ></radialGradient>
                <linearGradient id="linearGradient72838">
                    <stop offset="0" stopColor="#004c80" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#004c80" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient72926"
                    x1="1214.971"
                    x2="1223.081"
                    y1="162.582"
                    y2="162.189"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient72806"
                ></linearGradient>
                <linearGradient id="linearGradient72806">
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#000" stopOpacity="0"></stop>
                </linearGradient>
                <radialGradient
                    id="radialGradient72928"
                    cx="1217.079"
                    cy="162.148"
                    r="2.757"
                    fx="1217.079"
                    fy="162.148"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient72794"
                ></radialGradient>
                <linearGradient id="linearGradient72794">
                    <stop offset="0" stopColor="red" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#760000" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient110319"
                    x1="1194.745"
                    x2="1206.041"
                    y1="167.138"
                    y2="155.19"
                    gradientTransform="matrix(2.6171 0 0 2.6171 -475.444 -449.01)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient72849"
                ></linearGradient>
                <linearGradient id="linearGradient72849">
                    <stop offset="0" stopColor="#fff" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient110369"
                    x1="1214.04"
                    x2="1240.008"
                    y1="105.139"
                    y2="115.952"
                    gradientTransform="translate(237.693 -337.785) scale(2.04973)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient110363"
                ></linearGradient>
                <linearGradient id="linearGradient110363">
                    <stop offset="0" stopColor="#858585" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#858585" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
            <g>
                <g
                    fillRule="nonzero"
                    color="#000"
                    transform="matrix(3.096 0 0 3.096 -7711.176 884.312)"
                    visibility="visible"
                >
                    <path
                        d="M2858.836-42.227l22.247 4.803c11.405 2.356 10.33 6.17 9.544 47.27-.37 19.368 4.227 46.152-6.51 43.232-1.012-1.012-31.348-9.86-31.348-9.86-6.692-8.73-19.159-68.646 6.067-85.445z"
                        style={{ marker: "none" }}
                        fill="#585858"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2731.805-3.992l2.023 29.325c3.526 9.9 32.574 10.272 35.139.253-.282-9.775-.306-19.55-.253-29.325z"
                        style={{ marker: "none" }}
                        fill="#5a5a5a"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2866.841 2.163v14.234s-3.349 12.56-2.093 15.49c1.256 2.93 9.629 19.258 9.629 19.258l-18.002-5.442-5.861-15.072-5.024-17.165z"
                        style={{ marker: "none" }}
                        fill="url(#linearGradient110359)"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1.188"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2855.044 20.214l-86.962 5.309c-8.216-6.07-9.542-14.585.253-27.05l88.479-9.1c13.016 4.971 12.327 32.442-1.77 30.84z"
                        style={{ marker: "none" }}
                        fill="#4d4d4d"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <ellipse
                        cx="3584.927"
                        cy="-596.19"
                        fill="#818181"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                        ry="8.377"
                        transform="matrix(.40182 0 0 .4376 1309.655 257.213)"
                        style={{ marker: "none" }}
                        rx="45.927"
                    ></ellipse>
                    <path
                        d="M2732.69-5.319l.506-35.644 34.886-26.544-.254 63.452c-7.482 1.863-21.027 5.182-35.139-1.264z"
                        style={{ marker: "none" }}
                        fill="#737373"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        style={{ marker: "none" }}
                        fill="#202020"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        d="M2600.832-83.683c-9.997 6.332 7.198 41.278 12.278 59.325l3.429.773 152.972-89.734-11.545-16.954z"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        fill="url(#linearGradient110340)"
                        d="M2832.974-8.107c-35.707 7.126-60.876 17.835-69.547 29.67-9.887 5.97-19.109 5.032-30.238 4.536l-1.512-28.915 6.425-24.757 30.607-23.992s-1.078 45.725-.512 46.67c1.369 1.346 1 3.116 1 3.116 7.012-.32 48.112-4.763 63.777-6.328z"
                        style={{ marker: "none" }}
                        fillOpacity="1"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0"
                        strokeWidth="5.49"
                        display="inline"
                        enableBackground="accumulate"
                        opacity="0.668"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2602.74-83.934c-9.322 7.086 8.785 41.725 13.865 59.772l156.401-88.96-19.73-27.44z"
                        style={{ marker: "none" }}
                        fill="#3b3b3b"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <g
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        transform="matrix(.4369 .0245 -.0245 .4369 1151.676 363.64)"
                    >
                        <path
                            style={{ marker: "none" }}
                            fill="#a0a0a0"
                            d="M3590.031-897.813c-2.935.107-5.576.99-7.75 2.47-.012.007-.019.022-.03.03-5.96 2.916-265.306 129.782-269.345 129.782L3558-588.25l206.656-193.781c.012-.007.02-.024.031-.031 5.777-3.442 8.8-10.936 7.156-19.75l-13.124-70.25c-2.292-12.29-12.721-22.36-23.407-22.594L3591-897.813a16.556 16.556 0 00-.969 0z"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                            transform="translate(0 -447.638)"
                        ></path>
                        <rect
                            style={{ marker: "none" }}
                            width="245.945"
                            height="180.532"
                            x="3511.968"
                            y="-1324.389"
                            fill="#7b7b7b"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                            rx="25.94"
                            ry="34.937"
                            transform="matrix(.99968 .0253 .15918 .98725 0 0)"
                        ></rect>
                        <rect
                            width="201.37"
                            height="147.812"
                            x="3534.255"
                            y="-1308.029"
                            fill="url(#linearGradient72918)"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                            rx="21.239"
                            ry="28.605"
                            transform="matrix(.99968 .0253 .15918 .98725 0 0)"
                            style={{ marker: "none" }}
                        ></rect>
                        <rect
                            style={{ marker: "none" }}
                            width="189.122"
                            height="141.019"
                            x="3542.182"
                            y="-1311.166"
                            fill="#494949"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                            rx="19.947"
                            ry="27.29"
                            transform="matrix(.99965 .0266 .15757 .9875 0 0)"
                        ></rect>
                    </g>
                    <path
                        d="M2626.978-53.276s50.907-1.42 75.6 3.763c3.825.803 7.612 2.152 10.956 4.175 7.484 4.528 20.411 21.167 20.411 21.167l2.74.755-9.922-51.404-101.675-6.426z"
                        style={{ marker: "none" }}
                        fill="url(#linearGradient110331)"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1.4"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2752.947-139.028c-1.428-.028-2.733.328-3.829.987 0 .006 0 .01-.027.016-2.654 1.119-120.371 44.733-146.602 54.144-3.136 1.125-3.689 2.627-3.898 2.615 0 0 62.494 1.335 99.142 4.636 6.508.586 14.847 2.605 17.665 8.501 5.597 11.707 11.213 35.027 11.912 34.616l3.45.144 103.355-77.254-.027-11.317c-.777-6.025-5.564-11.196-10.743-11.6l-69.942-5.46a7.949 7.949 0 00-.47-.026z"
                        style={{ marker: "none" }}
                        fill="#202020"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        fill="url(#linearGradient72922)"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1.4"
                        d="M1227.327 163.56c1.083-.217 35.529-28.525 35.529-28.525l-1.3-7.582-36.54 19.064z"
                        display="inline"
                        transform="matrix(2.6171 0 0 2.6171 -475.444 -449.01)"
                        style={{ marker: "none" }}
                        enableBackground="accumulate"
                        filter="url(#filter66042)"
                        overflow="visible"
                    ></path>
                    <path
                        style={{ marker: "none" }}
                        fill="#6e6e6e"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        d="M2755.715-141.503c-1.427-.028-2.733.329-3.828.987 0 .006 0 .01-.027.017-2.971 1.252-149.612 56.809-151.571 56.699 0 0 63.565 1.394 100.213 4.696 6.508.586 14.846 2.605 17.665 8.5 5.597 11.708 12.133 37.801 12.832 37.39l105.885-79.884-.027-11.316c-.778-6.026-5.564-11.197-10.743-11.601l-69.943-5.46a7.949 7.949 0 00-.47-.026z"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <circle
                        cx="1199.164"
                        cy="149.334"
                        fill="#121212"
                        fillOpacity="1"
                        stroke="none"
                        display="inline"
                        transform="matrix(2.84802 0 .15395 2.80953 -757.958 -466.03)"
                        style={{ marker: "none" }}
                        r="9.821"
                        strokeWidth="1.4"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></circle>
                    <circle
                        style={{ marker: "none" }}
                        cx="1199.164"
                        cy="149.334"
                        r="9.821"
                        fill="url(#radialGradient72924)"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1.4"
                        display="inline"
                        enableBackground="accumulate"
                        opacity="0.668"
                        overflow="visible"
                        transform="matrix(2.46372 0 .13317 2.43043 -291.565 -408.472)"
                    ></circle>
                    <g
                        stroke="none"
                        strokeWidth="1.4"
                        display="inline"
                        transform="matrix(2.6171 0 0 2.6171 -475.444 -449.01)"
                    >
                        <circle
                            style={{ marker: "none" }}
                            cx="1217.729"
                            cy="162.582"
                            r="2.757"
                            fill="url(#linearGradient72926)"
                            fillOpacity="1"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                            transform="matrix(1.19642 0 0 1.19642 -239.437 -31.934)"
                        ></circle>
                        <circle
                            style={{ marker: "none" }}
                            cx="1217.729"
                            cy="162.582"
                            r="2.757"
                            fill="url(#radialGradient72928)"
                            fillOpacity="1"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                        ></circle>
                        <circle
                            style={{ marker: "none" }}
                            cx="1216.369"
                            cy="161.447"
                            r="0.975"
                            fill="#ff3636"
                            fillOpacity="0.429"
                            display="inline"
                            enableBackground="accumulate"
                            overflow="visible"
                            transform="matrix(.56862 .31107 -.47994 .8773 602.402 -358.554)"
                        ></circle>
                    </g>
                    <path
                        fill="url(#linearGradient110319)"
                        d="M2683.675-24.524l2.004-9.622c-4.92-1.383-9.947-2.045-13.497-12.561 0 0-11.626-.268-11.493.267-.176 9.961 9.745 22.177 22.986 21.916z"
                        style={{ marker: "none" }}
                        fillOpacity="1"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0"
                        strokeWidth="5.49"
                        display="inline"
                        enableBackground="accumulate"
                        opacity="0.668"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2688.256-83.793s43.22-8.289 47.365-10.065c4.145-1.776 4.923-6.432 16.405-9.33 11.566-2.918 20.286-9.472 20.286-9.472l16.595-8.433 27.827-15.394 13.618 2.369s5.92 2.368 5.92 5.328c0 2.96 1.777 14.21 1.777 14.21l-107.164 81.113-10.658-36.708-7.697-7.697z"
                        style={{ marker: "none" }}
                        fill="url(#linearGradient110369)"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1.188"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2602.252-84.499l150.297-55.68 4.571-.554 24.734 3.484 7.955-.838s9.629 2.512 2.512 4.606c-7.118 2.093-27.213.837-30.98 1.256-3.769.418-11.304 1.674-14.654 3.767-3.349 2.094-7.535 4.606-9.629 5.862-2.093 1.256-12.14 3.349-17.583 4.186-5.443.837-10.885-.419-14.234 3.35-3.35 3.767-5.024 10.047-9.21 11.722-4.187 1.674-12.98 1.674-17.166 1.256-4.186-.419-6.28-1.256-7.954-.838-1.675.419-7.955.419-11.304 1.256-3.349.838-10.047 5.443-20.514 7.955-10.466 2.512-36.841 9.21-36.841 9.21z"
                        style={{ marker: "none" }}
                        fill="#656565"
                        fillOpacity="0.579"
                        stroke="none"
                        strokeWidth="1.188"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                    <path
                        d="M2683.47-80.731h17.166s13.815 1.256 18.839-1.256c5.024-2.512 18.42-7.117 20.095-8.792 1.675-1.674 12.56-3.349 14.653-2.93 2.093.418 4.605 2.093 5.024 6.28.419 4.186 10.885 6.698 10.885 6.698s0 11.722-.419 13.816c-.418 2.093 8.373 2.093 10.048-6.699 1.675-8.792-3.35-14.653-3.768-18.42-.419-3.769.419-6.7.419-9.211 0-2.512-4.187-10.048-4.605-12.56-.42-2.512-6.28 3.35-8.792 5.443-2.512 2.093-10.466 5.442-15.072 6.28-4.605.837-7.954 2.093-17.164 4.605-9.21 2.512.418 3.768-15.072 4.605-15.49.837-35.586 4.187-39.772 4.605-4.187.419-8.373-.418-18.84-.418-10.466 0-55.262 4.605-55.262 4.605z"
                        style={{ marker: "none" }}
                        fill="#656565"
                        fillOpacity="0.579"
                        stroke="none"
                        strokeWidth="1.188"
                        display="inline"
                        enableBackground="accumulate"
                        overflow="visible"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
