import {Backdrop, CircularProgress, Slider, Tooltip} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ArchiveIcon from "@mui/icons-material/Archive";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatIcon from "@mui/icons-material/Chat";
import CancelIcon from '@mui/icons-material/Cancel';
import StopIcon from '@mui/icons-material/Stop';
import HomeIcon from '@mui/icons-material/Home';
import {getStatusIconWithDescription} from "../../constants";
import {AzureMP} from "react-azure-mp";
import React, {useEffect, useState} from "react";
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";

import axios from "axios";
import {useHistory} from "react-router-dom";
import {ReactComponent as VideoThumbnail} from "../../assets/images/VideoThumbnail.svg";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import { Auth } from 'aws-amplify';

const MediaPlayerContainer = ({
                                  robot,
                                  setOpenStartLivestreamDialog,
                                  sendArchiveCommand,
                                  sendStopCommand,
                                  sendGoHomeCommand,
                                  sendStopMissionCommand,
                                  sendGainChangeCommand,
                                  sendExposureChangeCommand,
                                  sendCameraNumberChangeCommand,
                                  setOpenAgnetSettings,
                                  agnetAccess,
                                  setOpenAgnetDialog,
                                  hasAgnetSettings,
                                  getBatteryIcon
                              }) => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [robotState, setRobotState] = useState({})

    useEffect(() => {
        let localRobotState;

        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.get('/api/robotmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    const robots = response.data
                    setRobotState(robots.find(dbRobot => dbRobot.robotId === robot.robotId))
                    localRobotState = robots.find(dbRobot => dbRobot.robotId === robot.robotId)
                }).catch(error => {

                })
            setIsLoading(false);
        }

        fetchData()

        const asyncInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/live') {
                await clearIntervalAsync(asyncInterval)
            }
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.get('/api/robotmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    const robots = response.data
                    const matchedRobot = robots.find(latestRobot => localRobotState.robotId === latestRobot.robotId && (localRobotState.liveStreamEndpoints !== latestRobot.liveStreamEndpoints || localRobotState.currentStatus !== latestRobot.currentStatus || localRobotState.batteryPct !== latestRobot.batteryPct))
                    if (matchedRobot) {
                        localRobotState = matchedRobot
                        await setRobotState(matchedRobot)
                    }
                }).catch(error => {
                    
                })
        }, 5000)

        return () => {
            clearIntervalAsync(asyncInterval).then(r => {
            })
        }
    }, [])

    const getGainPercentage = (value) => {
        return `Gain: ${value}%`
    }

    const getExposurePercentage = (value) => {
        return `Exposure: ${value}%`
    }

    const generateMultiMediaPlayer = (endpoints) => {
        const livestreamLinks = endpoints ? endpoints.split(',') : []
        while(livestreamLinks.length < 4) {
            livestreamLinks.push('')
        }

        return (
            <div className="stream-container">
                {livestreamLinks.map((link) => {
                    return link ?
                        <AzureMP
                            options={{controls: true, autoplay: true}}
                            src={[{src: link, type: "application/vnd.ms-sstr+xml"}]}
                        />
                    :
                    <VideoThumbnail />    
                })}
            </div>
        )
    }

    return (
        !isLoading &&
        <div className="media-player">
            <div className="livestream-info">
                <div>
                    {robotState.robotName}
                </div>
                <div className="status">
                    {getStatusIconWithDescription(robotState.currentStatus)}
                    <div className="battery-container">
                        <div className="battery-percentage">
                            {robotState.batteryPct ? robotState.batteryPct : 0}%
                        </div>
                        {getBatteryIcon(robotState.batteryPct)}
                    </div>
                </div>
            </div>
            <div className="media-container">
                {/*<div className="camera-settings">*/}
                {/*    <Slider*/}
                {/*        size="small"*/}
                {/*        orientation="vertical"*/}
                {/*        valueLabelDisplay="auto"*/}
                {/*        valueLabelFormat={getGainPercentage}*/}
                {/*        onChangeCommitted={(e, value) => sendGainChangeCommand(robotState.robotId, value.toString())}*/}
                {/*        defaultValue={50}*/}
                {/*    />*/}
                {/*    <Slider*/}
                {/*        size="small"*/}
                {/*        orientation="vertical"*/}
                {/*        valueLabelDisplay="auto"*/}
                {/*        valueLabelFormat={getExposurePercentage}*/}
                {/*        onChangeCommitted={(e, value) => sendExposureChangeCommand(robotState.robotId, value.toString())}*/}
                {/*        defaultValue={50}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="azure-mp">
                    {generateMultiMediaPlayer(robotState.liveStreamEndpoints)}
                </div>
            </div>
            <div className="livestream-commands">
                <div className="stream">
                    <Tooltip title="Start Livestream">
                        <PlayCircleFilledIcon className="icon" onClick={() => {
                            setOpenStartLivestreamDialog({open: true, robotId: robotState.robotId})
                        }}/>
                    </Tooltip>
                    <Tooltip title="Stop Livestream">
                        <CancelIcon className="icon" onClick={() => {
                            sendStopCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                    <Tooltip title="Archive Mission">
                        <ArchiveIcon className="icon" onClick={() => {
                            sendArchiveCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                </div>
                {agnetAccess && <div className="agnet">
                    <Tooltip title="Agnet Settings">
                        <SettingsIcon className="icon" onClick={() => {
                            setOpenAgnetSettings({open: true, robotId: robotState.robotId})
                        }}/>
                    </Tooltip>
                    {<Tooltip title="Agnet Message">
                        <ChatIcon className={"icon" + (hasAgnetSettings(robot) ? "" : "-disabled")} onClick={() => {
                            if (hasAgnetSettings(robot)) {
                                setOpenAgnetDialog({open: true, robotId: robotState.robotId})
                            }
                        }}/>
                    </Tooltip>}
                </div>}
                <div className="robot">
                    <Tooltip title="Return Home">
                        <HomeIcon className="icon" onClick={() => {
                            sendGoHomeCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                    <Tooltip title="Stop Mission">
                        <StopIcon className="icon" onClick={() => {
                            sendStopMissionCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default React.memo(MediaPlayerContainer)