import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import React, {useEffect, useRef, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import {Auth} from "aws-amplify";
import axios from "axios";
import moment from "moment/moment";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Start} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import ViewReportDialog from "./ViewReportDialog";

// Main component for Report page
const Report = () => {

    const history = useHistory();
    const isMobile = window.innerWidth <= 768

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingText, setIsLoadingText] = useState("")
    const [startTime, setStartTime] = useState(moment('01/01/2023'))
    const [endTime, setEndTime] = useState(moment.utc)
    const [siteNameFilter, setSiteNameFilter] = useState("All")
    
    // Reports selected by user
    const [selectedReports, setSelectedReports] = useState([])
    
    // Reports currently visable 
    const currentReports = useRef([])

    // List of objects containing Report image name and corresponding presigned url
    const [reportImages, setReportImages] = useState([])
    const [openViewReportDialog, setOpenViewReportDialog] = useState(false)

    const [reports, setReports] = useState([])

    // Function that downloads Reports for the user
    // reportData contains a dictionary where the file name of the report is the key
    // and the report content is the value
    const downloadReports = (reportData) => {
        Object.keys(reportData).forEach((fileName) => {
            let binaryString = window.atob(reportData[fileName])
            let binaryLen = binaryString.length
            let bytes = new Uint8Array(binaryLen)
            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i)
                bytes[i] = ascii
            }
            let blob = new Blob([bytes], {type: "application/pdf"})
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName;
            link.click()
        })
    }

    // Function to parse and organize Report information for DataGrid 
    // Also updates currentReports to show what is currently visable to user for later use
    const organizeReports = () => {
        let reportNames = []
        let counter = 1

        if (siteNameFilter === "All") {
            Object.keys(reports).forEach((siteName) => {
                reports[siteName].forEach((reportFileName) => {
                    reportNames.push({
                        id: counter++,
                        reportId: reportFileName.split("/")[0],
                        reportInfo: reportFileName.split("/")[1]
                    })
                })
            })
        } else {
            reports[siteNameFilter].forEach((reportFileName) => {
                reportNames.push({
                    id: counter++,
                    reportId: reportFileName.split("/")[0],
                    reportInfo: reportFileName.split("/")[1]
                })
            })
        }

        currentReports.current = reportNames
        return reportNames.reverse()
    }

    // Pre-render requests 
    useEffect(() => {
        async function fetchReports() {
            setIsLoadingText("Fetching Reports")

            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.post('/api/reportmethods', {
                Username: email,
                StartTime: startTime,
                EndTime: endTime
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setReports(response.data)
                })

            setIsLoading(false)
            setIsLoadingText("")
        }

        fetchReports()
    }, [])

    return (
        <div className="Report">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || isLoadingText !== ""}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <ViewReportDialog openViewReportDialog={openViewReportDialog}
                              setOpenViewReportDialog={setOpenViewReportDialog} reportImages={reportImages}/>
            <div className="header">
                <div className="title">
                    Reports
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            <div className="content-container">
                <div className="report-table-settings">
                    <div className="site-dropdown">
                        <FormControl>
                            <InputLabel id="demo-simple-select-standard-label">Site</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                defaultValue="All"
                                style={{width: "200px"}}
                                onChange={async e => {
                                    setSiteNameFilter(e.target.value)
                                }}
                                label="Site"
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                {!isLoading && Object.keys(reports).map(siteName => {
                                    return <MenuItem value={siteName}>{siteName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="datepicker-container">
                        <LocalizationProvider
                            dateAdapter={DateAdapter}
                            style={{height: "4rem", marginBottom: "2rem"}}>
                            <DatePicker
                                label="Start Date"
                                value={startTime}
                                onChange={async (newStartTime) => {
                                    // Update state and make request to update Reports
                                    
                                    setStartTime(newStartTime.format('YYYY-MM-DD'))
                                    const email = await Auth.currentAuthenticatedUser().then((user) => {
                                        return user.attributes.email;
                                    })
                                    const token = (await Auth.currentSession()).getIdToken().getJwtToken()

                                    await axios.post('/api/reportmethods', {
                                        Username: email,
                                        StartTime: moment(newStartTime).format('YYYY-MM-DDT00:00:00'),
                                        EndTime: moment(endTime).format('YYYY-MM-DDT23:59:59'),
                                    }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                        .then((response) => {
                                            setReports(response.data)
                                        })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                minDate={moment(startTime)}
                                label="End Date"
                                value={endTime}
                                onChange={async (newEndTime) => {
                                    // Update state and make request to update Reports
                                    
                                    setEndTime(newEndTime.format('YYYY-MM-DD'))
                                    const email = await Auth.currentAuthenticatedUser().then((user) => {
                                        return user.attributes.email;
                                    })
                                    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                                    
                                    await axios.post('/api/reportmethods', {
                                        Username: email,
                                        StartTime: moment(startTime).format('YYYY-MM-DDT00:00:00'),
                                        EndTime: moment(newEndTime).format('YYYY-MM-DDT23:59:59'),
                                    }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                        .then((response) => {
                                            setReports(response.data)
                                        })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="report-table">
                    <Box sx={{height: "100%", width: '100%'}}>
                        <DataGrid
                            sx={{overflowX: 'scroll'}}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            columns={[
                                isMobile ? {
                                    headerName: "Report Info",
                                    field: "reportInfo",
                                    width: 500
                                } : {headerName: "Report Info", field: "reportInfo", flex: 1},
                            ]}
                            rows={organizeReports()}
                            onSelectionModelChange={reports => {
                                setSelectedReports(reports)
                            }}
                        />
                    </Box>
                </div>
                <div className="report-actions">
                    <Button
                        style={{
                            backgroundColor: selectedReports.length === 1 ? "rgb(25, 118, 210)" : "rgba(0,0,0,0.26)",
                            borderRadius: ".25rem",
                            padding: ".375rem 1rem",
                            color: "white",
                            marginRight: "1rem"
                        }}
                        disabled={selectedReports.length !== 1}
                        onClick={async () => {
                            // Sends request to get images for selected report, sets images in state and opens pop up object
                            
                            const email = await Auth.currentAuthenticatedUser().then((user) => {
                                return user.attributes.email;
                            })
                            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

                            var report = currentReports.current.find((report) => {
                                return selectedReports[0] === report.id
                            })

                            if (report !== undefined) {
                                setIsLoading(true)
                                setIsLoadingText("Fetching Report Images")

                                await axios.post('/api/reportmethods/fetchImages', {
                                    Username: email,
                                    ReportId: report.reportId
                                }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                    .then((response) => {
                                        if (response.data != null) {
                                            if (response.data.length > 0) {
                                                setReportImages(response.data)
                                                setOpenViewReportDialog(true)
                                            }
                                        }
                                    })

                                setIsLoading(false)
                                setIsLoadingText("")
                            }

                        }}
                    >
                        View Images
                    </Button>
                    <Button
                        style={{
                            backgroundColor: selectedReports.length > 0 ? "rgb(25, 118, 210)" : "rgba(0,0,0,0.26)",
                            borderRadius: ".25rem",
                            padding: ".375rem 1rem",
                            color: "white"
                        }}
                        disabled={selectedReports.length === 0}
                        onClick={async () => {
                            // Sends request to download selected Reports 
                            
                            let reportsToBeDownloaded = {}

                            currentReports.current.filter((report) => {
                                return selectedReports.includes(report.id)
                            }).forEach((report) => {
                                reportsToBeDownloaded[report.reportId] = report.reportInfo
                            })

                            setIsLoading(true)
                            setIsLoadingText("Downloading Reports")

                            const email = await Auth.currentAuthenticatedUser().then((user) => {
                                return user.attributes.email;
                            })
                            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

                            await axios.post('/api/reportmethods/download', {
                                Username: email,
                                Reports: reportsToBeDownloaded
                            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                .then((response) => {
                                    if (response.data) {
                                        downloadReports(response.data)
                                    }
                                })

                            setIsLoading(false)
                            setIsLoadingText("")
                        }}>
                        Download
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Report