import React, {useEffect, useState} from "react";

const NextMission = ({generateMissionList}) => {
    const [missionState, setMissionState] = useState({
        missionName: "",
        timeToRun: "",
        dayOfWeek: ""
    })
    
    useEffect(() => {
        const missionList = generateMissionList()
        setMissionState(missionList.length ? missionList[0] : {
            missionName: "",
            timeToRun: "",
            dayOfWeek: ""
        })
    }, [])
    
    return (
        missionState.missionName ?
            <div>Next Mission: {missionState.missionName} on {missionState.dayOfWeek} at {missionState.timeToRun}</div>
            :
            <div>Next Mission: No Mission Scheduled</div>
    )
}

export default NextMission