import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';

const AgnetSettingsDialog = ({openAgnetSettings, setOpenAgnetSettings, setOpenConfirmationDialog}) => {

    const [robot, setRobot] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [dialogError, setDialogError] = useState(false)
    const [agnetSettings, setAgnetSettings] = useState({
        agnetChannel: "",
        agnetUserName: "",
        agnetPassword: "",
        agnetSubscriberId: "",
        agnetClientId: "",
        agnetClientSecret: "",
        agnetUrltoken: "",
        agnetUrlmessage: ""
    })

    const handleSetAgnetSettings = async () => {
        if (Object.values(agnetSettings).every(v => v !== "" && v !== null)) {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.put('/api/agnetrobotupdatemethods', {robotId: robot.robotId, Username: email,...agnetSettings}, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(() => {
                    setOpenAgnetSettings({open: false, robotId: null})
                    setOpenConfirmationDialog({open: true, message: "Agnet Settings updated successfully"})
                }).catch(() => {
                    setOpenAgnetSettings({open: false, robotId: null})
                    setOpenConfirmationDialog({
                        open: true,
                        message: "Agnet Settings could not be updated at this time. Please try again"
                    })
                })
        } else {
            setDialogError(true)
        }
    }

    useEffect(() => {
        async function getRobot() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            await axios.get('/api/robotmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    const robot = response.data.find(robot => robot.robotId === openAgnetSettings.robotId)
                    setRobot(robot)
                    setAgnetSettings({
                        agnetChannel: robot.agnetChannel,
                        agnetUserName: robot.agnetUserName,
                        agnetPassword: robot.agnetPassword,
                        agnetSubscriberId: robot.agnetSubscriberId,
                        agnetClientId: robot.agnetClientId,
                        agnetClientSecret: robot.agnetClientSecret,
                        agnetUrltoken: robot.agnetUrltoken,
                        agnetUrlmessage: robot.agnetUrlmessage
                    })
                })
            setIsLoading(false)
        }
        
        getRobot()
    }, [openAgnetSettings.robotId])
    
    return (
        <Dialog open={openAgnetSettings.open}>
            <DialogTitle>Agnet Settings</DialogTitle>
            <DialogContent
                id="agnet-settings-dialog"
                style={{display: "flex", flexDirection: "column"}}
            >
                <DialogContentText>
                    Please configure your Agnet settings below:
                </DialogContentText>

                {isLoading ? <></> : <div className="form-container">
                    <TextField
                        error={dialogError && !agnetSettings.agnetChannel}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Channel"
                        variant="standard"
                        value={agnetSettings.agnetChannel}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetChannel: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetUserName}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Username"
                        variant="standard"
                        value={agnetSettings.agnetUserName}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetUserName: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetPassword}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Password"
                        variant="standard"
                        value={agnetSettings.agnetPassword}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetPassword: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetSubscriberId}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Subscriber Id"
                        variant="standard"
                        value={agnetSettings.agnetSubscriberId}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetSubscriberId: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetClientId}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Client Id"
                        variant="standard"
                        value={agnetSettings.agnetClientId}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetClientId: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetClientSecret}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Client Secret"
                        variant="standard"
                        value={agnetSettings.agnetClientSecret}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetClientSecret: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetUrltoken}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Url Token"
                        variant="standard"
                        value={agnetSettings.agnetUrltoken}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetUrltoken: e.target.value}))
                        }}
                    />
                    <TextField
                        error={dialogError && !agnetSettings.agnetUrlmessage}
                        fullWidth
                        margin="dense"
                        id="name-textfield"
                        label="Agnet Url Message"
                        variant="standard"
                        value={agnetSettings.agnetUrlmessage}
                        onChange={(e) => {
                            setAgnetSettings(agnetSettings => ({...agnetSettings, agnetUrlmessage: e.target.value}))
                        }}
                    />
                </div>}
            </DialogContent>
            <DialogActions style={{padding: "1rem"}}>
                <Button onClick={() => {
                    setAgnetSettings({
                        agnetChannel: "",
                        agnetUserName: "",
                        agnetPassword: "",
                        agnetSubscriberId: "",
                        agnetClientId: "",
                        agnetClientSecret: "",
                        agnetUrltoken: "",
                        agnetUrlmessage: ""
                    })
                    setDialogError(false)
                    setOpenAgnetSettings({
                        open: false, robotId: null
                    })
                }}>Cancel</Button>
                <Button onClick={() => {
                    handleSetAgnetSettings()
                }} variant="contained" style={{
                    backgroundColor: "rgb(25, 118, 210)",
                    borderRadius: ".25rem",
                    padding: ".375rem 1rem",
                    color: "white"
                }}>Set Agnet Settings</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AgnetSettingsDialog;