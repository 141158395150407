import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios from "axios";
import RobotTile from "../RobotTile/RobotTile";
import {ReactComponent as LogoutIcon} from "../../assets/images/LogoutIcon.svg";
import Map from "../Map/Map";
import {ApplicationPaths} from "../api-authorization/ApiAuthorizationConstants";
import {useHistory} from "react-router-dom";
import FleetManagement from "../FleetManagement/FleetManagement";
import {
    Alert,
    Backdrop, Button,
    CircularProgress, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton,
    Snackbar,
    TextField,
    Tooltip
} from "@mui/material";
import { Auth } from 'aws-amplify';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CreateQrCodeDialog from "../CreateQrCodeDialog/CreateQrCodeDialog";
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import {Add} from "@mui/icons-material";
import AddDeviceDialog from "../AddDeviceDialog/AddDeviceDialog";
import DeviceTile from "../DeviceTile/DeviceTile";

const Home = () => {
    const defaultFleet = useMemo(() => ({fleetName: "All", fleetId: 0, fleetLocation: "-98.3734906468881,38.8825300889946"}), [])
    const [robotInfo, setRobotInfo] = useState([]);
    const [classes, setClasses] = useState([])
    const [fleets, setFleets] = useState([]);
    const [fleetRobots, setFleetRobots] = useState([]);
    const [currentFleet, setCurrentFleet] = useState(defaultFleet);
    const [isLoading, setIsLoading] = useState(true);
    const [currentView, setCurrentView] = useState();
    const [search, setSearch] = useState('');
    const [selectedPoint, setSelectedPoint] = useState([]);
    const [robotCoordinates, setRobotCoordinates] = useState([]);
    const [isLoadingText, setIsLoadingText] = useState("");
    const [messageStatus, setMessageStatus] = useState({open: false, message: "", severity: ""})
    
    const [openCreateQrCodeDialog, setOpenCreateQrCodeDialog] = useState(false)
    const [openAddDeviceDialog, setOpenDeviceDialog] = useState(false)
    
    const inputRef = useRef();
    const history = useHistory();
    const robotClassIds = [1,2]
    
    const logoutPath = {pathname: `${ApplicationPaths.LogOut}`, state: {local: true}};
    
    const uploadFile = async (e) => {
        var fileName = e.target.files[0].name;
        
        if(!fileName.endsWith(".walk.zip")) {
            setMessageStatus({open: true, message: "Invalid file type. Please upload your mission with file extension '.walk.zip'", severity: "error"})
            return;
        }
        

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        const formdata = new FormData();

        formdata.append("formFile", e.target.files[0])
        formdata.append("fileName", fileName)
        formdata.append("username", email)
        
        setIsLoadingText("Uploading Mission file")
        await axios.post('/api/missionassetmethods', formdata, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(async response => {
                setMessageStatus({open: true, message: "Mission upload successful.", severity: "success"})
            }).catch(e => {
                console.log(e)
            })
        setIsLoadingText("")
    }
    
    useEffect(() => {
        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()          

            let tempRobotInfo;
            setIsLoadingText("Fetching Fleet Data")
            await axios.get('/api/fleetmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    setFleets(response.data)
                }).catch(error => {
                    
                })
            
            await axios.get('/api/fleetrobotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    setFleetRobots(response.data)
                }).catch(error => {
                    
                })

            setIsLoadingText("Fetching Robot Data")
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    setRobotInfo(response.data)
                    tempRobotInfo = response.data
                }).catch(error => {
                   
                })
            
            console.log("Calling Class methods")
            await axios.get('/api/robotclassmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    setClasses(response.data.filter((robotClass) => !robotClassIds.includes(robotClass.robotClassId)))
                })

            setIsLoadingText("Fetching Location Data")
            await axios.post(`/api/lochistmethods`, { NumberHistoryToReturn: 1, Username: email }, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    const locHists = response.data
                    
                    locHists.forEach(locHist => {
                        const coordinates = locHist.geoCoordinate.split(",")
                        setRobotCoordinates(robotCoordinates => [...robotCoordinates, [{
                            robotId: locHist.robotId,
                            coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])]
                        }]])
                    })
                    
                    tempRobotInfo.forEach(robot => {
                        if(!locHists.find(locHist => locHist.robotId === robot.robotId)) {
                            setRobotCoordinates(robotCoordinates => [...robotCoordinates, [{
                                robotId: robot.robotId,
                                coordinates: [0, 0]
                            }]])
                        }
                    })
                })
            setIsLoading(false)
            setIsLoadingText("")
        }

        fetchData()
        
        return () => {
            if(history.action === "POP") {
                window.location.reload()
            }
        }
    }, [])

    return (
        <div className="Home">
            <Snackbar open={messageStatus.open} autoHideDuration={5000}
                      onClose={() => setMessageStatus({open: false, message: "", severity: ""})}>
                <Alert onClose={() => setMessageStatus({open: false, message: "", severity: ""})} severity={messageStatus.severity}
                       sx={{width: '100%'}}>
                    {messageStatus.message}
                </Alert>
            </Snackbar>
            <CreateQrCodeDialog openCreateQrCodeDialog={openCreateQrCodeDialog} setOpenCreateQrCodeDialog={setOpenCreateQrCodeDialog}/>
            <AddDeviceDialog openAddDeviceDialog={openAddDeviceDialog} setOpenAddDeviceDialog={setOpenDeviceDialog} deviceTypes={classes}/>
            <div className="header">
                <div className="fleet-location">
                    <FleetManagement 
                        defaultFleet={defaultFleet}
                        currentFleet={currentFleet}
                        setCurrentFleet={setCurrentFleet}
                        setCurrentView={setCurrentView}
                        setSelectedPoint={setSelectedPoint}
                        fleets={fleets} 
                        isLoading={isLoading}
                        fleetRobots={fleetRobots}
                        robotInfo={robotInfo}
                    />
                </div>
                <div className="navbar-utilities">
                    <input className="search-bar" placeholder="Search" onChange={(e) => {
                        setSearch(e.target.value)
                    }}/>
                    <Tooltip
                        title="Add Device"
                    >
                        <AddToQueueIcon className="add-device-icon" onClick={() => setOpenDeviceDialog(true)}/>
                    </Tooltip>
                    <Tooltip
                        title="Create QR Code"
                    >
                        <QrCode2Icon className="qr-icon" onClick={() => setOpenCreateQrCodeDialog(true)}/>
                    </Tooltip>
                    <Tooltip
                        title="Upload Mission file"
                    >
                        <DriveFolderUploadIcon className="upload-icon" onClick={() => inputRef.current.click()}/>
                    </Tooltip>
                    <input type="file" accept=".zip" ref={inputRef} onChange={uploadFile} hidden/>
                    <Tooltip
                        title="Log out"
                    >
                        <LogoutIcon className="logout-icon" onClick={() => {
                            Auth.signOut()
                        }}/>
                    </Tooltip>
                </div>
            </div>
            <div className="content-container">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading || isLoadingText}
                >
                    <div>{isLoadingText}&ensp;</div>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="robot-info">
                    {!isLoading && <>{robotInfo.map(robot => {
                        if (robot.robotName.toLowerCase().includes(search.toLowerCase()) && robotClassIds.includes(robot.classId)) {
                            const robotCoordinate = robotCoordinates.find(robotCoordinate => robotCoordinate[0].robotId === robot.robotId)
                            return <RobotTile
                                inCurrentFleet={currentFleet.fleetName === "All" || fleetRobots.some(fleetRobot =>
                                    fleetRobot.fleetId === currentFleet.fleetId && fleetRobot.robotId === robot.robotId
                                )}
                                key={robotInfo.indexOf(robot)}
                                setCurrentView={setCurrentView}
                                robot={robot}
                                robotCoordinate={robotCoordinate ? robotCoordinate[0].coordinates : [0,0]}
                                setSelectedPoint={setSelectedPoint}
                                tileNumber={robotInfo.indexOf(robot)}
                            />
                        }
                    })}</>}
                    {!isLoading && <>{robotInfo.map(robot => {
                        if (robot.robotName.toLowerCase().includes(search.toLowerCase()) && !robotClassIds.includes(robot.classId)) {
                            const robotCoordinate = robotCoordinates.find(robotCoordinate => robotCoordinate[0].robotId === robot.robotId)
                            return <DeviceTile
                                inCurrentFleet={currentFleet.fleetName === "All" || fleetRobots.some(fleetRobot =>
                                    fleetRobot.fleetId === currentFleet.fleetId && fleetRobot.robotId === robot.robotId
                                )}
                                key={robotInfo.indexOf(robot)}
                                setCurrentView={setCurrentView}
                                robot={robot}
                                robotCoordinate={robotCoordinate ? robotCoordinate[0].coordinates : [0,0]}
                                setSelectedPoint={setSelectedPoint}
                                tileNumber={robotInfo.indexOf(robot)}
                            />
                        }
                    })}</>}
                </div>
                {!isLoading && <div className="map">
                    <Map robotInfo={robotInfo}
                         robotLocs={robotCoordinates}
                         currentView={currentView}
                         setCurrentView={setCurrentView}
                         selectedPoint={selectedPoint}
                         setSelectedPoint={setSelectedPoint}
                    />
                </div>}
            </div>
        </div>);
}

export default Home; 
