import {batteryColor, estimateBatteryPercentage, getStatusIcon, statusColor} from "../../constants";
import SettingsIcon from "@mui/icons-material/Settings";
import {Tooltip} from "@mui/material";
import CCTVLogo from "../../assets/images/CCTVLogo";
import {ReactComponent as LocationIcon} from "../../assets/images/LocationIcon.svg";

const DeviceTile = ({robot, setCurrentView, robotCoordinate, setSelectedPoint}) => {
    
    
    return (
        <div className="DeviceTile">
            <div className="status" style={statusColor(robot)}/>
            <div className="content">
                <div className="robot-name">
                    {robot.robotName}
                    <div className="icon-container">
                        {/*<SettingsIcon className="icon settings-icon" onClick={() => {*/}
                        {/*    setOpenSettingsDialog(true)*/}
                        {/*}}/>*/}
                        {getStatusIcon(robot.currentStatus)}
                        <Tooltip
                            title="Location"
                        >
                            <LocationIcon className="icon" onClick={() => {
                                setCurrentView({
                                    longitude: robotCoordinate[0],
                                    latitude: robotCoordinate[1],
                                    zoom: 12,
                                    bearing: 0,
                                    pitch: 0
                                })
                                setSelectedPoint(robotCoordinate)
                            }}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="robot-actions">
                    <CCTVLogo className="cctv-icon"/>
                </div>
            </div>
            <div className="battery-percentage">
                <div className="battery-bar">
                    <div className="filled"
                         style={{width: robot.batteryPct + "%", backgroundColor: batteryColor(robot.batteryPct)}}/>
                </div>
                <div className="battery-info">
                    <div className="legend">
                        <div className="icon" style={{backgroundColor: batteryColor(robot.batteryPct)}}/>
                        Battery
                    </div>
                    {<div
                        className="text">(~{estimateBatteryPercentage(robot.batteryPct)} min) {robot.batteryPct}%</div>}
                </div>
            </div>
        </div>
    )
}

export default DeviceTile;