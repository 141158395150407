import {ReactComponent as SpotIcon} from "../../assets/images/SpotIcon.svg";
import {ReactComponent as LocationIcon} from "../../assets/images/LocationIcon.svg";
import {useHistory} from "react-router-dom";
import {batteryColor, estimateBatteryPercentage, getStatusIcon, statusColor} from "../../constants";
import {Tooltip} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {useEffect, useRef, useState} from "react";
import RobotSettingsDialog from "./RobotSettingsDialog";
import UpdateRobotDialog from "./UpdateRobotDialog";
import {Auth} from "aws-amplify";
import axios from "axios";

const RobotTile = ({inCurrentFleet, robot, setCurrentView, robotCoordinate, setSelectedPoint, tileNumber}) => {

    const history = useHistory();
    const [openSettingsDialog, setOpenSettingsDialog] = useState(false)
    const [openUpdateRobotDialog, setOpenUpdateRobotDialog] = useState(false)

    const releases = useRef([])
    const robotApiVersion = useRef("")
    const releasePreReqs = useRef([])
    const ecosystemInfo = useRef(null)
    const currentRelease = useRef(null)

    useEffect(() => {
        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.get('/api/releasemethods/' + robot.robotId + "/" + email,
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}}).then((response) => {

                if (response.data.releases !== null) {
                    releases.current = response.data.releases
                    releasePreReqs.current = response.data.releasePreReqs
                    robotApiVersion.current = response.data.robotApiVersion
                    ecosystemInfo.current = response.data.ecosystemInfo
                    currentRelease.current = response.data.currentRelease
                }

            }).catch(() => {

            })
        }

        fetchData()
    }, [])
    
    return (
        inCurrentFleet && 
        <div className="RobotTile" key={robot.robotName}>
            <RobotSettingsDialog robot={robot} openSettingsDialog={openSettingsDialog} setOpenSettingsDialog={setOpenSettingsDialog} setOpenUpdateRobotDialog={setOpenUpdateRobotDialog} robotApiVersion={robotApiVersion} currentRelease={currentRelease} ecosystemInfo={ecosystemInfo}/>
            <UpdateRobotDialog robot={robot} openUpdateRobotDialog={openUpdateRobotDialog} setOpenUpdateRobotDialog={setOpenUpdateRobotDialog} releases={releases} releasePreReqs={releasePreReqs} robotApiVersion={robotApiVersion} currentRelease={currentRelease}/>
            <div className="status" style={statusColor(robot)}/>
            <div className="content">
                <div className="robot-name">
                    {robot.robotName}
                    <div className="icon-container">
                        <SettingsIcon className="icon settings-icon" onClick={() => {
                            setOpenSettingsDialog(true)
                        }}/>
                        {getStatusIcon(robot.currentStatus)}
                        <Tooltip
                            title="Location"
                        >
                            <LocationIcon className="icon" onClick={() => {
                                setCurrentView({
                                    longitude: robotCoordinate[0],
                                    latitude: robotCoordinate[1],
                                    zoom: 12,
                                    bearing: 0,
                                    pitch: 0
                                })
                                setSelectedPoint(robotCoordinate)
                            }}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="robot-actions">
                    <SpotIcon className="spot-icon"/>
                    <div className="robot-control-btn" onClick={() => history.push("/robotDetails", robot)}>
                        CONTROL
                    </div>
                </div>
            </div>
            <div className="battery-percentage">
                <div className="battery-bar">
                    <div className="filled"
                         style={{width: robot.batteryPct + "%", backgroundColor: batteryColor(robot.batteryPct)}}/>
                </div>
                <div className="battery-info">
                    <div className="legend">
                        <div className="icon" style={{backgroundColor: batteryColor(robot.batteryPct)}}/>
                        Battery
                    </div>
                    {<div
                        className="text">(~{estimateBatteryPercentage(robot.batteryPct)} min) {robot.batteryPct}%</div>}
                </div>
            </div>
        </div>
    )
}

export default RobotTile;