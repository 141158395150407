import {FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import React, {useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UpdateIcon from '@mui/icons-material/Update';
import axios from "axios";

import CreateFleetDialog from "../Dialogs/CreateFleetDialog";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import DeleteFleetDialog from "../Dialogs/DeleteFleetDialog";
import UpdateFleetDialog from "../Dialogs/UpdateFleetDialog";
import { Auth } from 'aws-amplify';

const FleetManagement = ({
                             defaultFleet,
                             currentFleet,
                             setCurrentFleet,
                             setCurrentView,
                             setSelectedPoint,
                             fleets,
                             fleetRobots,
                             isLoading,
                             robotInfo
                         }) => {

    const screenWidth = window.innerWidth
    const [openCreateFleet, setOpenCreateFleet] = useState(false);
    const [openDeleteFleet, setOpenDeleteFleet] = useState(false);
    const [openUpdateFleet, setOpenUpdateFleet] = useState(false);
    const [dialogError, setDialogError] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState({open: false, message: ""});
    const [newFleet, setNewFleet] = useState({
        fleetName: "",
        latitude: "",
        longitude: "",
        fleetDescription: "",
        active: true
    });

    const handleCreateFleetOpen = () => {
        setDialogError(false)
        setNewFleet({
            fleetName: "",
            latitude: "",
            longitude: "",
            fleetDescription: "",
            active: true
        })
        setOpenCreateFleet(true)
    }

    const handleDeleteFleetOpen = () => {
        setDialogError(false)
        setOpenDeleteFleet(true)
    }

    const handleCreateFleetClose = () => {
        setOpenCreateFleet(false)
    }

    const handleUpdateFleetOpen = () => {
        setOpenUpdateFleet(true)
    }

    const checkLatRange = (lat) => {
        return lat >= -90 && lat <= 90
    }

    const checkLongRange = (long) => {
        return long >= -180 && long <= 180
    }

    const handleCreateFleetSubmit = async () => {
        const lat = parseFloat(newFleet.latitude)
        const long = parseFloat(newFleet.longitude)

        if (!newFleet.fleetName || !newFleet.latitude || !newFleet.longitude || !(checkLatRange(lat) && checkLongRange(long))) {
            setDialogError(true)
        } else {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.post('/api/fleetmethods', {
                    fleetName: newFleet.fleetName,
                    fleetLocation: newFleet.longitude + "," + newFleet.latitude,
                    fleetDescription: newFleet.fleetDescription,
                    active: newFleet.active,
                    Username: email
                }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}}
            ).then(() => {
                setOpenCreateFleet(false)
                setOpenConfirmationDialog({open: true, message: "Fleet created"})
            }).catch(() => {
                setOpenCreateFleet(false)
                setOpenConfirmationDialog({
                    open: true,
                    message: "Fleet could not be created at this time. Please try again"
                })
            })
        }
    }


    const generateFleetDataForDataGrid = () => {
        return fleets.map(fleet => {
            return {
                id: fleet.fleetId,
                fleetName: fleet.fleetName,
                numRobots: fleetRobots.filter(robot => robot.fleetId === fleet.fleetId).length
            }
        })
    }

    return (
        <div className="FleetManagement">
            <CreateFleetDialog
                openCreateFleet={openCreateFleet}
                handleCreateFleetClose={handleCreateFleetClose}
                handleCreateFleetSubmit={handleCreateFleetSubmit}
                dialogError={dialogError}
                newFleet={newFleet}
                setNewFleet={setNewFleet}
                checkLatRange={checkLatRange}
                checkLongRange={checkLongRange}
            />
            <ConfirmationDialog
                openConfirmationDialog={openConfirmationDialog}
                setOpenConfirmationDialog={setOpenConfirmationDialog}
            />
            <DeleteFleetDialog
                openDeleteFleet={openDeleteFleet}
                setOpenDeleteFleet={setOpenDeleteFleet}
                dialogError={dialogError}
                setDialogError={setDialogError}
                isLoading={isLoading}
                generateFleetDataForDataGrid={generateFleetDataForDataGrid}
            />
            <UpdateFleetDialog
                openUpdateFleet={openUpdateFleet}
                setOpenUpdateFleet={setOpenUpdateFleet}
                generateFleetDataForDataGrid={generateFleetDataForDataGrid}
                fleets={fleets}
                isLoading={isLoading}
                fleetRobots={fleetRobots}
                robotInfo={robotInfo}
                setOpenConfirmationDialog={setOpenConfirmationDialog}
            />
            <FormControl variant="standard" sx={{
                m: 1,
                minWidth: 300,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <InputLabel id="demo-simple-select-standard-label">Fleet</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={currentFleet}
                    defaultValue={defaultFleet}
                    style={{width: screenWidth <= 640 ? "10rem" : "15.625rem"}}
                    onChange={e => {
                        const newFleet = e.target.value
                        const fleetCoordinates = newFleet.fleetLocation.trim().split(',')
                        setCurrentView({
                            longitude: parseFloat(fleetCoordinates[0]),
                            latitude: parseFloat(fleetCoordinates[1]),
                            zoom: newFleet.fleetName === "All" ? 3 : 10,
                            bearing: 0,
                            pitch: 0
                        })
                        setSelectedPoint([])
                        setCurrentFleet(e.target.value)
                    }}
                    label="Fleet"
                >
                    <MenuItem value={defaultFleet}>
                        {defaultFleet.fleetName}
                    </MenuItem>

                    {fleets.map(fleet => {
                        return <MenuItem key={fleets.indexOf(fleet)} value={fleet}>{fleet.fleetName.trim()}</MenuItem>
                    })}
                </Select>
                <Tooltip
                    title="Add Fleet"
                >
                    <div>
                        <IconButton aria-label="add" onClick={handleCreateFleetOpen}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Tooltip
                    title="Remove Fleet"
                >
                    <div>
                        <IconButton aria-label="remove" onClick={handleDeleteFleetOpen}>
                            <RemoveIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Tooltip
                    title="Edit Fleet"
                >
                    <div>
                        <IconButton aria-label="update" onClick={handleUpdateFleetOpen}>
                            <UpdateIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
            </FormControl>
        </div>
    )
}

export default FleetManagement;