import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as AirbusAgnetLogo} from "../../assets/images/AirbusAgnetLogo.svg";
import SensorsIcon from '@mui/icons-material/Sensors';
import React, {useEffect, useRef, useState} from "react";
import {
    Alert,
    Backdrop,
    BottomNavigation,
    BottomNavigationAction,
    CircularProgress,
    Snackbar,
    Tooltip
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import {Auth} from "aws-amplify";
import VideoThumbnail from "../../assets/images/VideoThumbnail.svg";
import 'shaka-player/dist/controls.css';
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";
import {Chart} from "react-google-charts";
import {getStatusIcon, SensorThresholds} from "../../constants";
import EventTable from "../SensorData/EventTable";
import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

const shaka = require('shaka-player/dist/shaka-player.ui.js');

const Agnet = () => {

    const history = useHistory()
    const isMobile = window.innerWidth < 768 ? true : false

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingText, setIsLoadingText] = useState("")

    const searchParams = useLocation().search
    const [graphData, setGraphData] = useState({})
    const [userInfo, setUserInfo] = useState({
        agnetUsername: "",
        batteryPct: "",
        livestream: "",
        location: "",
        robotName: "",
        robotStatus: "",
        robotId: 0
    })

    const [allRobotSensors, setAllRobotSensors] = useState([])
    const [allSensors, setAllSensors] = useState([])

    const [robots, setRobots] = useState([])
    const [robotSensors, setRobotSensors] = useState([])
    const [sensors, setSensors] = useState([])
    const [sensorSettings, setSensorSettings] = useState([])
    const [sensorGroups, setSensorGroups] = useState([])

    const selectedRobotRef = useRef(null)
    const [selectedRobot, setSelectedRobot] = useState(null)
    const [selectedSensor, setSelectedSensor] = useState(null)

    const [bottomNavValue, setBottomNavValue] = useState(0)
    const [archiveEndpoint, setArchiveEndpoint] = useState('')

    const playerStateRef = useRef(null)
    const uiOverlayRef = useRef(null)
    const streamUrlRef = useRef("")

    const intervalStateRef = useRef(null)
    const [intervalState, setIntervalState] = useState(null)
    const [robotStates, setRobotStates] = useState([])
    const [messageStatus, setMessageStatus] = useState({open: false, message: ""})
    
    let manifestUrl = "";

    const generateStream = async (streamUrl) => {
        let video = document.getElementById('video');
        let videoContainer = document.getElementById('video-stream');
        let player = null;

        if (streamUrl !== streamUrlRef.current) {
            if (playerStateRef.current !== null) {
                if (uiOverlayRef.current !== null) {
                    await uiOverlayRef.current.destroy().then(() => {
                    })
                    uiOverlayRef.current = null
                }
                if (streamUrl === "") {
                    await playerStateRef.current.destroy().then(() => {
                    })
                    playerStateRef.current = null
                    streamUrlRef.current = streamUrl;
                } else {
                    await playerStateRef.current.destroy().then(() => {
                    })
                    video = document.getElementById('video');
                    videoContainer = document.getElementById('video-stream');
                    player = new shaka.Player(video);
                    playerStateRef.current = player
                }

            } else {
                player = new shaka.Player(video);
                playerStateRef.current = player
            }

            if (streamUrl) {
                const livestream = streamUrl.split("?token=")

                if (livestream.length > 1) {
                    player.getNetworkingEngine().registerRequestFilter((type, request) => {
                        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                            request.headers.Authorization = 'Bearer ' + livestream[1]
                        }
                    })
                }

                const config = {addSeekBar: true}
                const ui = new shaka.ui.Overlay(player, videoContainer, video)
                ui.configure(config)

                uiOverlayRef.current = ui
                streamUrlRef.current = streamUrl

                manifestUrl = livestream[0]
                await player.load(livestream[0]).then(() => {
                }).catch((e) => {
                })
            }
        }
    }

    const generateChartAxis = (sensor) => {
        return [{type: "datetime", label: "Time"}, {type: "number", label: sensor.sensorName}, {
            role: "style",
            type: "string"
        }, {type: "number", label: "color band 1"}, {type: "number", label: "color band 2"}]
    }

    const pointColor = (value, sensorId) => {
        var threshold = SensorThresholds[sensorId]

        if (threshold.direction === "greater") {
            if (value > threshold.value)
                return "red"
            else
                return "green"
        } else {
            if (value < threshold.value)
                return "red"
            else
                return "green"
        }
    }

    const formatChartData = (datum, sensorId) => {
        let chartData = []
        let threshold = SensorThresholds[sensorId].value


        chartData = chartData.concat(datum.map((data) => {
            return [
                moment.utc(data.creationTimestamp).local().toDate(),
                data.data,
                pointColor(data.data, sensorId),
                threshold,
                100 - threshold
            ]
        }))

        return chartData;
    }

    const generateChartOptions = (sensor) => {
        var threshold = SensorThresholds[sensor.sensorId]

        return {
            title: sensor.sensorName,
            curveType: "continuous",
            hAxis: {
                format: "dd-MM-yyyy HH:mm",
                gridlines: {
                    units: {
                        hours: {format: ['HH:mm', 'ha']},
                        minutes: {format: ['HH:mm a Z', ':mm']}
                    }
                },
                minorGridlines: {
                    units: {
                        hours: {format: ['hh:mm:ss a', 'ha']},
                        minutes: {format: ['HH:mm a Z', ':mm']}
                    }
                }
            },
            explorer: {
                actions: ['dragToZoom', 'rightClickToReset'],
                axis: 'horizontal',
                keepInBounds: true,
                maxZoomIn: 0.05,
                maxZoomOut: 1
            },
            legend: {position: 'none'},
            isStacked: true,
            series: {
                0: {type: "line", pointSize: 1, viewWindowMode: 'maximized'},
                1: {
                    lineWidth: 0,
                    type: 'area',
                    visibleInLegend: false,
                    enableInteractivity: false,
                    color: threshold.direction === "greater" ? "green" : "red",
                },
                2: {
                    lineWidth: 0,
                    type: 'area',
                    visibleInLegend: false,
                    enableInteractivity: false,
                    color: threshold.direction === "greater" ? "red" : "green",
                }
            }
        }
    }

    const updateGraphData = async (localRobotId) => {
        let localRobotSensors = []
        let localSensors = []

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        setIsLoadingText("Fetching Sensor Info")
        await axios.get(`/api/robotsensorsmethods/${localRobotId}/` + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                if (response.data) {
                    localRobotSensors = response.data
                    setRobotSensors(response.data)
                }
            }).catch(() => {
            })

        let localSensorGroups = []

        await axios.post('/api/sensormethods', {
            SensorIds: localRobotSensors.map((rs) => rs.sensorId),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                if (response.data) {
                    localSensors = response.data
                    setSensors(response.data)

                    if (response.data.length > 0)
                        setSelectedSensor(response.data[0])
                    else
                        setSelectedSensor(null)

                    localSensors.forEach((s) => {
                        var sensorGroupIdentifier = s.sensorName.split("_")[0]

                        if (!localSensorGroups.includes(sensorGroupIdentifier)) {
                            localSensorGroups.push(sensorGroupIdentifier)
                        }
                    })

                    setSensorGroups(localSensorGroups)
                }
            }).catch(() => {
            })

        setIsLoadingText("Fetching Sensor Data")
        var newGraphData = {}
        for (const rs of localRobotSensors) {

            await axios.post("/api/sensordatummethods/graphData", {
                RobotSensorsIds: [rs.robotSensorsId],
                TimeInterval: 5,
                Timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    var myData = response.data.filter(dp => dp.robotSensorsId === rs.robotSensorsId)
                    var sensor = localSensors.find((s) => s.sensorId === rs.sensorId)

                    newGraphData[sensor.sensorId] = [generateChartAxis(sensor), ...formatChartData(myData, sensor.sensorId)]
                })
        }

        setGraphData(newGraphData)
        setIsLoadingText("")
        startInterval(localRobotSensors, localSensors, localRobotId)
    }

    const hasActiveEvent = (robotId) => {

        if (robotStates.length === 0) {
            return (
                <Tooltip
                    title="Status Unknown"
                >
                    <div>
                        <QuestionMarkIcon className="event-icon" style={{fill: "red"}}/>
                    </div>
                </Tooltip>
            )
        }

        for (let i = 0; i < robotStates.length; i++) {
            if (robotStates[i].robotId === robotId) {
                if (robotStates[i].activeEvent) {
                    return (
                        <Tooltip
                            title="Active Event"
                        >
                            <div>
                                <CrisisAlertIcon className="event-icon" style={{fill: "red"}}/>
                            </div>
                        </Tooltip>

                    )
                } else {
                    return (
                        <Tooltip
                            title="No Active Events"
                        >
                            <div>
                                <CheckIcon className="event-icon" style={{fill: "green"}}/>
                            </div>
                        </Tooltip>

                    )
                }
            }
        }
    }

    const updateSelectedRobot = (robot) => {
        // setSelectedRobot(robot)
        selectedRobotRef.current = robot
        generateStream(robot.liveStreamEndpoints).then(() => {
        })
        if (intervalStateRef.current) {
            clearIntervalAsync(intervalStateRef.current).then(() => {
                intervalStateRef.current = null
            })
        }
        updateGraphData(robot.robotId).then(r => {
        })
    }

    const startInterval = (localRobotSensors, localSensors, robotId) => {
        let timestamp = moment.utc();

        let asyncInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/agnet') {
                await clearIntervalAsync(asyncInterval)
            }
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            let robotIds = [];

            await axios.get(`/api/robotmethods/` + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    if (response.data !== null || response.data !== undefined) {

                        var updatedSelectedRobot = response.data.find((robot) => robot.robotId === selectedRobotRef.current.robotId)

                        if (updatedSelectedRobot !== null) {
                            generateStream(updatedSelectedRobot.liveStreamEndpoints).then(() => {
                            })
                            // setSelectedRobot(updatedSelectedRobot)
                            selectedRobotRef.current = updatedSelectedRobot
                        }

                        robotIds = response.data.map((robot) => robot.robotId)
                        setRobots(response.data)
                    }
                }).catch(() => {
                })

            await axios.post('/api/sensoreventmethods/findRobotsWithActiveEvents',
                {
                    RobotIds: robotIds,
                    Username: email
                },
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}}
            ).then((response) => {
                setRobotStates(response.data)
            })

            await axios.post('/api/sensordatummethods/mostrecent', {
                    RobotSensorsIds: localRobotSensors.map(rs => rs.robotSensorsId),
                    Timestamp: timestamp,
                    Username: email
                },
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    // console.log("Checking with timestamp: " + timestamp.format("YYYY-MM-DDTHH:mm:ss") )

                    if (response.data.length > 0) {

                        response.data.forEach((datapoint) => {
                            if (moment.utc(datapoint.creationTimestamp).isSameOrAfter(timestamp)) {
                                timestamp = moment.utc(datapoint.creationTimestamp)
                            }
                        })

                        try {
                            setGraphData((graphData) => {
                                var newGraphData = {}

                                localRobotSensors.forEach((robotSensor) => {
                                    var datapointsForRobotSensor = response.data
                                        .filter((datapoint) => datapoint.robotSensorsId === robotSensor.robotSensorsId)
                                    var sensor = localSensors.find((s) => s.sensorId === robotSensor.sensorId)

                                    if (datapointsForRobotSensor.length > 0) {
                                        if (graphData[sensor.sensorId].length > 0)
                                            graphData[sensor.sensorId].shift()

                                        //Max 300 points (5 min interval, 60 points per second)
                                        while (graphData[sensor.sensorId].length + datapointsForRobotSensor.length > 5 * 60) {
                                            graphData[sensor.sensorId].pop();
                                        }

                                        newGraphData[sensor.sensorId] = [generateChartAxis(sensor), ...formatChartData(datapointsForRobotSensor, sensor.sensorId), ...graphData[sensor.sensorId]]
                                    } else {
                                        return graphData
                                    }
                                })

                                return newGraphData
                            })
                        } catch (e) {
                            history.go(0)
                        }
                    }
                }).catch(error => {
                })
        }, 5000)
        intervalStateRef.current = asyncInterval
    }

    const sendChangeCameraCommand = async (robotId) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/robotcommandboardmethods', {
            robotCommand: "VIDEOSELECTION",
            robotParams: "SWITCH",
            robotId: robotId,
            issueDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setMessageStatus({
                    open: true,
                    message: "Change Camera Command successfully sent."
                })
            }).catch(() => {
                setMessageStatus({
                    open: true,
                    message: "Change Camera Command could not be sent at this time. Please try again"
                })
            })
    }
    
    useEffect(() => {
        const encryptedInfo = new URLSearchParams(searchParams).toString().slice(5)
        let localRobotSensors = []
        let localSensors = []
        let localLivestream = ""
        let localRobots = []

        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            var localRobotId = 0;

            setIsLoadingText("Fetching Robots")

            await axios.get(`/api/agnet/${encryptedInfo}/` + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    if (response.data) {
                        localRobotId = response.data.robotId
                        localLivestream = response.data.livestream
                        setUserInfo(response.data)
                    }
                }).catch(() => {
                    history.push("/")
                    window.location.reload(true)
                })

            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setRobots(response.data)
                    // setSelectedRobot(response.data.find((robot) => robot.robotId === localRobotId))
                    selectedRobotRef.current = response.data.find((robot) => robot.robotId === localRobotId)
                    localRobots = response.data
                }).catch((error) => {
                })

            //*********
            let tempRobotSensors = []

            setIsLoadingText("Fetching Sensor Info")
            for (const robot of localRobots) {
                await axios.get('/api/robotsensorsmethods/' + robot.robotId + "/" + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then((response) => {
                        tempRobotSensors = tempRobotSensors.concat(response.data)
                    }).catch((error) => {

                    })
            }

            const sensorIds = [...new Set(tempRobotSensors.map(robotSensor => robotSensor.sensorId))];

            await axios.post('/api/sensormethods', {
                SensorIds: sensorIds,
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setAllSensors(response.data)
                })

            setAllRobotSensors(tempRobotSensors)
            //*********

            setIsLoadingText("Fetching Sensor Info")
            await axios.get(`/api/robotsensorsmethods/${localRobotId}/` + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    if (response.data) {
                        localRobotSensors = response.data
                        setRobotSensors(response.data)
                    }
                }).catch(() => {
                })

            let localSensorGroups = []

            await axios.post('/api/sensormethods', {
                SensorIds: localRobotSensors.map((rs) => rs.sensorId),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    if (response.data) {
                        localSensors = response.data
                        setSensors(response.data)

                        if (response.data.length > 0)
                            setSelectedSensor(response.data[0])

                        localSensors.forEach((s) => {
                            var sensorGroupIdentifier = s.sensorName.split("_")[0]

                            if (!localSensorGroups.includes(sensorGroupIdentifier)) {
                                localSensorGroups.push(sensorGroupIdentifier)
                            }
                        })

                        setSensorGroups(localSensorGroups)
                    }
                }).catch(() => {
                })

            setIsLoadingText("Fetching Sensor Settings")
            await axios.post('/api/sensorsettingsmethods', {
                RobotSensorIds: localRobotSensors.map((robotSensor) => robotSensor.robotSensorsId),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setSensorSettings(response.data)
                })


            setIsLoadingText("Fetching Sensor Data")
            var newGraphData = {}
            for (const rs of localRobotSensors) {

                await axios.post("/api/sensordatummethods/graphData", {
                    RobotSensorsIds: [rs.robotSensorsId],
                    TimeInterval: 5,
                    Timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
                    Username: email
                }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then((response) => {
                        var myData = response.data.filter(dp => dp.robotSensorsId === rs.robotSensorsId)
                        var sensor = localSensors.find((s) => s.sensorId === rs.sensorId)

                        newGraphData[sensor.sensorId] = [generateChartAxis(sensor), ...formatChartData(myData, sensor.sensorId)]
                    })
            }

            setGraphData(newGraphData)
            setIsLoadingText("")
            setIsLoading(false)
            setArchiveEndpoint(localLivestream)
            generateStream(localLivestream).then(() => {
            })
            startInterval(localRobotSensors, localSensors, localRobotId)
        }

        fetchData()

        return () => {
            if (intervalStateRef.current) {
                clearIntervalAsync(intervalStateRef.current).then(() => {
                })
            }
        }
    }, [])

    return (
        <div className="Agnet">
            <Snackbar open={messageStatus.open} autoHideDuration={5000}
                      onClose={() => setMessageStatus({open: false, message: ""})}>
                <Alert onClose={() => setMessageStatus({open: false, message: ""})} severity="success"
                       sx={{width: '100%'}}>
                    {messageStatus.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || isLoadingText !== ""}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            {!isLoading && <div className="header">
                <AirbusAgnetLogo className="agnet-logo"
                                 onClick={() => window.location.href = "https://dispatcher-1.ea-1.eu-west-1.agnet.com/index"}
                />
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => {
                        history.push("/")
                        window.location.reload(true)
                    }}/>
                </div>
            </div>}
            {!isLoading && <div className="content-container">
                {!isMobile && <div className="menu">
                    <div className="selected-robot">
                        <div className="robot-name">
                            {selectedRobotRef.current.robotName}
                        </div>
                        <div className="robot-status">
                            Status: {selectedRobotRef.current.currentStatus}
                            <br/>
                            Battery: {selectedRobotRef.current.batteryPct} %
                        </div>
                        <Tooltip title="Change Camera">
                            <CameraswitchIcon className="icon" onClick={() => {
                                sendChangeCameraCommand(selectedRobotRef.current.robotId).then(() => {
                                })
                            }}/>
                        </Tooltip>
                    </div>
                    <div className="robot-list">
                        {robots.map((robot) => {
                            return (
                                <div key={robots.indexOf(robot).toString()}
                                     className={`${robot.robotId === selectedRobotRef.current.robotId ? "selected-robot-button" : "robot-button"}`}
                                     onClick={() => {
                                         if (robot.robotId !== selectedRobotRef.current.robotId) {
                                             updateSelectedRobot(robot)
                                         }
                                     }}>
                                    {robot.robotName} ({robot.batteryPct}%)
                                    <div className="robot-button-status">
                                        {getStatusIcon(robot.currentStatus)}
                                        {hasActiveEvent(robot.robotId)}
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>}
                <div className="information-container">
                    <div className="sensor-info">
                        <div className="focus-container">
                            <div className="video-stream">
                                <div id="video-stream" className="shaka-video-container">
                                    <video autoPlay muted id="video" style={{width: "100%"}}
                                           poster={VideoThumbnail}></video>
                                </div>
                            </div>
                            {selectedSensor !== null &&
                                <div style={{height: "100%"}}>
                                    <Chart
                                        chartType="ComboChart"
                                        width={isMobile ? "100vw" : "100%"}
                                        height={isMobile ? window.innerHeight - 450 + "px" : "100%"}
                                        data={graphData[selectedSensor.sensorId]}
                                        options={generateChartOptions(selectedSensor)}
                                    />
                                </div>
                            }
                        </div>
                        <div className="graph-container">
                            {selectedSensor && sensors.map((sensor) => {
                                if (sensor.sensorId !== selectedSensor.sensorId) {
                                    return (
                                        <div key={sensors.indexOf(sensor).toString()} style={{height: "33%"}}
                                             onClick={() => {
                                                 setSelectedSensor(sensor)
                                             }}>
                                            <Chart
                                                chartType="ComboChart"
                                                width={isMobile ? "100vw" : "100%"}
                                                height={isMobile ? window.innerHeight - 450 + "px" : "100%"}
                                                data={graphData[sensor.sensorId]}
                                                options={generateChartOptions(sensor)}
                                            />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="alerts-container">
                            <div className="alerts-title">
                                Sensor Events
                            </div>
                            <div className="event-table-container">
                                {!isLoading &&
                                    <EventTable robotSensors={allRobotSensors} robots={robots} sensors={allSensors}
                                                sensorSettings={sensorSettings} setIsLoadingText={setIsLoadingText}
                                    />}
                            </div>
                        </div>
                    </div>
                    {isMobile &&
                        <BottomNavigation
                            showLabels
                            value={bottomNavValue}
                            onChange={(event, newValue) => {
                                setBottomNavValue(newValue);
                            }}
                            style={{boxShadow: "0px -10px 11px -14px grey"}}
                        >
                            {sensorGroups.map((group) => {
                                return (
                                    <BottomNavigationAction label={group} icon={<SensorsIcon/>}/>
                                )
                            })
                            }
                        </BottomNavigation>
                    }
                </div>
            </div>}
        </div>
    )
}

export default Agnet