import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";

const RobotSettingsDialog = ({robot, openSettingsDialog, setOpenSettingsDialog, setOpenUpdateRobotDialog, robotApiVersion, currentRelease, ecosystemInfo}) => {
    
    return (<Dialog open={openSettingsDialog} id="robot-settings-dialog">
        <DialogTitle>Robot Settings - {robot.robotName}</DialogTitle>
        <DialogContent id="dialog-content" style={{display: "flex", flexDirection: "column"}}>
            <DialogContentText>
                Current Software Versions:
            </DialogContentText>
            <div className="ecosystem-info">
                <span>
                    Ultaview Version: {currentRelease.current ? currentRelease.current.name : "N/A"}
                </span>
                <span>
                    Robot Api Version: {robotApiVersion.current ? robotApiVersion.current : "N/A"}
                </span>
                <span>
                    Cameras Equipped: {ecosystemInfo.current ? ecosystemInfo.current.uvcameraType : "N/A"}
                </span>
            </div>
            
            <DialogContentText>
                Select action for this robot:
            </DialogContentText>
            <Button onClick={() => {
                setOpenSettingsDialog(false)
                setOpenUpdateRobotDialog(true)
            }} variant="contained" style={{
                backgroundColor: "rgb(25, 118, 210)", borderRadius: ".25rem", padding: ".375rem 1rem", color: "white", marginTop: "1rem"
            }}
            >
                Update Robot
            </Button>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                setOpenSettingsDialog(false)
            }}>
                Close
            </Button>
        </DialogActions>
    </Dialog>)
}

export default RobotSettingsDialog;