// import React, {useEffect, useState} from "react";
//
// import axios from "axios";
// import {useHistory} from "react-router-dom";
// import RobotManagement from "../RobotManagement/RobotManagement";
// import AdminCustomerManagement from "../AdminCustomerManagement/AdminCustomerManagement";
// import { Auth } from 'aws-amplify';
//
// const Admin = () => {
//    
//     const history = useHistory();
//     const [isLoading, setIsLoading] = useState(true)
//    
//     useEffect(() => {
//         async function checkAuthStatus() {
//             const email = await Auth.currentAuthenticatedUser().then((user) => {
//                 return user.attributes.email;
//             })
//             const token = (await Auth.currentSession()).getIdToken().getJwtToken()
//             await axios.get('/api/isuseradmin/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
//                 .then(response => {
//                     if(response.data === false) { 
//                         history.push('/')
//                     } else {
//                         setIsLoading(false)
//                     }
//                 })
//         }
//        
//         checkAuthStatus()
//     })
//    
//     return (
//         isLoading ? <div></div> : <div className="Admin">
//             <div className="header">
//                 Admin
//             </div>
//             <div className="content-container">
//                 <div className="card">
//                     <div className="title">
//                         Robots
//                     </div>
//                     <div className="sub-text">
//                         Use the options below to Create/Remove/Modify Robots
//                     </div>
//                     <RobotManagement />
//                 </div>
//                
//                 <div className="card">
//                     <div className="title">
//                         Customer
//                     </div>
//                     <div className="sub-text">
//                         Use the options below to Create/Remove/Modify Customers
//                     </div>
//                     <AdminCustomerManagement />
//                 </div>
//             </div>
//         </div>
//     )
// }
//
// export default Admin;