import DeckGL from "@deck.gl/react";
import {IconLayer} from "@deck.gl/layers";

import {Map as ReactMap, Popup} from "react-map-gl";

import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const LocationIcon = "<svg xmlns=\"http://www.w3.org/2000/svg\" x=\"0px\" y=\"0px\" width='120' height='120' viewBox=\"0 0 108.3 131.9\" style=\"enable-background:new 0 0 108.3 131.9;\" xml:space=\"preserve\"><style type=\"text/css\">.st0{fill:#FFFFFF;}</style><g><path class=\"st0\" d=\"M57.2,129.7c18.3-31.4,37.6-62.4,37.6-85.1C94.8,21.1,77.1,2,55.2,2C33.3,2,15.5,21.1,15.5,44.6c0,22.6,20,55.1,37.6,85.1C54,131.3,56.3,131.3,57.2,129.7z M36.2,44.6c0-11.2,8.5-20.4,19-20.4c10.5,0,19,9.1,19,20.4c0,11.2-8.5,20.4-19,20.4C44.7,65,36.2,55.9,36.2,44.6z\"/></g></svg>"

const screenWidth = window.innerWidth

const INITIAL_VIEW_STATE = {
    longitude: -98.3734906468881,
    latitude: 38.8825300889946,
    zoom: screenWidth < 550 ? 2 : 3,
    bearing: 0,
    pitch: 0
};

function svgToDataURL(svg) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

const Map = ({robotInfo, robotLocs, currentView, setCurrentView, selectedPoint, setSelectedPoint}) => {
    
    const distanceCalc = (x1, x2, y1, y2) => {
        return Math.sqrt(Math.pow((x2-x1),2) + Math.pow((y2-y1),2))
    }
    
    const findClosestRobotLoc = (selectedPoint) => {
        let distance = 10000000;
        let closestLoc;
        let robotName;
        
        robotLocs.forEach(loc => {
            const coordinates = loc[0].coordinates;
            const calculatedDistance = distanceCalc(selectedPoint[0], coordinates[0], selectedPoint[1], coordinates[1])
            if (calculatedDistance < distance) {
                distance = calculatedDistance;
                closestLoc = loc[0].coordinates;
                robotName = robotInfo.find(robot => robot.robotId === loc[0].robotId).robotName;
            }
        })
        
        return {coordinates: closestLoc, robotName: robotName}
    }
    
    const getPopupInfo = (robotName) => {
        const robot = robotInfo.filter(info => info.robotName === robotName);
        
        return (
            <>
                <div>Robot Name: {robot[0].robotName}</div>
                <div>Status: {robot[0].currentStatus}</div>
                <div>Battery: {robot[0].batteryPct}%</div>
            </>
        )
    }
    
    const renderPopup = () => {
        if (selectedPoint !== null && selectedPoint !== undefined && selectedPoint.length !== 0) {
            const closestRobot = findClosestRobotLoc(selectedPoint)
            return (
                <Popup
                    captureClick
                    closeButton={false}
                    closeOnClick
                    longitude={closestRobot.coordinates[0]}
                    latitude={closestRobot.coordinates[1]}
                    anchor="top"
                    offset={-75}
                    className="pop-up"
                    styles={{}}
                >
                    {getPopupInfo(closestRobot.robotName)}
                    <div className="popup-triangle"></div>
                </Popup>
            );
        }
    };
    
    const layers = robotLocs.map(robotLoc => {
        return new IconLayer({
            onClick: d => setSelectedPoint(d.coordinate),
            id: robotInfo.find(robot => robot.robotId === robotLoc[0].robotId).robotName,
            data: robotLoc,
            pickable: true,
            getIcon: () => ({
                url: svgToDataURL(LocationIcon),
                width: 128,
                height: 128,
            }),
            sizeScale: 15,
            getPosition: d => d.coordinates,
            getSize: () => 3,
            getColor: d => [Math.sqrt(d.exits), 140, 0]
        })
    })
    
    const MAPBOX_ACCESS_TOKEN =
        "pk.eyJ1Ijoic2twb3BhbCIsImEiOiJjbDAyeTk0aHoxNjE2M2pwbWFpd2hpNGRkIn0.h7vo6UJwGhs5EbZ3VpZamQ";

    return (
        <div>
            <DeckGL
                initialViewState={currentView || INITIAL_VIEW_STATE}
                controller={true}
                layers={layers}
                onViewStateChange={({viewState}) => {
                    setCurrentView(null)
                }}
                onClick={(d, event) => {
                    if (d.object === undefined || d.object === null) {
                        setSelectedPoint([])
                    }
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                <ReactMap
                    initialViewState={INITIAL_VIEW_STATE}
                    mapStyle="mapbox://styles/skpopal/cl073ki4f000d15nwgth609kz"
                    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                    key="map"
                    width="100%"
                    height="100%"
                >
                    {renderPopup()}
                </ReactMap>
            </DeckGL>
        </div>
    );
};

export default Map;
