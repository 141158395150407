import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import React, {useState} from "react";
import {Auth} from "aws-amplify";
import axios from "axios";

const SensorSettingsDialog = ({
                                  openSensorSettingsDialog,
                                  setOpenSensorSettingsDialog,
                                  robots,
                                  sensors,
                                  robotSensors,
                                  sensorSettings,
                                  setIsLoadingText,
                                  setMessageStatus,
                                  setSensorSettings
                              }) => {

    const screenWidth = window.innerWidth
    const [selectedRobot, setSelectedRobot] = useState(robots[0].robotId)
    const [selectedSensor, setSelectedSensor] = useState("")
    const [selectedRobotSensor, setSelectedRobotSensor] = useState("")
    const [selectedSensorSetting, setSelectedSensorSetting] = useState("")
    const [threshold, setThreshold] = useState("")
    const [bufferTime, setBufferTime] = useState("")
    const [units, setUnits] = useState("")

    const RobotNameDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Robot name</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRobot}
                    label="Robot"
                    onChange={(e) => {
                        setSelectedRobot(e.target.value)
                        setSelectedSensor("")
                        setThreshold("")
                        setBufferTime("")
                        setUnits("")
                        setSelectedSensorSetting("")
                    }}
                >
                    {robots.map((robot) => {
                        return <MenuItem value={robot.robotId}>{robot.robotName}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }

    const SensorNameDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Sensor name</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSensor}
                    label="Sensor"
                    onChange={(e) => {
                        setSelectedSensor(e.target.value)
                        const sensor = sensors.find((sensor) => sensor.sensorId === e.target.value)
                        const robotSensorsId = robotSensors.find((robotSensor) => robotSensor.robotId === selectedRobot && robotSensor.sensorId === e.target.value).robotSensorsId
                        const mySensorSettings = sensorSettings.find((sensorSetting) => sensorSetting.robotSensorsId === robotSensorsId)

                        setSelectedRobotSensor(robotSensorsId)
                        setThreshold(mySensorSettings.threshold ? mySensorSettings.threshold : "")
                        setBufferTime(mySensorSettings.bufferTime ? mySensorSettings.bufferTime : "")
                        setSelectedSensorSetting(mySensorSettings.sensorSettingsId)
                        setUnits(sensor.units)
                    }}
                >
                    {sensors.filter((sensor) => {
                        const myRobotSensors = robotSensors.filter((robotSensor) => robotSensor.robotId === selectedRobot)
                        const mySensorIds = myRobotSensors.map((robotSensor) => robotSensor.sensorId)

                        return mySensorIds.includes(sensor.sensorId)
                    }).map((sensor) => {
                        return <MenuItem value={sensor.sensorId}>{sensor.sensorName}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }

    const ThresholdInput = () => {
        return (
            <FormControl variant="standard" className="settings-inputs">
                <TextField
                    disabled={!selectedSensor}
                    margin="dense"
                    id="name-textfield"
                    label={`Threshold ${units ? "(" + units + ")" : ""}`}
                    variant="standard"
                    style={{width: "100%"}}
                    value={threshold}
                    onChange={(e) => {
                        setThreshold(e.target.value)
                    }}
                />
            </FormControl>
        )
    }

    const BufferTimeInput = () => {
        return (
            <FormControl variant="standard" className="settings-inputs">
                <TextField
                    disabled={!selectedSensor}
                    margin="dense"
                    id="name-textfield"
                    label="Buffer Time (secs)"
                    variant="standard"
                    style={{width: "100%"}}
                    value={bufferTime}
                    onChange={(e) => {
                        setBufferTime(e.target.value)
                    }}
                />
            </FormControl>
        )
    }

    const handleUpdateRobotSettings = async () => {
        setOpenSensorSettingsDialog(false)
        setIsLoadingText("Updating Sensor Setting")
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        await axios.put('/api/sensorsettingsmethods',
            {
                SensorSettingsId: selectedSensorSetting,
                RobotSensorsId: selectedRobotSensor,
                Threshold: threshold,
                BufferTime: bufferTime,
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                if (response.data) {
                    setMessageStatus({
                        open: true,
                        message: "Sensor Setting has been successfully updated",
                        severity: "success" //TODO: Change be back to success
                    })
                    setSensorSettings(() => {
                        let filteredSensorSettings = sensorSettings.filter((sensorSetting) => sensorSetting.sensorSettingsId !== selectedSensorSetting)
                        let settingToUpdate = sensorSettings.find((sensorSetting) => sensorSetting.sensorSettingsId === selectedSensorSetting)
                        settingToUpdate.threshold = threshold
                        settingToUpdate.bufferTime = bufferTime

                        return [...filteredSensorSettings, settingToUpdate]
                    })
                } else {
                    setMessageStatus({
                        open: true,
                        message: "Sensor Setting could not be updated at this time. Please try again",
                        severity: "error"
                    })
                }
            }).catch((e) => {
                console.log(e)
                setMessageStatus({
                    open: true,
                    message: "Sensor Setting could not be updated at this time. Please try again",
                    severity: "error"
                })
            })

        setIsLoadingText("")
        setSelectedRobot(robots[0].robotId)
        setSelectedSensor("")
        setThreshold("")
        setBufferTime("")
        setUnits("")
        setSelectedSensorSetting("")
    }

    return (
        <Dialog open={openSensorSettingsDialog} id="sensor-settings-dialog">
            <DialogTitle>Update Sensor Settings</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Configure Sensor Settings
                </DialogContentText>
            </DialogContent>
            <div style={{height: 371}} className="dialog-content">
                {RobotNameDropdown()}
                {SensorNameDropdown()}
                {ThresholdInput()}
                {BufferTimeInput()}
            </div>
            <DialogActions>
                <Button onClick={() => {
                    setOpenSensorSettingsDialog(false)
                    setIsLoadingText("")
                    setSelectedRobot(robots[0].robotId)
                    setSelectedSensor("")
                    setThreshold("")
                    setBufferTime("")
                    setUnits("")
                    setSelectedSensorSetting("")
                }}>
                    Close
                </Button>
                <Button
                    disabled={!selectedSensor || !threshold || !bufferTime}
                    style={{
                        backgroundColor: (selectedSensor && threshold && bufferTime) ? "rgb(25, 118, 210)" : "rgba(0,0,0,0.26)",
                        borderRadius: ".25rem",
                        padding: ".375rem 1rem",
                        color: "white"
                    }}
                    onClick={() => {
                        handleUpdateRobotSettings().then(() => {
                        })
                    }}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SensorSettingsDialog;