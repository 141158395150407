import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React, {useState} from "react";
import QRCode from "react-qr-code";
import {DataGrid} from "@mui/x-data-grid";
import WifiConnectDialog from "./WifiConnectDialog";
import WifiDeleteDialog from "./WifiDeleteDialog";
import CellConnectDialog from "./CellConnectDialog";

const CreateQrCodeDialog = ({openCreateQrCodeDialog, setOpenCreateQrCodeDialog}) => {
    
    const [openQrDialog, setOpenQrDialog] = useState("")
    const [selectedCommand, setSelectedCommand] = useState("")
    const qrCodes = [
        {id: 1, command: "wifiConnect", commandName: "Connect to Wifi"},
        {id: 2, command: "wifiDelete", commandName: "Delete Wifi settings"},
        {id: 3, command: "cellConnect", commandName: "Connect to Cell"}
    ];

    const handleGenerateQrCode = () => {
        setOpenCreateQrCodeDialog(false)
        setOpenQrDialog(selectedCommand)
    }

    return (
        <>
            <WifiConnectDialog openQrDialog={openQrDialog} setOpenQrDialog={setOpenQrDialog} />
            <WifiDeleteDialog openQrDialog={openQrDialog} setOpenQrDialog={setOpenQrDialog} />
            <CellConnectDialog openQrDialog={openQrDialog} setOpenQrDialog={setOpenQrDialog} />
            <Dialog open={openCreateQrCodeDialog} id="qr-code-dialog">
                <DialogTitle>QR Code Generator</DialogTitle>
                <DialogContent id="qr-dialog-content"  style={{display: "flex", flexDirection: "column", width: 296}}>
                    <DialogContentText>
                        Select command to generate a QR Code for: 
                    </DialogContentText>
                </DialogContent>
                <div style={{width: 296}}>
                    <DataGrid
                        autoHeight
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        columns={[
                            {headerName: "Command", field: "commandName", width: 294},
                        ]}
                        rows={qrCodes}
                        onRowClick={(selectedRow) => {
                            setSelectedCommand(selectedRow.row.command)
                        }}
                    />
                </div>
                <DialogActions style={{padding: "1rem"}}>
                    <Button onClick={() => {
                        setOpenCreateQrCodeDialog(false)
                        setSelectedCommand("")
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        handleGenerateQrCode()
                    }} variant="contained" style={{
                        backgroundColor: "rgb(25, 118, 210)",
                        borderRadius: ".25rem",
                        padding: ".375rem 1rem",
                        color: "white"
                    }}>Generate QR Code</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateQrCodeDialog;