import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import {useHistory} from "react-router-dom";
import {Auth, Storage} from 'aws-amplify'
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {
    Backdrop, Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    TextField, Tooltip
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment/moment";
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";
import DownloadIcon from '@mui/icons-material/Download';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import {saveAs} from 'file-saver'
import AddIcon from "@mui/icons-material/Add";

const ImageGallery = () => {

    const history = useHistory();

    const images = useRef([])
    const startTime = useRef('01/01/2022')
    const endTime = useRef(moment.now())
    const selectedRobotId = useRef(0)
    const robots = useRef([])
    const galleryLength = useRef(0)
    const galleryPage = useRef(1)
    const resetPage = useRef(false)

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingText, setIsLoadingText] = useState("")
    const [imageDialog, setImageDialog] = useState({open: false, imageTag: "", robotName: "", imageDate: ""})
    
    const [updateKey, setUpdateKey] = useState({key: Math.random()})

    const generateDatetime = (dateString, timeString) => {
        const year = dateString.slice(0, 4)
        const month = dateString.slice(4, 6)
        const day = dateString.slice(6, 8)

        const hour = timeString.slice(0, 2)
        const minute = timeString.slice(2, 4)
        const second = timeString.slice(4, 6)

        var date = moment(year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second)

        // return date.format("YYYY-MM-DD HH:mm:ss")
        return date.format("YYYY-MM-DD HH:mm:ss")
    }

    const generateDate = (dateString) => {
        const year = dateString.slice(0, 4)
        const month = dateString.slice(4, 6)
        const day = dateString.slice(6, 8)

        return month + "/" + day + "/" + year
    }

    const downloadImage = (url, robotName, date) => {
        saveAs(url, robotName + "_" + date)
    }

    const generateImagePages = (imageData, tempRobots, selectedRobotId, startTime, endTime) => {

        let imageObjs = []
        let imagePages = []

        images.current.forEach((image) => {
                const imageMetadata = image.name.split('/')[1].split('.')[0].split('_')

                if (imageMetadata.length === 3) {
                    const imageDateTime = generateDatetime(imageMetadata[1], imageMetadata[2])
                    const imageDate = generateDate(imageMetadata[1])
                    const imageRobotId = parseInt(imageMetadata[0])

                    if (selectedRobotId !== 0) {
                        if (selectedRobotId === imageRobotId) {
                            const robotName = robots.current.find(robot => robot.robotId === imageRobotId).robotName

                            if (moment(imageDate).isSameOrAfter(startTime) && moment(imageDate).isSameOrBefore(endTime)) {
                                imageObjs.push({
                                    imageTag:
                                        <img className="image" src={image.presignedUrl} alt="new"/>,
                                    imageDate: imageDateTime,
                                    robotName: robotName,
                                    imageUrl: image.presignedUrl
                                })
                            }
                        }
                    } else {
                        if (moment(imageDate).isSameOrAfter(startTime) && moment(imageDate).isSameOrBefore(endTime)) {
                            console.log(image.name)
                            //TODO: Fix bug if it cant find the robot 
                            const robotName = robots.current.find(robot => robot.robotId === imageRobotId).robotName

                            imageObjs.push({
                                imageTag:
                                // <a href={image.presignedUrl} target="_blank">
                                //     <img className="image" src={image.presignedUrl} alt="new"/>
                                // </a>
                                    <img className="image" src={image.presignedUrl} alt="new"/>
                                ,
                                imageDate: imageDateTime,
                                robotName: robotName,
                                imageUrl: image.presignedUrl
                            })
                        }
                    }
                }
            }
        )

        for (let i = 0; i < imageObjs.length; i = i + 8) {
            imagePages.push(imageObjs.slice(i, i + 8))
        }

        if (imagePages.length === 0)
            imagePages.push(imageObjs.slice(0, 8))

        if (resetPage.current === true) {
            galleryPage.current = 1
            resetPage.current = false
        }

        galleryLength.current = Math.ceil(imagePages.length)
        return imagePages
    }

    useEffect(() => {
        async function fetchImageData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            let tempImageData;
            let tempRobots;

            setIsLoadingText("Fetching Robot Data")
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    // setRobots(response.data)
                    robots.current = response.data
                    tempRobots = response.data
                }).catch((error) => {
                })

            setIsLoadingText("Fetching Image Data")
            await axios.get(`/api/imagegallerymethods/get-all?bucketName=corobotimagegallery&username=` + email,
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    galleryLength.current = Math.ceil(response.data.length / 8)
                    images.current = response.data.reverse()
                    tempImageData = response.data
                }).catch((err) => console.log(err))

            generateImagePages(tempImageData, tempRobots)
            setIsLoadingText("")
            setIsLoading(false)
        }

        fetchImageData()

        const imageNameInterval = setIntervalAsync(async () => {
            if (window.location.pathname === '/imageGallery') {

                const email = await Auth.currentAuthenticatedUser().then((user) => {
                    return user.attributes.email;
                })
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()

                //TODO: Update with CustId
                await axios.get(`/api/imagegallerymethods/get-all?bucketName=corobotimagegallery&username=` + email,
                    {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(response => {
                        images.current = response.data.reverse()
                    }).catch((err) => console.log(err))
            } else {

            }

            return () => {
                clearIntervalAsync(imageNameInterval).then(r => {
                })
            }
        }, 5000)
    }, [])

    return (
        <div className="ImageGallery">
            <Dialog open={imageDialog.open} id="image-dialog">
                <DialogTitle>
                    <div>
                        {imageDialog.robotName}
                    </div>
                    <div>
                        {imageDialog.imageDate}
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{display: "flex", flexDirection: "column"}}
                >
                    {imageDialog.imageTag}
                </DialogContent>
                <DialogActions style={{padding: "1rem"}}>
                    <Button onClick={() => {
                        setImageDialog({open: false, imageTag: "", robotName: "", imageDate: ""})
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || isLoadingText !== ""}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className="header">
                <div className="title">
                    Image Gallery
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            {!isLoading && <div className="content-container">
                <div className="filters-container">
                    <div className="robot-selector">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Robot</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                defaultValue={0}
                                style={{width: "200px"}}
                                onChange={async e => {
                                    resetPage.current = true
                                    selectedRobotId.current = e.target.value
                                    setUpdateKey({key: Math.random()})
                                }}
                                label="Robot"
                            >
                                <MenuItem value={0}>All</MenuItem>
                                {robots.current.map(robot => {
                                    return <MenuItem value={robot.robotId}
                                                     key={Math.random()}>{robot.robotName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="datepicker-container">
                        <LocalizationProvider
                            dateAdapter={DateAdapter}
                            style={{height: "4rem", marginBottom: "2rem"}}>
                            <DatePicker
                                maxDate={moment(endTime.current)}
                                label="Start Date"
                                value={startTime.current}
                                onChange={async (newStartTime) => {
                                    resetPage.current = true
                                    startTime.current = newStartTime.format('YYYY-MM-DD')
                                    setUpdateKey({key: Math.random()})
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                minDate={moment(startTime.current)}
                                label="End Date"
                                value={endTime.current}
                                onChange={async (newEndTime) => {
                                    resetPage.current = true
                                    endTime.current = newEndTime.format('YYYY-MM-DD')
                                    setUpdateKey({key: Math.random()})
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="images-container" key={updateKey.key}>
                    {images.current.length > 0 &&
                        generateImagePages(images.current, robots, selectedRobotId.current, startTime.current, endTime.current)[galleryPage.current - 1].map((image) =>
                            <div className="image-container" key={image.imageDate}>
                                <div className="metadata-container">
                                    <div>{image.robotName}</div>
                                    <div>{image.imageDate}</div>
                                </div>
                                {image.imageTag}
                                <div className="image-actions">
                                    <Tooltip
                                        title="View"
                                    >
                                        <OpenInBrowserIcon className="icon" onClick={() => {
                                            setImageDialog({
                                                open: true,
                                                imageTag: image.imageTag,
                                                robotName: image.robotName,
                                                imageDate: image.imageDate
                                            })
                                        }}/>
                                    </Tooltip>
                                    <Tooltip
                                        title="Download"
                                    >
                                        <DownloadIcon className="icon"
                                                      onClick={() => downloadImage(image.imageUrl, image.robotName, image.imageDate)}/>
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="footer">
                    <Pagination key={updateKey} count={galleryLength.current} page={galleryPage.current}
                                onChange={(event, value) => {
                                    galleryPage.current = value
                                    setUpdateKey({key: Math.random()})
                                }}/>
                </div>
            </div>}
        </div>
    )
}

export default ImageGallery;