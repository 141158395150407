import React, {Component} from 'react';
import {Route} from 'react-router';
import './App.scss';

import {Layout} from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import RobotDetails from "./components/RobotDetails/RobotDetails";
import LiveStream from "./components/LiveStream/LiveStream";
import Admin from "./components/Admin/Admin";
import VideoArchive from "./components/VideoArchive/VideoArchive";
import Agnet from "./components/Agnet/Agnet";

import {withAuthenticator} from '@aws-amplify/ui-react'
import Logs from "./components/Logs/Logs";
import SensorData from "./components/SensorData/SensorData";
import SensorGraph from "./components/SensorGraph/SensorGraph";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import Report from "./components/Report/Report";


class App extends Component {
    static displayName = App.name;
    
    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home}/>
                <Route exact path='/robotDetails' component={RobotDetails}/>
                <Route exact path='/live' component={LiveStream}/>
                {/*<Route exact path='/admin' component={Admin}/>*/}
                <Route exact path='/archive' component={VideoArchive}/>
                <Route exact path='/logs' component={Logs}/>
                <Route exact path='/agnet' component={Agnet}/>
                <Route exact path='/sensorData' component={SensorData}/>
                <Route exact path='/sensorGraph' component={SensorGraph}/>
                <Route exact path='/imageGallery' component={ImageGallery}/>
                <Route exact path='/report' component={Report}/>
                {/*<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes}/>*/}
                {/*<AuthorizeRoute exact path='/settings' component={Settings}/>*/}
            </Layout>
        );
    }
}

export default withAuthenticator(App)