import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import axios from "axios";
import {DataGrid, gridClasses, GridFooter, GridFooterContainer} from "@mui/x-data-grid";
import moment from "moment";
import {Alert, Box, Button, Snackbar} from "@mui/material";
import {delay, NotificationCodeConstants} from "../../constants";

const EventTable = ({robotSensors, robots, sensors, sensorSettings, setIsLoadingText}) => {

    const isMobile = window.innerWidth <= 768
    const [isLoading, setIsLoading] = useState(true);
    const [sensorEvents, setSensorEvents] = useState([]);
    const [eventsToBeRemoved, setEventsToBeRemoved] = useState([])
    const [animationState, setAnimationState] = useState("")
    const [messageStatus, setMessageStatus] = useState({
        open: false,
        message: ""
    })
    
    

    const startStopAnimation = () => {
        setAnimationState("new-sensor-event-animation")
    }

    onanimationend = () => {
        setAnimationState("")
    }

    const eventCompare = (a, b) => {
        if (moment(a.startTime).isSameOrBefore(moment(b.startTime))) {
            return 1;
        } else {
            return -1;
        }
    }

    const sortEvents = (activeEvents, finishedEvents) => {
        activeEvents.sort(eventCompare)
        finishedEvents.sort(eventCompare)

        return activeEvents.concat(finishedEvents)
    }

    const generateEventsForDatagrid = () => {
        let idCounter = 0;

        let events = sensorEvents.map((event) => {
            idCounter += 1;
            const robotSensor = robotSensors.find((robotSensor) => robotSensor.robotSensorsId === event.robotSensorsId)
            const sensorName = sensors.find((sensor) => sensor.sensorId === robotSensor.sensorId).sensorName
            const sensorUnits = sensors.find((sensor) => sensor.sensorId === robotSensor.sensorId).units
            const robotName = robots.find((robot) => robot.robotId === robotSensor.robotId).robotName
            // const threshold = sensorSettings.find((sensorSetting) => sensorSetting.robotSensorsId === robotSensor.robotSensorsId).threshold

            // const description = "Robot [" + robotName + "] with sensor [" + sensorName + "] has met threshold"

            return {
                id: idCounter,
                robotName: robotName,
                sensorName: sensorName,
                startTime: moment.utc(event.startTime).local().format('MM/DD/YY hh:mm a'),
                endTime: event.endTime ? moment.utc(event.endTime).local().format('MM/DD/YY hh:mm a') : "N/A",
            }
        })

        let activeEvents = events.filter((event) => !event.endTime)
        let finishedEvents = events.filter((event) => event.endTime)

        return sortEvents(activeEvents, finishedEvents)
    }

    const handleAcknowledgingEvents = async () => {
        let localSensorEventsToBeRemoved = []
        let sensorEventIdsToBeRemoved = []

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        eventsToBeRemoved.forEach((item) => {
            localSensorEventsToBeRemoved.push(sensorEvents[item - 1])
            sensorEventIdsToBeRemoved.push(sensorEvents[item - 1].sensorEventId)
        })

        await axios.put('/api/sensoreventmethods', {
            SensorEvents: localSensorEventsToBeRemoved,
            Username: email
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then(() => {
                setSensorEvents(state => state.filter((item) => !sensorEventIdsToBeRemoved.includes(item.sensorEventId)))
            }).catch(() => {
            })

    }

    const EventTableFooter = () => {
        return (
            <GridFooterContainer className="event-table-footer">
                <Button
                    className="event-table-resolve-button"
                    disabled={eventsToBeRemoved.length === 0}
                    style={{
                        backgroundColor: eventsToBeRemoved.length > 0 ? "rgb(211, 47, 47)" : "rgba(0,0,0,0.26)"
                    }}
                    onClick={() => {
                        setIsLoadingText("Acknowledging Action Items")
                        handleAcknowledgingEvents().then(() => {
                            setIsLoadingText("")
                            setMessageStatus({
                                open: true,
                                message: "Action Items have been acknowledged"
                            })
                            console.log("Action Items have been acknowledged")
                        }).catch(() => {
                            setMessageStatus({
                                open: true,
                                message: "Action Items could not be resolved at this time. Please try again"
                            })
                        })
                    }}>
                    Resolve
                </Button>

                <GridFooter sx={{
                    border: 'none', // To delete double border.
                }}/>
            </GridFooterContainer>
        )
    }

    async function checkForNewEvents() {
        let localSensorEventsTimestamp = moment().utc();
        
        //TODO: UPDATE ME TO HANDLE AGNET STUFF
        while (window.location.pathname === '/sensorData' || window.location.pathname === '/agnet') {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.post('/api/sensoreventmethods', {
                RobotSensorsIds: robotSensors.map((robotSensor) => robotSensor.robotSensorsId),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setSensorEvents(response.data)
                })
            
            await delay(5000);
        }
    }

    useEffect(() => {
        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.post('/api/sensoreventmethods', {
                RobotSensorsIds: robotSensors.map((robotSensor) => robotSensor.robotSensorsId),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setSensorEvents(response.data)
                })

            setIsLoading(false)
            checkForNewEvents()
        }

        fetchData()
    }, [])

    return (
        <div className="EventTable">
            <Snackbar open={messageStatus.open} autoHideDuration={5000}
                      onClose={() => setMessageStatus({open: false, message: ""})}>
                <Alert onClose={() => setMessageStatus({open: false, message: ""})} severity="success"
                       sx={{width: '100%'}}>
                    {messageStatus.message}
                </Alert>
            </Snackbar>
            <div className={`event-table-container ${animationState}`}>
                <DataGrid
                    checkboxSelection
                    pageSize={isMobile ? 5 : 11}
                    rowsPerPageOptions={isMobile ? [5] : [11]}
                    columns={[
                        // {headerName: "Description", field: "description", flex: 1},
                        {headerName: "Robot", field: "robotName", flex: 1, headerAlign: "center", align: "center"},
                        {headerName: "Sensor", field: "sensorName", flex: 1, headerAlign: "center", align: "center"},
                        {headerName: "Start Time", field: "startTime", flex: 1, headerAlign: "center", align: "center"},
                        {headerName: "End Time", field: "endTime", flex: 1, headerAlign: "center", align: "center"},
                    ]}
                    onSelectionModelChange={(selectionModel) => {
                        setEventsToBeRemoved(selectionModel)
                    }}
                    getRowClassName={(params) => {
                        return params.row.endTime === "N/A" ? "active-event" : ""
                    }}
                    isRowSelectable={(params) => params.row.endTime != "N/A"}
                    rows={isLoading ? [] : generateEventsForDatagrid()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                    components={{Footer: EventTableFooter}}
                />
            </div>
        </div>
    )
}

export default EventTable;