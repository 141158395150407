import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';
 
const DeleteFleetDialog = ({
                               openDeleteFleet,
                               setOpenDeleteFleet,
                               dialogError,
                               setDialogError,
                               isLoading,
                               generateFleetDataForDataGrid
                           }) => {

    const [fleetsToBeRemoved, setFleetsToBeRemoved] = useState([]);
    const history = useHistory();

    const deleteFleets = async (fleetIds) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.delete("/api/fleetmethods", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            data: { FleetIds: fleetIds, Username: email }
        }).catch((e) => {
            throw e;
        })
    }

    return (
        <Dialog open={openDeleteFleet} id="delete-fleet-dialog">
            <DialogTitle>Delete Fleets</DialogTitle>
            <DialogContent>
                <DialogContentText style={{color: dialogError ? "red" : ""}}>
                    Select Fleets to delete:
                </DialogContentText>
            </DialogContent>
            <div style={{height: 371, width: 400}}>
                <DataGrid
                    checkboxSelection
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    columns={[
                        {headerName: "Fleet Name", field: "fleetName", width: 125},
                        {headerName: "Number of Associated Robots", field: "numRobots", width: 220},
                    ]}
                    rows={isLoading ? [] : generateFleetDataForDataGrid()}
                    onSelectionModelChange={fleetsToBeRemoved => {
                        setFleetsToBeRemoved(fleetsToBeRemoved)
                    }}
                />
            </div>
            <DialogActions>
                <Button onClick={() => {
                    setOpenDeleteFleet(false);
                    setFleetsToBeRemoved([]);
                    setDialogError(false);
                }}>
                    Close
                </Button>
                <Button
                    style={{
                        backgroundColor: "rgb(211, 47, 47)",
                        borderRadius: ".25rem",
                        padding: ".375rem 1rem",
                        color: "white"
                    }}
                    onClick={() => {
                        if (fleetsToBeRemoved.length === 0) {
                            setDialogError(true);
                        } else if (window.confirm("Are you sure you want to delete these Fleets? This will disassociate Robots assigned to this fleet.")) {
                            deleteFleets(fleetsToBeRemoved).then(() => {
                                setOpenDeleteFleet(false);
                                setFleetsToBeRemoved([]);
                                setDialogError(false);
                                history.go()
                            })
                        }
                    }}>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteFleetDialog;