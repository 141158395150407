import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import React, {useEffect, useRef, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";

import axios from "axios";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import VideoThumbnail from "../../assets/images/VideoThumbnail.svg";
import { Auth } from 'aws-amplify';
import 'shaka-player/dist/controls.css';

const shaka = require('shaka-player/dist/shaka-player.ui.js'); 


const VideoArchive = () => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [robots, setRobots] = useState([])
    const [videoArchives, setVideoArchives] = useState([])
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [archiveEndpoint, setArchiveEndpoint] = useState('')
    const [currentRobot, setCurrentRobot] = useState({})
    const isMobile = window.innerWidth <= 768 
    
    let manifestUrl = ""

    const playerStateRef = useRef(null)
    const uiOverlayRef = useRef(null)
    const streamUrlRef = useRef("")
    
    const generateVideoArchiveDataForDataGrid = () => {
        return videoArchives.map(videoArchive => {
            return {
                id: videoArchive.videoArchiveId, //TODO: THIS IS REALLY BAD DONT DO THIS    
                creationDatetime: moment(videoArchive.creationDatetime).local().format("MM-DD-YYYY HH:mm"),
                notes: videoArchive.notes,
                streamEndpoint: videoArchive.streamEndpoint
            }
        })
    }
    
    // const generateStreams = () => {
    //     let video = document.getElementById('video');
    //     let videoContainer = document.getElementById('video-container');
    //     let player = new shaka.Player(video);
    //    
    //     const livestream = archiveEndpoint.split("?token=")
    //
    //     if(livestream.length > 1) {
    //         player.getNetworkingEngine().registerRequestFilter((type, request) => {
    //             if(type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
    //                 request.headers.Authorization = 'Bearer ' + livestream[1]
    //             }
    //         })
    //     }
    //
    //     const config = {addSeekBar: true}
    //     const ui = new shaka.ui.Overlay(player, videoContainer, video)
    //     ui.configure(config)
    //     ui.getControls()
    //    
    //     manifestUrl = livestream[0]
    //     player.load(livestream[0])
    // }

    const generateStream = async (streamUrl) => {
        let video = document.getElementById('video');
        let videoContainer = document.getElementById('video-container');
        let player = null;

        if (streamUrl !== streamUrlRef.current) {
            if (playerStateRef.current !== null) {
                if (uiOverlayRef.current !== null) {
                    await uiOverlayRef.current.destroy().then(() => {
                    })
                    uiOverlayRef.current = null
                }
                if (streamUrl === "") {
                    await playerStateRef.current.destroy().then(() => {
                    })
                    playerStateRef.current = null
                    streamUrlRef.current = streamUrl;
                } else {
                    await playerStateRef.current.destroy().then(() => {
                    })
                    video = document.getElementById('video');
                    videoContainer = document.getElementById('video-container');
                    player = new shaka.Player(video);
                    playerStateRef.current = player
                }

            } else {
                player = new shaka.Player(video);
                playerStateRef.current = player
            }

            if (streamUrl) {
                const livestream = streamUrl.split("?token=")

                if (livestream.length > 1) {
                    player.getNetworkingEngine().registerRequestFilter((type, request) => {
                        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                            request.headers.Authorization = 'Bearer ' + livestream[1]
                        }
                    })
                }

                const config = {addSeekBar: true}
                const ui = new shaka.ui.Overlay(player, videoContainer, video)
                ui.configure(config)

                uiOverlayRef.current = ui
                streamUrlRef.current = streamUrl

                manifestUrl = livestream[0]
                await player.load(livestream[0]).then(() => {
                }).catch((e) => {
                })
            }
        }
    }

    useEffect(() => {
        async function getVideoArchiveData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            const initialStartDate = moment().subtract(1, 'months').format()
            const initialEndDate = moment().format()
            var robots;

            await axios.get('/api/robotmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    setRobots(response.data)
                    setCurrentRobot(response.data[0].robotId)
                    robots = response.data
                }).catch((error) => {
                    if (error.response.status === 401) {
                        
                    }
                })
            await axios.post('/api/azurevideoarchivemethods', {
                robotId: robots[0].robotId,
                startTime: initialStartDate,
                endTime: initialEndDate,
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setVideoArchives(response.data)
                    setStartTime(initialStartDate)
                    setEndTime(initialEndDate)
                })
            
            setIsLoading(false)
        }

        
        getVideoArchiveData()
    }, [])

    return (
        <div className="VideoArchive">
            <div className="header">
                <div className="title">
                    Video Archive
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {!isLoading && <div className="content-container">
                <div className="archive-settings">
                    <div className="robot-selector">
                        <FormControl>
                            <InputLabel id="demo-simple-select-standard-label">Robot</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                defaultValue={robots[0].robotId}
                                style={{width: "200px"}}
                                onChange={async e => {
                                    setCurrentRobot(e.target.value)
                                    const email = await Auth.currentAuthenticatedUser().then((user) => {
                                        return user.attributes.email;
                                    })
                                    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                                    await axios.post('/api/azurevideoarchivemethods', {
                                        robotId: e.target.value,
                                        startTime: moment(startTime),
                                        endTime: moment(endTime),
                                        Username: email
                                    }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                        .then((response) => {
                                            setVideoArchives(response.data)
                                        })

                                }}
                                label="Robot"
                            >
                                {robots.map(robot => {
                                    return <MenuItem value={robot.robotId}>{robot.robotName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="datepicker-container">
                        <LocalizationProvider
                            dateAdapter={DateAdapter}
                                              style={{height: "4rem", marginBottom: "2rem"}}>
                            <DatePicker
                                label="Start Date"
                                value={startTime}
                                onChange={async (newStartTime) => {
                                    setStartTime(newStartTime.format('YYYY-MM-DD'))
                                    const email = await Auth.currentAuthenticatedUser().then((user) => {
                                        return user.attributes.email;
                                    })
                                    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                                    await axios.post('/api/azurevideoarchivemethods', {
                                        robotId: currentRobot,
                                        startTime: moment(newStartTime).format('YYYY-MM-DDT00:00:00'),
                                        endTime: moment(endTime).format('YYYY-MM-DDT23:59:59'),
                                        Username: email
                                    }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                        .then((response) => {
                                            setVideoArchives(response.data)
                                        })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                minDate={moment(startTime)}
                                label="End Date"
                                value={endTime}
                                onChange={async (newEndTime) => {
                                    setEndTime(newEndTime.format('YYYY-MM-DD'))
                                    const email = await Auth.currentAuthenticatedUser().then((user) => {
                                        return user.attributes.email;
                                    })
                                    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                                    await axios.post('/api/azurevideoarchivemethods', {
                                        robotId: currentRobot,
                                        startTime: moment(startTime).format('YYYY-MM-DDT00:00:00'),
                                        endTime: moment(newEndTime).format('YYYY-MM-DDT23:59:59'),
                                        Username: email
                                    }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                        .then((response) => {
                                            setVideoArchives(response.data)
                                        })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="archive-container">
                    <div className="archive-table">
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'creationDatetime', sort: 'desc' }],
                                },
                            }}
                            pageSize={isMobile ? 5 : 20}
                            rowsPerPageOptions={isMobile ? [5] : [20]}
                            columns={[
                                {headerName: "Creation Date", field: "creationDatetime", width: 200},
                                {headerName: "Notes", field: "notes", width: 425},
                            ]}
                            rows={isLoading ? [] : generateVideoArchiveDataForDataGrid()}
                            onRowClick={videoArchive => {
                                setArchiveEndpoint(videoArchive.row.streamEndpoint)
                                generateStream(videoArchive.row.streamEndpoint).then(() => {})
                            }}
                        />
                    </div>
                    <div className="archive-viewer">
                        {/*{!isLoading && archiveEndpoint && */}
                        {/*    generateStreams()*/}
                        {/*}*/}
                        <div id="video-container">
                            <video id="video" style={{width: "100%"}} poster={VideoThumbnail} autoPlay muted></video>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default VideoArchive