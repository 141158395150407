import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import TabletIcon from "@mui/icons-material/Tablet";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import UpdateIcon from '@mui/icons-material/Update';
import React from "react";
import {Tooltip} from "@mui/material";

export const NotificationCodeConstants = {
    1: {logType: "alert", logTitle: "Mission Failure", logCriticality: "Error"},
    2: {logType: "detection", logTitle: "AI Detection", logCriticality: "Normal"},
    3: {logType: "mission", logTitle: "Mission Success", logCriticality: "Normal"},
    4: {logType: "alert", logTitle: "Robot Error", logCriticality: "Error"},
    5: {logType: "alert", logTitle: "General Log", logCriticality: "Normal"},
    6: {logType: "alert", logTitle: "Bad Network Connection", logCriticality: "Warning"},
    7: {logType: "alert", logTitle: "Robot Warning", logCriticality: "Warning"},
};

export const SensorThresholds = {
    1: {value: 10, direction: "greater"},
    2: {value: 19, direction: "less"},
    4: {value: 25, direction: "greater"},
    5: {value: 10, direction: "greater"}
}

export const errorCodes = ["1","4"]
export const warningCodes = ["6", "7"]

export const hasCriticalAlert = (logs) => {
    return logs.filter(log => NotificationCodeConstants[log.notificationCode]?.logCriticality === "Critical").length > 0
}

export const estimateBatteryPercentage = (batteryPct) => {
    return Math.trunc(batteryPct * .9);
}

export const calcVwFromRem = (rem) => {
    return (rem * .83333)
}

export const getStatusIconWithDescription = (status) => {
    if (status === "Docked") {
        return (
            <div className="status-icon-container">
                <OfflineBoltIcon className="status-icon" style={{fill: "blue"}}/> Docked
            </div>
        )
    } else if (status === "On Scheduled Mission") {
        return (
            <div className="status-icon-container">
                <RunCircleIcon className="status-icon" style={{fill: "green"}}/> On Mission
            </div>
        )
    } else if (status === "Idle") {
        return (
            <div className="status-icon-container">
                <ReportGmailerrorredIcon className="status-icon" style={{fill: "red"}}/> Idle
            </div>
        )
    } else if (status === "Offline") {
        return (
            <div className="status-icon-container">
                <CircleNotificationsIcon className="status-icon" style={{fill: "red"}}/> Offline
            </div>
        )
    } else if (status === "User Controlled") {
        return (
            <div className="status-icon-container">
                <TabletIcon className="status-icon" style={{fill: "green"}}/> User Controlled
            </div>
        )
    } else if (status === "Patching") {
        return (
            <div className="status-icon-container">
                <TabletIcon className="status-icon" style={{fill: "green"}}/> Patching
            </div>
        )
    } else {
        return (
            <div className="status-icon-container">
                <QuestionMarkIcon className="status-icon" style={{fill: "red"}}/> Status Unknown
            </div>
        )
    }
}

export const getStatusIcon = (status) => {
    if (status === "Docked") {
        return (
            <Tooltip
                title="Docked"
            >
                <div>
                    <OfflineBoltIcon className="status-icon" style={{fill: "blue"}}/>
                </div>
            </Tooltip>
        )
    } else if (status === "On Scheduled Mission") {
        return (
            <Tooltip
                title="On Scheduled Mission"
            >
                <div>
                    <RunCircleIcon className="status-icon" style={{fill: "green"}}/>
                </div>
            </Tooltip>
        )
    } else if (status === "Idle") {
        return (
            <Tooltip
                title="Idle"
            >
                <div>
                    <ReportGmailerrorredIcon className="status-icon" style={{fill: "orange"}}/>
                </div>
            </Tooltip>
        )
    } else if (status === "Offline") {
        return (
            <Tooltip
                title="Offline"
            >
                <div>
                    <CircleNotificationsIcon className="status-icon" style={{fill: "red"}}/>
                </div>
            </Tooltip>
        )
    } else if (status === "User Controlled") {
        return (
            <Tooltip
                title="User Controlled"
            >
                <div>
                    <TabletIcon className="status-icon" style={{fill: "green"}}/>
                </div>
            </Tooltip>
        )
    } else if (status === "Patching") {
        return (
            <Tooltip
                title="User Controlled"
            >
                <div>
                    <UpdateIcon className="status-icon" style={{fill: "orange"}}/>
                </div>
            </Tooltip>
        )
    } else {
        return (
            <Tooltip
                title="Status Unknown"
            >
                <div>
                    <QuestionMarkIcon className="status-icon" style={{fill: "red"}}/>
                </div>
            </Tooltip>
        )
    }
}

export const statusColor = (robot) => {
    switch (robot.currentStatus) {
        case 'Docked':
            return {backgroundColor: '#ABBFFF'}
        case 'On Scheduled Mission':
            return {backgroundColor: '#BBF3C0'}
        case 'Idle':
            return {backgroundColor: '#FAC898'}
        case 'Patching':
            return {backgroundColor: '#FAC898'}
        case 'User Controlled':
            return {backgroundColor: '#BBF3C0'}
        default:
            return {backgroundColor: '#FF9AA2'}
    }
}

export const batteryColor = (batteryPct) => {
    if (batteryPct <= 25) {
        return '#FF9AA2'
    } else if (batteryPct <= 50) {
        return '#FAC898'
    } else if (batteryPct <= 75) {
        return '#FFFFBA'
    } else {
        return '#BBF3C0'
    }
}

export const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}