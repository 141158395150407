import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import axios from "axios";

// TODO: Add Comments to new Controllers, new Helper methods, new JS files
// TODO: Add checkbox or some kind of confirmation before allowing updates
// TODO: Add check to see if there is a mission in the next 30 min 
// TODO: Changelogs? 
// Add better dialog for submitting updates
// Add dialog for when patch request fails because of pre existing patch request 
// Prevent users from submitting PatchRequest if Robot is offline?
// Add Current Version to EcosystemInfo so we can display it on the web app 


// Summary:  Dialog Box that allows users to submit Patch Requests

// Flow: 
// - User is presented list of releases that are compatible with it according to its hardware and software versions (excluding Robot Api Version)
// - User selects release
// - Release goes through a series of checks: Robot is docked and Robot Api Versions match
// - If requirements are met, user can submit a Patch Request
// - If Patch Request is successful, window will close and the robot will patch when ServiceManager goes through its loop
// - Patch Request will fail if an active Patch Request is already present for that robot

// Notes:
// - Release will be disabled if the robot is already on said release
const UpdateRobotDialog = ({robot, openUpdateRobotDialog, setOpenUpdateRobotDialog, releases, robotApiVersion, releasePreReqs, currentRelease}) => {

    // State objects
    const [selectedRelease, setSelectedRelease] = useState(null)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])

    // Function that submits a new patch request based on the selectedRelease
    // This function can only be invoked if the user selected a release (selectedRelease) from the dropdown and no errors are present
    const createNewPatchRequest = async () => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        const data = {
            RobotId: robot.robotId,
            ReleaseId: selectedRelease.releaseId,
            Username: email
        }

        await axios.post('/api/patchrequestmethods', data, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                // If we successfully created a Patch Request, close dialog
                if (response.data) {
                    setOpenUpdateRobotDialog(false)
                    setSelectedRelease(null)
                    setError(false)
                    setErrorMessage([])
                } 
                // If we failed to create a Patch Request (likely due to an existing one in the db), set error message
                else {
                    setError(true)
                    setErrorMessage(["Existing Patch Request", "A request has already been made to update this robot", "Please wait until it is resolved before submitting another request"])
                }
            }).catch(() => {
                // If there is a server/network error
                setError(true)
                setErrorMessage(["Network Error", "Patch Request could not be sent at this time", "Please try again later"])
            })
    }

    return (
        <Dialog open={openUpdateRobotDialog} id="update-robot-dialog">
            <DialogTitle>Update Robot - {robot.robotName}</DialogTitle>
            <DialogContent id="dialog-content" style={{display: "flex", flexDirection: "column"}}>
                <DialogContentText>
                    Select Release you would like to update your Robot to:
                </DialogContentText>
                {/*Release Dropdown*/}
                <TextField
                    error={error}
                    select
                    margin="dense"
                    id="name-textfield"
                    label="Release Name"
                    variant="standard"
                    style={{width: "100%", marginBottom: "2rem"}}
                    onChange={(e) => {
                        
                        // Set Selected Release state and find pre reqs for that release
                        setSelectedRelease(e.target.value)
                        var selectedReleasePreReq = releasePreReqs.current.find((releasePreReq) => {
                            if (releasePreReq.releasePreReqId === e.target.value.releasePreReqId) {
                                return releasePreReq
                            }
                        })
                        
                        // Check if robot is docked and api versions match
                        if(robot.currentStatus !== "Docked") {
                            setError(true)
                            setErrorMessage(["Robot is not docked", "Please ensure the robot is docked before attempting to send a patch request"])
                        }
                        else if (selectedReleasePreReq.robotApiVersion !== robotApiVersion.current) {
                            setError(true)
                            setErrorMessage(["Robot Api Version mismatch", "Current Robot Api Version: " + robotApiVersion.current, "Required Robot Api Version: " + selectedReleasePreReq.robotApiVersion])
                        }
                    }}
                >
                    {/*Generates list of releases that are deemed compatible with this robot*/}
                    {releases.current.map(release => {
                        return (<MenuItem
                            value={release}
                            disabled={release.releaseId === currentRelease.current.releaseId}
                            // disabled={false} used for testing
                            style={{width: "100%", justifyContent: "left", paddingLeft: "0.50rem"}}
                        >
                            {release.name}
                        </MenuItem>)
                        }
                    )}
                </TextField>
                {/*Error dialog*/}
                {error && <div className="error-message">
                    {errorMessage.map((message) =>  <span>{message}</span>)}
                </div>}

            </DialogContent>
            {/*Dialog Buttons*/}
            <DialogActions>
                {/*Close Button*/}
                <Button onClick={() => {
                    setOpenUpdateRobotDialog(false)
                    setSelectedRelease(null)
                    setError(false)
                    setErrorMessage([])
                }}>
                    Close
                </Button>
                {/*Submit Patch Request button*/}
                <Button
                    disabled={error || selectedRelease === null}
                    variant="contained"
                    onClick={() => {
                        createNewPatchRequest().then(() => {})
                    }}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>)
}

export default UpdateRobotDialog;