import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField
} from "@mui/material";
import {Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import axios from "axios";

const AddDeviceDialog = ({openAddDeviceDialog, setOpenAddDeviceDialog, deviceTypes}) => {
    
    const [selectedDeviceType, setSelectedDeviceType] = useState(null)
    const [deviceName, setDeviceName] = useState(null)
    
    const addNewDevice = async () => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        await axios.post("/api/robotmethods/add", {
            Username: email,
            ClassId: selectedDeviceType.robotClassId,
            Name: deviceName
        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
        
        console.log(selectedDeviceType)
        setSelectedDeviceType(null)
        setDeviceName(null)
        setOpenAddDeviceDialog(false)
    }
    
    return ( 
        <Dialog open={openAddDeviceDialog} id="add-device-dialog">
        <DialogTitle>Add new device</DialogTitle>
        <DialogContent id="dialog-content" style={{display: "flex", flexDirection: "column"}}>
            <DialogContentText>
                Select the type of device you would like to add:
            </DialogContentText>
            <TextField
                select
                margin="dense"
                id="name-textfield"
                label="Device Type"
                variant="standard"
                style={{width: "100%", marginBottom: "2rem"}}
                onChange={(e) => {
                    setSelectedDeviceType(e.target.value)
                }}
            >
                {deviceTypes.map(deviceType => {
                        return (<MenuItem
                            value={deviceType}
                            // disabled={false} used for testing
                            style={{width: "100%", justifyContent: "left", paddingLeft: "0.50rem"}}
                        >
                            {deviceType.description}
                        </MenuItem>)
                    }
                )}
            </TextField>

            <TextField
                margin="dense"
                id="name-textfield"
                label="Name"
                variant="standard"
                style={{width: "100%", marginBottom: "2rem"}}
                onChange={(e) => {
                    setDeviceName(e.target.value)
                }}
            />
        </DialogContent>
        {/*Dialog Buttons*/}
        <DialogActions>
            {/*Close Button*/}
            <Button onClick={() => {
                setOpenAddDeviceDialog(false)
                setSelectedDeviceType(null)
            }}>
                Close
            </Button>
            {/*Submit Patch Request button*/}
            <Button
                disabled={selectedDeviceType === null || deviceName === null || deviceName === ""}
                variant="contained"
                onClick={() => {
                    addNewDevice().then(() => {})
                }}>
                Add
            </Button>
        </DialogActions>
    </Dialog>)
}

export default AddDeviceDialog;