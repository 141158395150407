import {Tooltip} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatIcon from "@mui/icons-material/Chat";
import StopIcon from '@mui/icons-material/Stop';
import HomeIcon from '@mui/icons-material/Home';
import {getStatusIconWithDescription} from "../../constants";
import React, {useEffect, useRef, useState} from "react";
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";
import axios from "axios";
import {useHistory} from "react-router-dom";
import VideoThumbnail from "../../assets/images/VideoThumbnail.svg";
import {Auth} from 'aws-amplify';
import 'shaka-player/dist/controls.css';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import ReactPlayer from "react-player";

const shaka = require('shaka-player/dist/shaka-player.ui.js');

const MediaPlayerContainer = ({
                                  index,
                                  robot,
                                  setOpenStartLivestreamDialog,
                                  sendArchiveCommand,
                                  sendStopCommand,
                                  sendGoHomeCommand,
                                  sendStopMissionCommand,
                                  sendChangeCameraCommand,
                                  sendGainChangeCommand,
                                  sendExposureChangeCommand,
                                  sendCameraNumberChangeCommand,
                                  setOpenAgnetSettings,
                                  agnetAccess,
                                  setOpenAgnetDialog,
                                  hasAgnetSettings,
                                  getBatteryIcon,
                              }) => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [robotState, setRobotState] = useState({})
    const [livestreamUrl, setLivestreamUrl] = useState("")

    // const generateStream = () => {
    //     let video = document.getElementById('video' + index);
    //     let videoContainer = document.getElementById('video-container' + index);
    //     let player = new shaka.Player(video);
    //    
    //     const livestream = robot.liveStreamEndpoints.split("?token=")
    //
    //     if(livestream.length > 1) {
    //         player.getNetworkingEngine().registerRequestFilter((type, request) => {
    //             if(type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
    //                 request.headers.Authorization = 'Bearer ' + livestream[1]
    //             }
    //         })
    //         // window.player = player;
    //     }
    //
    //     const config = {addSeekBar: true}
    //     const ui = new shaka.ui.Overlay(player, videoContainer, video)
    //     ui.configure(config)
    //     ui.getControls()
    //    
    //     player.load(livestream[0]).then(() => {}).catch((e) => {})
    // }

    const playerStateRef = useRef(null)
    const uiOverlayRef = useRef(null)
    const streamUrlRef = useRef("")
    let manifestUrl = "";

    const generateStream = async (streamUrl) => {
        let video = document.getElementById('video' + index);
        let videoContainer = document.getElementById('video-container' + index);
        let player = null;

        console.log(streamUrl)
        // const streamUrl = robot.liveStreamEndpoints.split("?token=")

        if (streamUrl !== streamUrlRef.current) {
            if (playerStateRef.current !== null) {
                if (uiOverlayRef.current !== null) {
                    await uiOverlayRef.current.destroy().then(() => {
                    })
                    uiOverlayRef.current = null
                }
                if (streamUrl === "") {
                    await playerStateRef.current.destroy().then(() => {
                    })
                    playerStateRef.current = null
                    streamUrlRef.current = streamUrl;
                } else {
                    await playerStateRef.current.destroy().then(() => {
                    })
                    video = document.getElementById('video' + index);
                    videoContainer = document.getElementById('video-container' + index);
                    player = new shaka.Player(video);
                    playerStateRef.current = player
                }

            } else {
                player = new shaka.Player(video);
                playerStateRef.current = player
            }

            if (streamUrl) {
                const livestream = streamUrl.split("?token=")

                if (livestream.length > 1) {
                    player.getNetworkingEngine().registerRequestFilter((type, request) => {
                        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                            request.headers.Authorization = 'Bearer ' + livestream[1]
                        }
                    })
                }

                const config = {addSeekBar: true}
                const ui = new shaka.ui.Overlay(player, videoContainer, video)
                ui.configure(config)

                uiOverlayRef.current = ui
                streamUrlRef.current = streamUrl

                manifestUrl = livestream[0]
                await player.load(livestream[0]).then(() => {
                }).catch((e) => {
                })
            }
        }
    }

    useEffect(() => {
        let localRobotState;

        async function fetchData() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            let tempRobot;
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    const robots = response.data
                    setRobotState(robots.find(dbRobot => dbRobot.robotId === robot.robotId))
                    localRobotState = robots.find(dbRobot => dbRobot.robotId === robot.robotId)
                    tempRobot = robots.find(dbRobot => dbRobot.robotId === robot.robotId)
                }).catch(error => {
                    if (error.response.status === 401) {
                        history.push("/Identity/Account/Login")
                        history.go(0);
                    }
                })
            setIsLoading(false);
            // generateStream(tempRobot.liveStreamEndpoints).then(() => {})
            setLivestreamUrl(tempRobot.liveStreamEndpoints)
        }

        fetchData()

        const asyncInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/live') {
                await clearIntervalAsync(asyncInterval)
            }
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    const robots = response.data
                    const matchedRobot = robots.find(latestRobot => localRobotState.robotId === latestRobot.robotId && (localRobotState.liveStreamEndpoints !== latestRobot.liveStreamEndpoints || localRobotState.currentStatus !== latestRobot.currentStatus || localRobotState.batteryPct !== latestRobot.batteryPct))
                    if (matchedRobot) {
                        localRobotState = matchedRobot
                        await setRobotState(matchedRobot)
                        // generateStream(matchedRobot.liveStreamEndpoints).then(() => {})
                        setLivestreamUrl(matchedRobot.liveStreamEndpoints)
                    }
                }).catch(error => {

                })
        }, 5000)

        return () => {
            clearIntervalAsync(asyncInterval).then(r => {
            })
        }
    }, [])

    return (
        <div className="media-player">
            <div className="livestream-info">
                <div>
                    {robotState.robotName}
                </div>
                <div className="status">
                    {getStatusIconWithDescription(robotState.currentStatus)}
                    <div className="battery-container">
                        <div className="battery-percentage">
                            {robotState.batteryPct ? robotState.batteryPct : 0}%
                        </div>
                        {getBatteryIcon(robotState.batteryPct)}
                    </div>
                </div>
            </div>
            <div className="media-container">
                <div className="video-player">
                    <ReactPlayer
                        playing
                        controls
                        // light={"../../assets/CommonObjects-FinalLogo-01.svg"}
                        style={{backgroundColor: "black"}}
                        width={"100%"}
                        height={"100%"}
                        url={livestreamUrl}
                    />
                </div>
            </div>
            <div className="livestream-commands">
                {agnetAccess && <div className="agnet">
                    <Tooltip title="Agnet Settings">
                        <SettingsIcon className="icon" onClick={() => {
                            setOpenAgnetSettings({open: true, robotId: robotState.robotId})
                        }}/>
                    </Tooltip>
                    {<Tooltip title="Agnet Message">
                        <ChatIcon className={"icon" + (hasAgnetSettings(robot) ? "" : "-disabled")} onClick={() => {
                            if (hasAgnetSettings(robot)) {
                                setOpenAgnetDialog({open: true, robotId: robotState.robotId})
                            }
                        }}/>
                    </Tooltip>}
                </div>}
                <div className="robot">
                    <Tooltip title="Return Home">
                        <HomeIcon className="icon" onClick={() => {
                            sendGoHomeCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                    <Tooltip title="Stop Mission">
                        <StopIcon className="icon" onClick={() => {
                            sendStopMissionCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                    <Tooltip title="Change Camera">
                        <CameraswitchIcon className="icon" onClick={() => {
                            sendChangeCameraCommand(robotState.robotId).then(() => {
                            })
                        }}/>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default React.memo(MediaPlayerContainer)