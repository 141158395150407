import {
    Backdrop,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField, Tooltip
} from "@mui/material";
import React, {useState} from "react";
import DownloadIcon from '@mui/icons-material/Download';
import {Auth} from "aws-amplify";
import axios from "axios";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

// Component for viewing images for a particular report
const ViewReportDialog = ({openViewReportDialog, setOpenViewReportDialog, reportImages}) => {

    const [downloadingImage, setDownloadingImage] = useState(false)
    
    // Function to download a specified image
    const downloadImage = (imageData, fileName) => {
        let binaryString = window.atob(imageData)
        let binaryLen = binaryString.length
        let bytes = new Uint8Array(binaryLen)

        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i)
            bytes[i] = ascii
        }

        let blob = new Blob([bytes], {type: "image/jpeg"})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
    }

    return (
        <Dialog open={openViewReportDialog} id="view-report-dialog">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={downloadingImage}
            >
                <div>Downloading Image&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <DialogTitle>Report Images</DialogTitle>
            <DialogContent id="dialog-content" style={{display: "flex", flexDirection: "column"}}>
                {reportImages.map((imageInfo) => {
                    return (
                        <div className="report-image">
                            <img className="image" src={imageInfo.presignedUrl} alt="new"/>
                            <div className="image-info">
                                <div>
                                    {imageInfo.name.split(/REPORT[1-9]+_/)[1]}
                                </div>
                                <Tooltip
                                    title="Download image"
                                >
                                    <DownloadIcon className="download-icon" onClick={async () => {
                                        // Makes request to get image data for particular image
                                        setDownloadingImage(true)

                                        const email = await Auth.currentAuthenticatedUser().then((user) => {
                                            return user.attributes.email;
                                        })
                                        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

                                        await axios.post('/api/reportmethods/downloadImage', {
                                            Username: email,
                                            ImagePath: imageInfo.name
                                        }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                            .then((response) => {
                                                if (response.data.length > 0) {
                                                    downloadImage(response.data, imageInfo.name.split(/REPORT[1-9]+_/)[1])
                                                }
                                            })

                                        setDownloadingImage(false)
                                    }}/>
                                </Tooltip>
                            </div>
                        </div>
                    )
                })}
            </DialogContent>
            <DialogActions>
                {/*Close Button*/}
                <Button onClick={() => {
                    setOpenViewReportDialog(false)
                }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewReportDialog;