import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField
} from "@mui/material";
import {DataGrid, GridFooter, GridFooterContainer} from "@mui/x-data-grid";
import {delay, errorCodes, NotificationCodeConstants, warningCodes} from "../../constants";
import moment from "moment";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";

const Logs = () => {

    const history = useHistory()
    const isMobile = window.innerWidth <= 768
    const [animationState, setAnimationState] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingText, setIsLoadingText] = useState("")
    const [fetchingMoreLogs, setFetchingMoreLogs] = useState({
        open: false,
        message: ""
    })
    const [robotInfo, setRobotInfo] = useState([])
    const [fleets, setFleets] = useState([])
    const [fleetRobots, setFleetRobots] = useState([])
    
    const [archivedLogs, setArchivedLogs] = useState([])
    const [filteredArchivedLogs, setFilteredArchivedLogs] = useState([])
    const [actionItems, setActionItems] = useState([])
    const [newLogs, setNewLogs] = useState(false)
    const [oldestRobotLogTimestamps, setOldestRobotLogTimestamps] = useState([])
    
    const [actionItemsToBeRemoved, setActionItemsToBeRemoved] = useState([])
    const [messageStatus, setMessageStatus] = useState({
        open: false,
        message: ""
    })
    
    let initialEndDateTime = moment().format("YYYY-MM-DDTHH:mm:ss") 
    const numLogsToFetch = 100;
    
    //TODO: FIX ALL DATE FORMATS
    
    const [filters, setFilters] = useState({
        logLevel: "all",
        fleetId: 0,
        robotId: 0,
        notificationCode: 0,
        startDateTime: moment("2022-01-01T12:00:00"),
        endDateTime: initialEndDateTime
    })
    
    const defaultFilters = {
        logLevel: "all",
        fleetId: 0,
        robotId: 0,
        notificationCode: 0,
        startDateTime: moment("2022-01-01T12:00:00"),
        endDateTime: initialEndDateTime
    }

    const startStopAnimation = () => {
        setAnimationState("new-action-item-animation")
    }

    onanimationend = () => {
        setAnimationState("")
    }

    async function checkForNewActionItems() {
        let localActionItemsTimestamp = moment().utc();
        let localArchivedLogsTimestamp = moment().utc();

        while (window.location.pathname === '/logs') {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.post('/api/logmethods', {
                    Acknowledged: false,
                    Codes: [1, 4, 6],
                    Timestamp: localActionItemsTimestamp,
                    Username: email
                },
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    if (response.data.length > 0 && window.location.pathname === '/logs') {
                        startStopAnimation()
                        localActionItemsTimestamp = moment().utc()
                        setActionItems((state) => [...state, ...response.data])
                        setNewLogs(true)
                    }
                }).catch(error => {

                })
            
            if(!newLogs) {
                await axios.post('/api/logmethods', {
                        Acknowledged: true,
                        Codes: Object.keys(NotificationCodeConstants).map(Number),
                        Timestamp: localArchivedLogsTimestamp,
                        Username: email
                    },
                    {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(async response => {
                        if (response.data.length > 0 && window.location.pathname === '/logs') {
                            setNewLogs(true)
                            localArchivedLogsTimestamp = moment.utc()
                        }
                    }).catch(error => {

                    })
            }            

            await delay(5000);
        }
    }

    const generateActionItemsForDataGrid = () => {
        let idCounter = 0;

        return actionItems.map((item) => {
            idCounter += 1;
            const robotName = robotInfo.find((robot) => robot.robotId === item.robotId).robotName

            return {
                id: idCounter,
                robotName: robotName,
                timeOfEvent: moment.utc(item.timeOfEvent).local().format("MM-DD-YYYY HH:mm:ss"),
                description: item.ultaviewLogEvent,
                logCriticality: NotificationCodeConstants[item.notificationCode].logCriticality
            }
        })
    }

    const generateArchivedLogsForDataGrid = () => {
        let idCounter = 0;
        return filteredArchivedLogs.map((item) => {
            idCounter += 1;
            const robotName = robotInfo.find((robot) => robot.robotId === item.robotId).robotName

            const notificationCode = NotificationCodeConstants[item.notificationCode]
            
            return {
                id: idCounter,
                robotName: robotName,
                timeOfEvent: moment.utc(item.timeOfEvent).local().format("MM-DD-YYYY HH:mm:ss"),
                description: item.ultaviewLogEvent,
                logCriticality: notificationCode ? notificationCode.logCriticality : "Error"
            }
        })
    }

    //TODO: Look into updating multiple logs at once
    const handleAcknowledgingActionItems = async () => {
        let localActionItems = []
        let logIdsToBeRemoved = []

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        
        actionItemsToBeRemoved.forEach(item => {
            localActionItems.push(actionItems[item - 1])
            logIdsToBeRemoved.push(actionItems[item - 1].logId)
        })

        localActionItems.forEach(item => {
            item.acknowledged = 1
            item.Username = email
        })
        
        await axios.put('/api/logmethods/updateList', localActionItems,{headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                //TODO: Think about what will happen if one of the logs fails to update
                setActionItems(state => state.filter((item) => !logIdsToBeRemoved.includes(item.logId)))
            })
            .catch((error) => {

            })
    }
    
    const fetchAdditionalLogs = async () => {
        let logs = []
        
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        let localTimestampRecord = oldestRobotLogTimestamps;
        for (const robot of robotInfo) {
            const oldestTimestampRecord = oldestRobotLogTimestamps.find((log) => log.robotId === robot.robotId) 
            
            if(oldestTimestampRecord.timestamp !== null) {
                await axios.post('/api/logmethods/robotLogRequest', {
                    RobotId: robot.robotId,
                    Timestamp: oldestTimestampRecord.timestamp,
                    Username: email,
                    Count: numLogsToFetch
                }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(async response => {
                        logs = logs.concat(response.data)
                        if(response.data[response.data.length - 1] !== undefined) {
                            localTimestampRecord = localTimestampRecord.map((record) => {
                                if(record.robotId === robot.robotId) {
                                    return {...record, timestamp: response.data[response.data.length - 1].timeOfEvent}
                                }
                                
                                return record
                            })
                        }
                    })
            }
            
        }
        
        setOldestRobotLogTimestamps(localTimestampRecord)
        setArchivedLogs(state => [...state, ...logs])
        setFilters(defaultFilters)
        setFilteredArchivedLogs([...archivedLogs, ...logs])

        return logs.length
    }
    
    const refreshLogs = async () => {
        let logs = []
        let refreshTimestamp;
        
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()

        let localTimestampRecord = oldestRobotLogTimestamps;
        for (const robot of robotInfo) {
            await axios.post('/api/logmethods/robotLogRequest', {
                RobotId: robot.robotId,
                Timestamp: null,
                Username: email,
                Count: numLogsToFetch
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    logs = logs.concat(response.data)
                    refreshTimestamp = moment().format("YYYY-MM-DDTHH:mm:ss")
                    
                    if(response.data[response.data.length - 1] !== undefined) {
                        localTimestampRecord = localTimestampRecord.map((record) => {
                            if(record.robotId === robot.robotId) {
                                return {...record, timestamp: response.data[response.data.length - 1].timeOfEvent}
                            }

                            return record
                        })
                    } else {
                        localTimestampRecord = localTimestampRecord.map((record) => {
                            if(record.robotId === robot.robotId) {
                                return {...record, timestamp: null}
                            }

                            return record
                        })
                    }
                })
        }

        setOldestRobotLogTimestamps(localTimestampRecord)
        defaultFilters.endDateTime = refreshTimestamp;
        setArchivedLogs(logs)
        setFilters(defaultFilters)
        setFilteredArchivedLogs(logs)
        setNewLogs(false)
    }
    
    const ActionCenterFooter = () => {
        return (
            <GridFooterContainer className="action-center-footer">
                <Button
                    className="action-center-resolve-button"
                    disabled={actionItemsToBeRemoved.length === 0}
                    style={{
                        backgroundColor: actionItemsToBeRemoved.length > 0 ? "rgb(211, 47, 47)" : "rgba(0,0,0,0.26)" 
                    }}
                    onClick={() => {
                        setIsLoadingText("Acknowledging Action Items")
                        handleAcknowledgingActionItems().then(() => {
                            setIsLoadingText("")
                            setMessageStatus({
                                open: true,
                                message: "Action Items have been acknowledged"
                            })
                            console.log("Action Items have been acknowledged")
                        }).catch(() => {
                            setMessageStatus({
                                open: true,
                                message: "Action Items could not be resolved at this time. Please try again"
                            })
                        })
                    }}>
                    Resolve
                </Button>

                <GridFooter sx={{
                    border: 'none', // To delete double border.
                }}/>
            </GridFooterContainer>
        )
    }
    
    const ArchiveLogsFooter = () => {
        return (
            <GridFooterContainer className="archive-log-footer">
                <div>
                    <Button
                        className="fetch-additional-logs-button"
                        onClick={() => {
                            setFetchingMoreLogs({
                                open: true,
                                message: "Fetching additional logs"
                            })
                            fetchAdditionalLogs().then((count) => {
                                setFetchingMoreLogs({
                                    open: false,
                                    message: ""
                                })
                                setMessageStatus({
                                    open: true,
                                    message: "Fetched " + count + " additional Logs"
                                })
                            }).catch(() => {
                                setFetchingMoreLogs({
                                    open: false,
                                    message: ""
                                })
                                setMessageStatus({
                                    open: true,
                                    message: "Could not fetch additional Archived Logs at this time. Please try again"
                                })
                            })
                        }}>
                        Fetch more Archived Logs
                    </Button>
                    <Button
                        className="refresh-table-button"
                        style={{
                            backgroundColor: newLogs ? "#3497fd" : "rgba(0,0,0,0.26)"
                        }}
                        onClick={() => {
                            setFetchingMoreLogs({
                                open: true,
                                message: "Refreshing Archived Logs"
                            })
                            refreshLogs().then(() =>  {
                                setFetchingMoreLogs({
                                    open: false,
                                    message: ""
                                })
                                setMessageStatus({
                                    open: true,
                                    message: "Refreshed logs"
                                })
                            }).catch(() => {
                                setFetchingMoreLogs({
                                    open: false,
                                    message: ""
                                })
                                setMessageStatus({
                                    open: true,
                                    message: "Could not refresh Archived Logs at this time. Please try again"
                                })
                            })
                        }}>
                        Refresh
                    </Button>
                </div>
                <GridFooter sx={{
                    border: 'none', // To delete double border.
                }}/>
            </GridFooterContainer>
        )
    }

    const RobotNameDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Robot Name</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.robotId}
                    label="Robot"
                    onChange={(e) => {
                        let updatedFilters = {...filters, robotId: e.target.value}
                        setFilters(state => {return {...state, robotId: e.target.value}})
                        updateFilteredLogs(updatedFilters)
                    }}
                >
                    <MenuItem value={0}>All</MenuItem>
                    {robotInfo.map((robot) => {
                        return <MenuItem value={robot.robotId}>{robot.robotName}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
    
    const FleetDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Fleet</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.fleetId}
                    label="Fleet"
                    onChange={(e) => {
                        let updatedFilters = {...filters, fleetId: e.target.value}
                        setFilters(state => {return {...state, fleetId: e.target.value}})
                        updateFilteredLogs(updatedFilters)
                    }}
                >
                    <MenuItem value={0}>All</MenuItem>
                    {fleets.map((fleet) => {
                        return <MenuItem value={fleet.fleetId}>{fleet.fleetName}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
    
    const LogLevelDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Log Level</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.logLevel}
                    label="Log Level"
                    onChange={(e) => {
                        let updatedFilters = {...filters, logLevel: e.target.value, notificationCode: 0}
                        setFilters(state => {return {...state, logLevel: e.target.value, notificationCode: 0}})
                        updateFilteredLogs(updatedFilters)
                    }}
                >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"normal"}>Normal</MenuItem>
                    <MenuItem value={"warning"}>Warning</MenuItem>
                    <MenuItem value={"error"}>Error</MenuItem>
                </Select>
            </FormControl>
        )
    }
    
    const LogTypeDropdown = () => {
        return (
            <FormControl variant="standard" className="filter-dropdown">
                <InputLabel id="demo-simple-select-label">Log Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.notificationCode}
                    label="Log Type"
                    onChange={(e) => {
                        let updatedFilters = {...filters, notificationCode: e.target.value, logLevel: "all"}
                        setFilters(state => {return {...state, notificationCode: e.target.value, logLevel: "all"}})
                        updateFilteredLogs(updatedFilters)
                    }}
                >
                    <MenuItem value={0}>All</MenuItem>
                    {Object.keys(NotificationCodeConstants).map((notificationCode) => {
                        return <MenuItem value={notificationCode}>{NotificationCodeConstants[notificationCode].logTitle}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
    
    const StartEndDateTimePicker = () => {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DateTimePicker
                    ampm={false}
                    renderInput={(props) => <TextField {...props} />}
                    label="Start Date/Time"
                    value={filters.startDateTime}
                    maxDateTime={moment(filters.endDateTime)}
                    onChange={(e) => {
                        console.log(e)
                        let updatedFilters = {...filters, startDateTime: e.format("YYYY-MM-DDTHH:mm:ss")}
                        setFilters(state => {return {...state, startDateTime: e.format("YYYY-MM-DDTHH:mm:ss")}})
                        updateFilteredLogs(updatedFilters)
                    }}
                />
                <DateTimePicker
                    ampm={false}
                    renderInput={(props) => <TextField {...props} />}
                    label="End Date/Time"
                    value={filters.endDateTime}
                    minDateTime={moment(filters.startDateTime)}
                    onChange={(e) => {
                        let updatedFilters = {...filters, endDateTime: e.format("YYYY-MM-DDTHH:mm:ss")}
                        setFilters(state => {return {...state, endDateTime: e.format("YYYY-MM-DDTHH:mm:ss")}})
                        updateFilteredLogs(updatedFilters)
                    }}
                />
            </LocalizationProvider>
        )
    }
    
    const updateFilteredLogs = (newFilters) => {
        if(JSON.stringify(newFilters) === JSON.stringify(defaultFilters)) {
            setFilteredArchivedLogs(archivedLogs)
        } else {
            let filteredLogs = archivedLogs; 
            
            //Apply Filter for Log Level
            if(newFilters.logLevel !== defaultFilters.logLevel) {
                filteredLogs = filteredLogs.filter((log) => {
                    if(newFilters.logLevel === "error") {
                        return errorCodes.includes(log.notificationCode)
                    } else if (newFilters.logLevel === "warning") {
                        return warningCodes.includes(log.notificationCode)
                    } else if (newFilters.logLevel === "normal") {
                        return !errorCodes.includes(log.notificationCode) && !warningCodes.includes(log.notificationCode) 
                    }
                })
            }
            
            //Apply Filter for Robot Name
            if(newFilters.robotId !== defaultFilters.robotId) {
                filteredLogs = filteredLogs.filter((log) => {
                    return log.robotId === newFilters.robotId
                })
            }
            
            //Apply Filter for Log Type
            if(newFilters.notificationCode !== defaultFilters.notificationCode) {
                filteredLogs = filteredLogs.filter((log) => {
                    return log.notificationCode === newFilters.notificationCode
                })
            }
            
            //Apply Filter for time 
            if(newFilters.startDateTime !== defaultFilters.startDateTime || newFilters.endDateTime !== defaultFilters.endDateTime) {
                filteredLogs = filteredLogs.filter((log) => {
                    return moment.utc(log.timeOfEvent).isSameOrAfter(newFilters.startDateTime) && moment.utc(log.timeOfEvent).isSameOrBefore(newFilters.endDateTime)
                })
            }
            
            //Apply Filter for fleet
            if(newFilters.fleetId !== 0) {
                let fleetRobotIds = []
                fleetRobots.forEach((robot) => {
                    if(robot.fleetId === newFilters.fleetId) {
                        fleetRobotIds.push(robot.robotId)
                    }
                })
                filteredLogs = filteredLogs.filter((log) => fleetRobotIds.includes(log.robotId))
            }
            
            setFilteredArchivedLogs(filteredLogs)
        }
    }
    
    //Todo: Be wary of accidentally refreshing the page from Code changes, double check this does not persistent in higher environments
    useEffect(() => {
        async function getLogs() {
            let tempRobotInfo;
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            setIsLoadingText("Fetching Robot Data")
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    setRobotInfo(response.data)
                    tempRobotInfo = response.data
                }).catch(error => {

                })

            setIsLoadingText("Fetching Fleet Data")
            await axios.get('/api/fleetmethods/' + email, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(async response => {
                    setFleets(response.data)
                }).catch(error => {

                })

            await axios.get('/api/fleetrobotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    setFleetRobots(response.data)
                }).catch(error => {

                })

            setIsLoadingText("Fetching Action Items")
            await axios.post('/api/logmethods', {
                    Acknowledged: false,
                    Username: email,
                    Codes: [1, 4, 6]
                },
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    if (response.data.length > 0) {
                        setActionItems(response.data.sort((a, b) => {
                            a.timeOfEvent.localeCompare(b.timeOfEvent)
                        }))
                    } else {
                        setActionItems([])
                    }
                }).catch(error => {

                })

            setIsLoadingText("Fetching Archived Logs")
            let logs = []
            
            for (const robot of tempRobotInfo) {
                await axios.post('/api/logmethods/robotLogRequest', {
                    RobotId: robot.robotId,
                    Timestamp: null,
                    Username: email,
                    Count: numLogsToFetch
                }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(async response => {
                        logs = logs.concat(response.data)
                        if(response.data[response.data.length - 1] !== undefined) {
                            setOldestRobotLogTimestamps(state => [...state, {
                                robotId: robot.robotId,
                                timestamp: response.data[response.data.length - 1].timeOfEvent
                            }])
                        } else {
                            setOldestRobotLogTimestamps(state => [...state, {
                                robotId: robot.robotId,
                                timestamp: null
                            }])
                        }
                    })
            }

            setArchivedLogs(logs.sort((a, b) => {
                a.timeOfEvent.localeCompare(b.timeOfEvent)
            }))
            setFilteredArchivedLogs(logs.sort((a, b) => {
                a.timeOfEvent.localeCompare(b.timeOfEvent)
            }))
            
            setIsLoading(false)
            setIsLoadingText("")
            checkForNewActionItems()
        }

        getLogs()
    }, [])

    return (
        <div className="Logs">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={fetchingMoreLogs.open}
            >
                <div>{fetchingMoreLogs.message}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Snackbar open={messageStatus.open} autoHideDuration={5000}
                      onClose={() => setMessageStatus({open: false, message: ""})}>
                <Alert onClose={() => setMessageStatus({open: false, message: ""})} severity="success"
                       sx={{width: '100%'}}>
                    {messageStatus.message}
                </Alert>
            </Snackbar>
            <div className="header">
                <div className="title">
                    Logs
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            <div className="content-container">
                <div className="table-container">
                    <div className="table-title">
                        Action Center
                    </div>
                    <div className={`action-center-table ${animationState}`}>
                        <DataGrid
                            checkboxSelection
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'timeOfEvent', sort: 'desc'}],
                                },
                            }}
                            onSelectionModelChange={(selectionModel) => {
                                setActionItemsToBeRemoved(selectionModel)
                            }}
                            pageSize={isMobile ? 5 : 20}
                            rowsPerPageOptions={isMobile ? [5] : [20]}
                            getRowClassName={(params) => {
                                return params.row.logCriticality === "Error" ? "log--error" : "log--warning"
                            }}
                            columns={[
                                {headerName: "Robot Name", field: "robotName", width: 200,},
                                {headerName: "Time of Event", field: "timeOfEvent", width: 200},
                                {headerName: "Description", field: "description", width: 425},
                            ]}
                            rows={isLoading ? [] : generateActionItemsForDataGrid()}
                            components={{Footer: ActionCenterFooter}}
                        />
                    </div>
                </div>
                <div className="table-container">
                    <div className="table-title">
                        Archived Logs
                    </div>
                    <div className="filters-container">
                        <div className="basic-filters">
                            {RobotNameDropdown()}
                            {FleetDropdown()}
                            {LogLevelDropdown()}
                            {LogTypeDropdown()}
                        </div>
                        <div className="time-filters">
                            {StartEndDateTimePicker()}
                        </div>
                    </div>
                    <div className="archived-logs-table">
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'timeOfEvent', sort: 'desc'}],
                                },
                            }}
                            pageSize={isMobile ? 5 : 20}
                            rowsPerPageOptions={isMobile ? [5] : [20]}
                            getRowClassName={(params) => {
                                switch (params.row.logCriticality) {
                                    case "Error":
                                        return "log--error";
                                    // break;
                                    case "Warning":
                                        return "log--warning";
                                    // break;
                                    default:
                                        return "log--general"
                                }
                            }}
                            columns={[
                                {headerName: "Robot Name", field: "robotName", width: 200,},
                                {headerName: "Time of Event", field: "timeOfEvent", width: 200},
                                {headerName: "Description", field: "description", width: 425},
                            ]}
                            rows={isLoading ? [] : generateArchivedLogsForDataGrid()}
                            components={{Footer: ArchiveLogsFooter}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Logs;