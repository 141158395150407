import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React, {useState} from "react";
import QRCode from "react-qr-code";

const CellConnectDialog = ({openQrDialog, setOpenQrDialog}) => {

    // const handleSubmit = () => {
    //     if(networkSettings.ssid == "" || networkSettings.password == "")
    //         setNetworkSettings(state => {return {...state, error: true}})
    //     else {
    //         setOpenQrDialog("")
    //         setOpenShowQrCodeDialog(true)
    //     }
    // }

    //TODO: Currently this deletes all known networks, will be updated to delete specific networks
    const getQrCodeValue = () => {
        return "CELLCONNECT"
    }

    const generateQrCode = () => {
        return <QRCode value={getQrCodeValue()}
                       style={{height: "auto", maxWidth: "100%", width: "100%", padding: "2rem"}}/>
    }

    return (
        <>
            {/*<Dialog open={openQrDialog == "wifiConnect"}>*/}
            {/*    <DialogTitle>Create Network QR Code</DialogTitle>*/}
            {/*    <DialogContent*/}
            {/*        id="qr-dialog-content"*/}
            {/*        style={{display: "flex", flexDirection: "column"}}*/}
            {/*    >*/}
            {/*        <DialogContentText>*/}
            {/*            Please specify your network settings:*/}
            {/*        </DialogContentText>*/}
            {/*        <TextField*/}
            {/*            error={networkSettings.error}*/}
            {/*            fullWidth*/}
            {/*            margin="dense"*/}
            {/*            id="name-textfield"*/}
            {/*            label="SSID"*/}
            {/*            variant="standard"*/}
            {/*            value={networkSettings.ssid}*/}
            {/*            style={{marginTop: "2rem", marginBottom: "1rem"}}*/}
            {/*            onChange={(e) => {*/}
            {/*                setNetworkSettings(newNetworkSettings => {*/}
            {/*                    return {...newNetworkSettings, ssid: e.target.value}*/}
            {/*                })*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            error={networkSettings.error}*/}
            {/*            fullWidth*/}
            {/*            margin="dense"*/}
            {/*            id="name-textfield"*/}
            {/*            label="Password"*/}
            {/*            variant="standard"*/}
            {/*            value={networkSettings.password}*/}
            {/*            style={{marginTop: "2rem", marginBottom: "1rem"}}*/}
            {/*            onChange={(e) => {*/}
            {/*                setNetworkSettings(newNetworkSettings => {*/}
            {/*                    return {...newNetworkSettings, password: e.target.value}*/}
            {/*                })*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions style={{padding: "1rem"}}>*/}
            {/*        <Button onClick={() => {*/}
            {/*            setOpenQrDialog("")*/}
            {/*            setNetworkSettings({ssid: "", password: "", error: false})*/}
            {/*        }}>Cancel</Button>*/}
            {/*        <Button onClick={() => {*/}
            {/*            handleSubmit()*/}
            {/*        }} variant="contained" style={{*/}
            {/*            backgroundColor: "rgb(25, 118, 210)",*/}
            {/*            borderRadius: ".25rem",*/}
            {/*            padding: ".375rem 1rem",*/}
            {/*            color: "white"*/}
            {/*        }}>Generate QR Code</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
            <Dialog open={openQrDialog === "cellConnect"}>
                {generateQrCode()}
                <DialogActions style={{padding: "1rem"}}>
                    <Button onClick={() => {
                        setOpenQrDialog("")
                    }} variant="contained" style={{
                        backgroundColor: "rgb(25, 118, 210)",
                        borderRadius: ".25rem",
                        padding: ".375rem 1rem",
                        color: "white"
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CellConnectDialog;