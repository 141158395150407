import React, {useEffect, useState} from 'react';
import {ReactComponent as FolderIcon} from "../../assets/images/FolderIcon.svg";
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import SensorsIcon from '@mui/icons-material/Sensors';
import {useHistory} from "react-router-dom";
import CollectionsIcon from '@mui/icons-material/Collections';
import SummarizeIcon from '@mui/icons-material/Summarize';

import axios from "axios";
import {Auth} from 'aws-amplify';
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";
import {errorCodes, warningCodes} from "../../constants";

const NavMenu = () => {

    const history = useHistory()
    const [pathname, setPathname] = useState(window.location.pathname)
    const [isAdmin, setIsAdmin] = useState(false)
    const [customer, setCustomer] = useState({
        customerName: "",
        contactEmail: ""
    })
    const [logStatus, setLogStatus] = useState("")
    const [sensorEventStatus, setSensorEventStatus] = useState("")
    
    useEffect(() => {
        let robotSensorIds = []
        
        async function checkAuthStatus() {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.get('/api/isuseradmin/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    if (response.data === true) {
                        setIsAdmin(true)
                    } else {
                        setIsAdmin(false)
                    }
                }).catch(() => {
                    setIsAdmin(false)
                })

            await axios.get('/api/customermethods/1', {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    setCustomer(response.data)
                })
            
            let robots = []
            
            await axios.get('/api/robotmethods/' + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    robots = response.data
                })

            for (const robot of robots) {
                await axios.get('/api/robotsensorsmethods/' + robot.robotId + "/" + email, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then((response) => {
                        robotSensorIds = robotSensorIds.concat(response.data.map((robotSensor) => robotSensor.robotSensorsId))
                    }).catch((error) => {

                    })
            }
        }

        checkAuthStatus()

        const logInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/logs') {

                const email = await Auth.currentAuthenticatedUser().then((user) => {
                    return user.attributes.email;
                })
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                await axios.post('/api/logmethods', {
                        Acknowledged: false,
                        Username: email,
                        Codes: errorCodes.concat(warningCodes)
                    },
                    {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(async response => {
                        const logs = response.data

                        if (logs.find(log => errorCodes.includes(log.notificationCode))) {
                            setLogStatus("Error")
                        } else if (logs.find(log => warningCodes.includes(log.notificationCode))) {
                            setLogStatus("Warning")
                        } else {
                            setLogStatus("")
                        }
                    }).catch(error => {

                    })
            } else {
                setLogStatus("")
            }

            return () => {
                clearIntervalAsync(logInterval).then(r => {
                })
            }
        }, 5000)

        const sensorEventInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/sensorData') {
                const email = await Auth.currentAuthenticatedUser().then((user) => {
                    return user.attributes.email;
                })
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()

                await axios.post('/api/sensoreventmethods', {
                        RobotSensorsIds: robotSensorIds,
                        Username: email,
                    },
                    {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                    .then(async response => {
                        if(response.data.length > 0) {
                            setSensorEventStatus("EventPresent")
                        }
                    }).catch(error => {

                    })
            } else {
                setSensorEventStatus("")
            }
            return () => {
                clearIntervalAsync(sensorEventInterval).then(r => {
                })
            }
        }, 5000)
    }, [])

    return (
        <div className="NavMenu">
            <div className="content">
                <div className="ultaview">
                    Ultaview
                </div>
                <div className="nav-link-container">
                    <div className={`link ${pathname === "/" ? "active-link" : ""}`}
                         onClick={() => {
                             setPathname("/")
                             history.push("/")
                         }}
                    >
                        <HomeIcon className="icon"/>
                        Home
                    </div>
                    <div className={`link ${pathname === "/live" ? "active-link" : ""}`}
                         onClick={() => {
                             setPathname("/live")
                             history.push("/live")
                         }}>
                        <PersonalVideoIcon className="icon"/>
                        Live
                    </div>
                    <div
                        className={`link ${pathname === "/sensorData" ? "active-link" : ""}  ${sensorEventStatus ? "error" : ""}`}
                        onClick={() => {
                            setPathname("/sensorData")
                            history.push("/sensorData")
                        }}>
                        <SensorsIcon className="icon"/>
                        Sensor Data
                    </div>
                    <div className={`link ${pathname === "/archive" ? "active-link" : ""}`}
                         onClick={() => {
                             setPathname("/archive")
                             history.push("/archive")
                         }}>
                        <VideoLibraryIcon className="icon"/>
                        Video Archive
                    </div>
                    <div className={`link ${pathname === "/imageGallery" ? "active-link" : ""}`}
                         onClick={() => {
                             setPathname("/imageGallery")
                             history.push("/imageGallery")
                         }}>
                        <CollectionsIcon className="icon"/>
                        Image Gallery
                    </div>
                    <div className={`link ${pathname === "/report" ? "active-link" : ""}`}
                         onClick={() => {
                             setPathname("/report")
                             history.push("/report")
                         }}>
                        <SummarizeIcon className="icon"/>
                        Reports
                    </div>
                    <div
                        className={`link ${pathname === "/logs" ? "active-link" : ""} ${logStatus ? (logStatus === "Error" ? "error" : "warning") : ""}`}
                        onClick={() => {
                            setPathname("/logs")
                            history.push("/logs")
                        }}>
                        <ArticleIcon className="icon"/>
                        Logs
                    </div>
                    {isAdmin &&
                        <div className={`link ${pathname === "/admin" ? "active-link" : ""}`}
                             onClick={() => {
                                 setPathname("/admin")
                                 history.push("/admin")
                             }}>
                            <FolderIcon className="icon"/>
                            Admin
                        </div>}
                    {/*<div className={`link ${pathname === "/settings" ? "active-link" : ""}`}*/}
                    {/*     onClick={() => {*/}
                    {/*         setPathname("/settings")*/}
                    {/*         history.push("/settings")*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <SettingsIcon className="icon"/>*/}
                    {/*    Settings*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="user">
                {customer.customerName}
            </div>
        </div>
    )
}

export default NavMenu;