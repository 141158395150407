import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as BackArrowIcon} from "../../assets/images/BackArrowIcon.svg";
import {ReactComponent as CommonObjectsLogo} from "../../assets/images/CommonObjectsLogo.svg";
import MaterialTable from "material-table";
import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Switch,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import DateAdapter from '@mui/lab/AdapterMoment';

import axios from "axios";
import {estimateBatteryPercentage, NotificationCodeConstants, statusColor} from "../../constants";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/lab";
import moment from "moment";
import NextMission from "./NextMission";
import { Auth } from 'aws-amplify';

const RobotDetails = () => {
    const history = useHistory();
    const location = useLocation();
    const robotInfo = location.state;
    const screenWidth = window.innerWidth

    const [logs, setLogs] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [missions, setMissions] = useState([]);
    const [objectsOfInterest, setObjectsOfInterest] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [openDialog, setOpenDialog] = useState(false);
    const [tableFilter, setTableFilter] = useState("alert");
    const [newScheduleInfo, setNewScheduleInfo] = useState({
        robotId: robotInfo.robotId,
        missionId: "",
        timeToRun: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        repeatWeekly: true,
        archiveVideo: true,
        dayOfTheWeekToRun: ["0", "0", "0", "0", "0", "0", "0"]
    });
    const [scheduleFormError, setScheduleFormError] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState({open: false, message: ""});
    const [openRemoveScheduleDialog, setOpenRemoveScheduleDialog] = useState(false);
    const [scheduleIdsToBeRemoved, setScheduleIdsToBeRemoved] = useState([]);
    const [openUpdateScheduleDialog, setOpenUpdateScheduleDialog] = useState(false);
    const [openUpdateScheduleForm, setOpenUpdateScheduleForm] = useState(false);
    const [rowToBeUpdated, setRowToBeUpdated] = useState({});
    const [originalRowToBeUpdated, setOriginalRowToBeUpdated] = useState({});
    
    const logTableHeight = (window.innerHeight - 380)
    const scheduleTableHeight = (window.innerHeight - 268)
    
    const [isLoadingText, setIsLoadingText] = useState("");

    const formatLogDataForTable = () => {
        return logs.map((log) => ({
            ...NotificationCodeConstants[log.notificationCode],
            logTimestamp: moment(log.timeOfEvent).format("MM-DD-YYYY HH:mm"),
            logDescription: log.ultaviewLogEvent
        }))
    }

    const generateWeekOrder = () => {
        const weekOrder = []
        const currentDay = new Date().getDay();

        for (let i = 0; i < 7; i++) {
            weekOrder.push(currentDay + i < 7 ? currentDay + i : currentDay + i - 7)
        }

        return weekOrder
    }

    const generateDaysToRun = (daysToRunString) => {
        return [
            {numericDayOfWeek: 0, dayOfWeek: "Sunday", run: daysToRunString[0] === "1"},
            {numericDayOfWeek: 1, dayOfWeek: "Monday", run: daysToRunString[1] === "1"},
            {numericDayOfWeek: 2, dayOfWeek: "Tuesday", run: daysToRunString[2] === "1"},
            {numericDayOfWeek: 3, dayOfWeek: "Wednesday", run: daysToRunString[3] === "1"},
            {numericDayOfWeek: 4, dayOfWeek: "Thursday", run: daysToRunString[4] === "1"},
            {numericDayOfWeek: 5, dayOfWeek: "Friday", run: daysToRunString[5] === "1"},
            {numericDayOfWeek: 6, dayOfWeek: "Saturday", run: daysToRunString[6] === "1"}
        ]
    }

    const generateScheduledMissionDataForTable = () => {
        const missionsToBeRanThisWeek = [].concat(...schedule.map((schedule) => {
            const correspondingMission = missions.find(mission => mission.missionId === schedule.missionId)
            const objectOfInterest = objectsOfInterest.find((object) => (object.detectObjectId === correspondingMission.detectObjectId) && object.active)

            return generateDaysToRun(schedule.dayOfTheWeekToRun.trim().split(','))
                .filter(dayOfWeek => dayOfWeek.run)
                .map(validDays => {
                    return {
                        missionName: correspondingMission.missionName.trim(),
                        timeToRun: schedule.timeToRun,
                        dayOfWeek: validDays.dayOfWeek,
                        numericDayOfWeek: validDays.numericDayOfWeek,
                        objectOfInterest: objectOfInterest ? objectOfInterest.objectName : "None",
                        // archiveVideo: schedule.archiveVideo ? "True" : "False"
                    }
                })
        }))

        return [].concat(...generateWeekOrder().map(numericDayOfWeek => {
            return missionsToBeRanThisWeek
                .filter(mission => numericDayOfWeek === mission.numericDayOfWeek)
                .sort((a, b) =>
                    a.timeToRun.localeCompare(b.timeToRun)
                )
        }))
    }

    const handleTabChange = (event, newValue) => {
        setTableFilter(newValue);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handleScheduleSubmit = async () => {
        if (!newScheduleInfo.missionId || !newScheduleInfo.timeToRun || newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined || !(moment(newScheduleInfo.startDate) <= moment(newScheduleInfo.endDate))) {
            setScheduleFormError(true)
        } else {
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            // let utcTimeToRun = moment("2022-01-01" + " " + newScheduleInfo.timeToRun, "YYYY-MM-DDTHH:mm").utc().format()
            // console.log("Original")
            // console.log(newScheduleInfo.timeToRun)
            // console.log("UTC")
            // console.log(utcTimeToRun)
            // console.log("LOCAL")
            // console.log(moment("2022-01-01" + " " + newScheduleInfo.timeToRun))
            
            
            await axios.post('/api/schedulemethods', {
                robotId: newScheduleInfo.robotId,
                missionId: newScheduleInfo.missionId,
                timeToRun: moment(newScheduleInfo.timeToRun, "H:mm").utc().format("H:mm"),
                dayOfTheWeekToRun: newScheduleInfo.dayOfTheWeekToRun.join(","),
                repeatWeekly: newScheduleInfo.repeatWeekly,
                archiveVideo: newScheduleInfo.archiveVideo ? 1 : 0,
                startDateToRun: newScheduleInfo.startDate,
                endDateToRun: newScheduleInfo.endDate,
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    if (response.data) {
                        setOpenDialog(false)
                        setOpenConfirmationDialog({open: true, message: "Scheduled Mission Created"})
                    } else {
                        setOpenDialog(false)
                        setOpenConfirmationDialog({
                            open: true,
                            message: "Scheduled Mission could not be created at this time. Please try again"
                        })
                    }
                }).catch(() => {
                    setOpenDialog(false)
                    setOpenConfirmationDialog({
                        open: true,
                        message: "Scheduled Mission could not be created at this time. Please try again"
                    })
                });
        }
    }

    const generateAbbreviatedWeekOrder = (schedule) => {
        const daysOfWeek = schedule.dayOfTheWeekToRun.trim().split(",");
        const abbreviatedWeekOrder = ["Sun", "Mon", "Tu", "Wed", "Th", "Fri", "Sat"]
        let activeDays = []

        for (let i = 0; i < 7; i++) {
            if (daysOfWeek[i] === "1") {
                activeDays.push(abbreviatedWeekOrder[i])
            }
        }

        return activeDays.join(", ")
    }

    const generateScheduledMissionDataForDataGrid = () => {
        const scheduleArray = schedule.map(schedule => {
            const correspondingMission = missions.find(mission => mission.missionId === schedule.missionId);
            const correspondingObject = objectsOfInterest.find((object) => (object.detectObjectId === correspondingMission.detectObjectId) && object.active)

            return {
                id: schedule.scheduleId,
                missionName: correspondingMission.missionName.trim(),
                timeToRun: schedule.timeToRun,
                daysOfWeek: generateAbbreviatedWeekOrder(schedule),
                objectOfInterest: correspondingObject !== undefined ? correspondingObject.objectName : "None",
                // archiveVideo: schedule.archiveVideo ? "True" : "False"
            }
        })

        return scheduleArray
    }

    const removeSchedules = async (scheduleIds) => {
        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.delete("/api/schedulemethods", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            data: { ScheduleIds: scheduleIds, Username: email }
        })
            .catch((e) => {
                throw e;
            })
    }

    const checkIfSchedulesAreEqual = (a, b) => {
        if (a.scheduleId === b.scheduleId && a.robotId === b.robotId && a.missionId === b.missionId && a.timeToRun === b.timeToRun && moment(a.startDateToRun).format("YYYY-MM-DD") === moment(b.startDateToRun).format("YYYY-MM-DD") && a.repeatWeekly === b.repeatWeekly && a.archiveVideo === b.archiveVideo) {
            for (let i = 0; i < 7; i++) {
                if (a.dayOfTheWeekToRun[i] !== b.dayOfTheWeekToRun[i]) {
                    return false;
                }
            }

            return true;
        }
        return false;
    }

    const handleScheduleUpdateSubmit = async () => {
        if (checkIfSchedulesAreEqual(rowToBeUpdated, originalRowToBeUpdated) || !(moment(newScheduleInfo.startDateToRun) <= moment(newScheduleInfo.endDateToRun))) {
            setScheduleFormError(true)
        } else {
            setScheduleFormError(false)
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            await axios.put('/api/schedulemethods', {
                robotId: rowToBeUpdated.robotId,
                missionId: rowToBeUpdated.missionId,
                timeToRun: moment(rowToBeUpdated.timeToRun, "H:mm").utc().format("H:mm"),
                dayOfTheWeekToRun: rowToBeUpdated.dayOfTheWeekToRun.join(","),
                repeatWeekly: rowToBeUpdated.repeatWeekly,
                archiveVideo: rowToBeUpdated.archiveVideo ? 1 : 0,
                startDateToRun: rowToBeUpdated.startDateToRun,
                endDateToRun: rowToBeUpdated.endDateToRun,
                scheduleId: rowToBeUpdated.scheduleId,
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    if (response.data) {
                        setOpenUpdateScheduleForm(false)
                        setOpenConfirmationDialog({open: true, message: "Scheduled Mission Updated"})
                    } else {
                        setOpenUpdateScheduleForm(false)
                        setOpenConfirmationDialog({
                            open: true,
                            message: "Scheduled Mission could not be updated at this time. Please try again"
                        })
                    }
                }).catch(() => {
                    setOpenUpdateScheduleForm(false)
                    setOpenConfirmationDialog({
                        open: true,
                        message: "Scheduled Mission could not be updated at this time. Please try again"
                    })
                });
        }
    }

    useEffect(() => {
        async function fetchLogsScheduleAndMissions() {
            setIsLoading(true);
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            setIsLoadingText("Fetching Logs")
            await axios.get(`/api/logmethods/${robotInfo.robotId}/500/${email}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    setLogs(response.data)
                })

            setIsLoadingText("Fetching Schedules")
            await axios.get(`/api/schedulemethods/${robotInfo.robotId}/${email}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(response => {
                    const schedules = response.data
                    
                    setSchedule(schedules.map(schedule => {
                        return {
                            ...schedule, timeToRun: moment.utc(schedule.timeToRun, "H:mm").local().format("H:mm")
                        }
                    }))
                })

            setIsLoadingText("Fetching Missions")
            await axios.get(`/api/missionmethods/${email}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    setMissions(response.data)

                    for (const mission of response.data) {
                        if (mission.detectObjectId !== null) {
                            await axios.get(`/api/detectobjectmethods/${mission.detectObjectId}/${email}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                                .then(response => {
                                    setObjectsOfInterest(objectsOfInterest => [...objectsOfInterest, response.data])
                                })
                        }
                    }
                })
            setIsLoading(false);
        }

        fetchLogsScheduleAndMissions()
    }, [])

    return (
        <div className="RobotDetails">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <div>{isLoadingText}&ensp;</div>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Dialog open={openUpdateScheduleForm}>
                <DialogTitle>Update Scheduled Mission</DialogTitle>
                <DialogContent
                    id="dialog-content"
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <DialogContentText style={{color: scheduleFormError ? "red" : ""}}>
                        To update a mission, please alter any of the following:
                    </DialogContentText>
                    <TextField
                        error={scheduleFormError && !rowToBeUpdated.missionId}
                        select
                        margin="dense"
                        id="name-textfield"
                        label="Mission Name"
                        variant="standard"
                        value={rowToBeUpdated.missionId !== undefined ? missions.find(mission => mission.missionId === rowToBeUpdated.missionId).missionName : ""}
                        style={{width: "40%", marginBottom: "2rem"}}
                        onChange={(e) => {
                            setRowToBeUpdated(scheduleInfo => ({
                                ...scheduleInfo,
                                missionId: missions.find(mission => mission.missionName === e.target.value).missionId
                            }))
                        }}
                    >
                        {missions.map(mission =>
                            <MenuItem
                                value={mission.missionName}
                                style={{width: "100%", justifyContent: "left", paddingLeft: "0.50rem"}}
                            >
                                {mission.missionName}
                            </MenuItem>
                        )}
                    </TextField>
                    <div className="form-container">
                        <div className="datetime-container">
                            <LocalizationProvider dateAdapter={DateAdapter}
                                                  style={{height: "4rem", marginBottom: "2rem"}}>
                                <DatePicker
                                    style={{marginBottom: "10rem"}}
                                    disablePast
                                    validationError={scheduleFormError && !newScheduleInfo.startDateToRun}
                                    label="Start Date"
                                    value={rowToBeUpdated.startDateToRun}
                                    onChange={(newStartDate) => {
                                        setRowToBeUpdated(scheduleInfo => ({
                                            ...scheduleInfo, startDateToRun: newStartDate.format("YYYY-MM-DD")
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    disablePast
                                    minDate={moment(rowToBeUpdated.startDateToRun)}
                                    validationError={scheduleFormError && !rowToBeUpdated.endDateToRun}
                                    label="End Date"
                                    value={rowToBeUpdated.endDateToRun}
                                    onChange={(newEndDate) => {
                                        setRowToBeUpdated(scheduleInfo => ({
                                            ...scheduleInfo, endDateToRun: newEndDate.format("YYYY-MM-DD")
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    validationError={scheduleFormError && !rowToBeUpdated.timeToRun}
                                    label="Time to Run"
                                    ampm={false}
                                    value={moment("2022-2-22" + " " + rowToBeUpdated.timeToRun)}
                                    onChange={(newTime) => {
                                        setRowToBeUpdated(scheduleInfo => ({
                                            ...scheduleInfo, timeToRun: newTime.format("H:mm")
                                        }))
                                    }}
                                    onError={(e) => {
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        {rowToBeUpdated.dayOfTheWeekToRun !== undefined && <FormGroup
                            style={{
                                display: "grid",
                                paddingLeft: "2rem",
                                height: "6rem",
                                rowGap: "0.25rem"
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[0] === "1" : false}
                                        style={{color: rowToBeUpdated.dayOfTheWeekToRun !== undefined && scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[0] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Sunday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[1] === "1" : false}
                                        style={{color: scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[1] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Monday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[2] === "1" : false}
                                        style={{color: scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[2] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Tuesday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[3] === "1" : false}
                                        style={{color: scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[3] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Wednesday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[4] === "1" : false}
                                        style={{color: scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[4] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Thursday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[5] === "1" : false}
                                        style={{color: scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[5] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Friday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rowToBeUpdated.dayOfTheWeekToRun !== undefined ? rowToBeUpdated.dayOfTheWeekToRun[6] === "1" : false}
                                        style={{color: scheduleFormError && rowToBeUpdated.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            rowToBeUpdated.dayOfTheWeekToRun[6] = e.target.checked ? "1" : "0"
                                            setRowToBeUpdated(rowToBeUpdated => ({
                                                ...rowToBeUpdated
                                            }))
                                        }}
                                    />
                                }
                                label="Saturday"
                            />
                        </FormGroup>}
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked={rowToBeUpdated.repeatWeekly}
                                    className="mui-switch-container"
                                    onChange={(e) => {
                                        setRowToBeUpdated(rowToBeUpdated => ({
                                            ...rowToBeUpdated,
                                            repeatWeekly: e.target.checked
                                        }))
                                    }}
                                />
                            }
                            label="Repeat Weekly"/>
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked={rowToBeUpdated.archiveVideo}
                                    className="mui-switch-container"
                                    onChange={(e) => {
                                        setRowToBeUpdated(rowToBeUpdated => ({
                                            ...rowToBeUpdated,
                                            archiveVideo: e.target.checked
                                        }))
                                    }}
                                />
                            }
                            label="Archive Video"/>
                    </FormGroup>
                </DialogContent>
                <DialogActions style={{padding: "1rem"}}>
                    <Button onClick={() => {
                        setOpenUpdateScheduleForm(false)
                        setRowToBeUpdated({})
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        handleScheduleUpdateSubmit()
                    }} variant="contained" style={{
                        backgroundColor: "rgb(25, 118, 210)",
                        borderRadius: ".25rem",
                        padding: ".375rem 1rem",
                        color: "white"
                    }}>Update Mission</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openUpdateScheduleDialog} id="update-schedule-dialog">
                <DialogTitle>Update Scheduled Missions</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select Scheduled Mission to update:
                    </DialogContentText>
                </DialogContent>
                <div style={{height: 371, width: screenWidth < 550 ? "100%" : 800}}>
                    <DataGrid
                        // checkboxSelection
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        columns={[
                            {headerName: "Mission Name", field: "missionName", width: 187},
                            {headerName: "Execution Time (UTC)", field: "timeToRun", width: 187},
                            {headerName: "Execution Days", field: "daysOfWeek", width: 187},
                            {headerName: "Object of Interest", field: "objectOfInterest", width: 187},
                            // {headerName: "Archive Video", field: "archiveVideo", width: 160},
                        ]}
                        rows={isLoading ? [] : generateScheduledMissionDataForDataGrid()}
                        onRowClick={(selectedRow) => {
                            const scheduleRow = schedule.find(schedule => schedule.scheduleId === selectedRow.row.id)
                            setRowToBeUpdated({
                                ...scheduleRow,
                                dayOfTheWeekToRun: scheduleRow.dayOfTheWeekToRun.trim().split(',')
                            })
                        }}
                    />
                </div>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenUpdateScheduleDialog(false)
                    }}>
                        Close
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "rgb(25, 118, 210)",
                            borderRadius: ".25rem",
                            padding: ".375rem 1rem",
                            color: "white"
                        }}
                        onClick={() => {
                            if (rowToBeUpdated !== {}) {
                                setOpenUpdateScheduleForm(true)
                                setOpenUpdateScheduleDialog(false)
                                setOriginalRowToBeUpdated(JSON.parse(JSON.stringify(rowToBeUpdated)))
                            }
                        }}>
                        Configure
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openRemoveScheduleDialog} id="remove-schedule-dialog">
                <DialogTitle>Remove Scheduled Missions</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color: scheduleFormError ? "red" : ""}}>
                        Select Scheduled Missions to delete:
                    </DialogContentText>
                </DialogContent>
                <div style={{height: 371, width: screenWidth < 550 ? "100%" : 800}}>
                    <DataGrid
                        checkboxSelection
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        columns={[
                            {headerName: "Mission Name", field: "missionName", width: 187},
                            {headerName: "Execution Time (UTC)", field: "timeToRun", width: 187},
                            {headerName: "Execution Days", field: "daysOfWeek", width: 187},
                            {headerName: "Object of Interest", field: "objectOfInterest", width: 187},
                            // {headerName: "Archive Video", field: "archiveVideo", width: 110},
                        ]}
                        rows={isLoading ? [] : generateScheduledMissionDataForDataGrid()}
                        onSelectionModelChange={schedulesToBeRemoved => {
                            setScheduleIdsToBeRemoved(schedulesToBeRemoved)
                        }}
                    />
                </div>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenRemoveScheduleDialog(false);
                        setScheduleIdsToBeRemoved([]);
                        setScheduleFormError(false);
                    }}>
                        Close
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "rgb(211, 47, 47)",
                            borderRadius: ".25rem",
                            padding: ".375rem 1rem",
                            color: "white"
                        }}
                        onClick={() => {
                            if (scheduleIdsToBeRemoved.length === 0) {
                                setScheduleFormError(true);
                            } else if (window.confirm("Are you sure you want to delete these Scheduled Missions? This action is permanent.")) {
                                removeSchedules(scheduleIdsToBeRemoved).then(() => {
                                    setOpenRemoveScheduleDialog(false);
                                    setScheduleIdsToBeRemoved([]);
                                    setScheduleFormError(false);
                                    history.go()
                                })
                            }
                        }}>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmationDialog.open}>
                <Dialog>Schedule Mission</Dialog>
                <DialogContent>
                    <DialogContentText>
                        {openConfirmationDialog.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenConfirmationDialog({open: false, message: ""})
                        history.go()
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialog} onClose={handleDialogClose} id="create-schedule-dialog">
                <DialogTitle>Schedule Mission</DialogTitle>
                <DialogContent
                    id="dialog-content"
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <DialogContentText>
                        To create a mission, please specify the following:
                    </DialogContentText>
                    <TextField
                        error={scheduleFormError && !newScheduleInfo.missionId}
                        select
                        margin="dense"
                        id="name-textfield"
                        label="Mission Name"
                        variant="standard"
                        style={{width: "40%", marginBottom: "2rem"}}
                        onChange={(e) => {
                            setNewScheduleInfo(scheduleInfo => ({
                                ...scheduleInfo,
                                missionId: missions.find(mission => mission.missionName === e.target.value).missionId
                            }))
                        }}
                    >
                        {missions.map(mission =>
                            <MenuItem
                                value={mission.missionName}
                                style={{width: "100%", justifyContent: "left", paddingLeft: "0.50rem"}}
                            >
                                {mission.missionName}
                            </MenuItem>
                        )}
                    </TextField>
                    <div className="form-container">
                        <div className="datetime-container">
                            <LocalizationProvider dateAdapter={DateAdapter}
                                                  style={{height: "4rem", marginBottom: "2rem"}}>
                                <DatePicker
                                    style={{marginBottom: "10rem"}}
                                    disablePast
                                    validationError={scheduleFormError && !newScheduleInfo.startDate}
                                    label="Start Date"
                                    value={newScheduleInfo.startDate}
                                    onChange={(newStartDate) => {
                                        setNewScheduleInfo(scheduleInfo => ({
                                            ...scheduleInfo, startDate: newStartDate.format("YYYY-MM-DD")
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    disablePast
                                    minDate={moment(newScheduleInfo.startDate)}
                                    validationError={scheduleFormError && !newScheduleInfo.endDate}
                                    label="End Date"
                                    value={newScheduleInfo.endDate}
                                    onChange={(newEndDate) => {
                                        setNewScheduleInfo(scheduleInfo => ({
                                            ...scheduleInfo, endDate: newEndDate.format("YYYY-MM-DD")
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    validationError={scheduleFormError && !newScheduleInfo.timeToRun}
                                    label="Time to Run"
                                    ampm={false}
                                    value={moment("2022-2-22" + " " + newScheduleInfo.timeToRun)}
                                    onChange={(newTime) => {
                                        setNewScheduleInfo(scheduleInfo => ({
                                            ...scheduleInfo, timeToRun: newTime.format("H:mm")
                                        }))
                                    }}
                                    onError={(e) => {
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <FormGroup
                            style={{
                                display: "grid",
                                paddingLeft: "2rem",
                                height: "6rem",
                                rowGap: "0.25rem"
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[0] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Sunday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[1] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Monday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[2] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Tuesday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[3] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Wednesday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[4] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Thursday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[5] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Friday"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{color: scheduleFormError && newScheduleInfo.dayOfTheWeekToRun.find(day => day === "1") === undefined ? "red" : ""}}
                                        onChange={(e) => {
                                            newScheduleInfo.dayOfTheWeekToRun[6] = e.target.checked ? "1" : "0"
                                            setNewScheduleInfo(newScheduleInfo => ({
                                                ...newScheduleInfo
                                            }))
                                        }}
                                    />
                                }
                                label="Saturday"
                            />
                        </FormGroup>
                    </div>
                    {/*<FormGroup>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={*/}
                    {/*            <Switch*/}
                    {/*                className="mui-switch-container"*/}
                    {/*                defaultChecked*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    setNewScheduleInfo(newScheduleInfo => ({*/}
                    {/*                        ...newScheduleInfo,*/}
                    {/*                        repeatWeekly: e.target.checked*/}
                    {/*                    }))*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        label="Repeat Weekly"/>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={*/}
                    {/*            <Switch*/}
                    {/*                className="mui-switch-container"*/}
                    {/*                defaultChecked*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    setNewScheduleInfo(newScheduleInfo => ({*/}
                    {/*                        ...newScheduleInfo,*/}
                    {/*                        archiveVideo: e.target.checked*/}
                    {/*                    }))*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        label="Archive Video"/>*/}
                    {/*</FormGroup>*/}
                </DialogContent>
                <DialogActions style={{padding: "1rem"}}>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleScheduleSubmit} variant="contained" style={{
                        backgroundColor: "rgb(25, 118, 210)",
                        borderRadius: ".25rem",
                        padding: ".375rem 1rem",
                        color: "white"
                    }}>Create Mission</Button>
                </DialogActions>
            </Dialog>
            <div className="header">
                <div className="back-arrow-icon" onClick={() => history.goBack()}>
                    <BackArrowIcon/>
                </div>
                <div className="robot-name">
                    {robotInfo.robotName}
                </div>
                <div className="company-logo">
                    <CommonObjectsLogo onClick={() => history.push("/")}/>
                </div>
            </div>
            <div className="content-container">
                <div className="robot-info">
                    <div className="status-container">
                        <div className="title" style={statusColor(robotInfo)}>
                            <span>Status</span>
                        </div>
                        {!isLoading && <div className="status-info">
                            <div>Battery Percentage:
                                (~{estimateBatteryPercentage(robotInfo.batteryPct)} min) {robotInfo.batteryPct}%
                            </div>
                            <div>Current Action: {robotInfo.currentStatus}</div>
                            {/*<div>Uptime: 72hrs</div>*/}
                            {/*<div>Current Mileage: 10,000m</div>*/}
                            <NextMission generateMissionList={generateScheduledMissionDataForTable}/>
                            {/*//TODO: Fix this*/}
                        </div>}
                    </div>
                    <div className="alerts-container">
                        <MaterialTable
                            options={{
                                headerStyle: {
                                    color: "#2A2E43",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    whiteSpace: "nowrap",
                                },
                                maxBodyHeight: logTableHeight,
                                pageSize: 20,
                                rowStyle: rowData => ({
                                    backgroundColor: rowData.logCriticality === "Error" ? "#fd7b83" : "#bbf3c0",
                                    fontSize: 10,
                                })
                            }}
                            columns={[
                                {title: "Log Title", field: "logTitle"},
                                {title: "Timestamp", field: "logTimestamp", defaultSort: "desc"},
                                {title: "Criticality", field: "logCriticality"},
                                {title: "Description", field: "logDescription", width: "10%"},
                            ]}
                            data={formatLogDataForTable().filter(log => log.logType === tableFilter)}
                            title={
                                <Tabs value={tableFilter} onChange={handleTabChange}
                                      variant="fullWidth"
                                      aria-label="basic tabs example"
                                >
                                    <Tab label="Logs" value="alert"
                                         style={{
                                             fontFamily: "Roboto",
                                             fontWeight: 500,
                                             textTransform: "none",
                                             width: "100%"
                                         }}/>
                                    <Tab label="Detections" value="detection"
                                         style={{
                                             fontFamily: "Roboto",
                                             fontWeight: 500,
                                             textTransform: "none",
                                             width: "100%"
                                         }}/>
                                    <Tab label="Mission History" value="mission"
                                         style={{
                                             fontFamily: "Roboto",
                                             fontWeight: 500,
                                             textTransform: "none",
                                             width: "100%"
                                         }}/>
                                </Tabs>
                            }
                        />
                    </div>
                </div>
                <div className="robot-schedule">
                    <div className="schedule-container">
                        <MaterialTable
                            options={{
                                maxBodyHeight: window.innerWidth <= 640 ? logTableHeight : scheduleTableHeight,
                                pageSize: 20,
                                rowStyle: rowData => ({
                                    fontSize: 12,
                                    backgroundColor: rowData.tableData.id % 2 === 0 ? "rgba(0,0,0,.05)" : ""
                                }),
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    color: "#2A2E43",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    whiteSpace: "nowrap"
                                },
                            }}
                            columns={[
                                {title: "Mission Name", field: "missionName"},
                                {title: "Execution Time (UTC)", field: "timeToRun"},
                                {title: "Day of Week", field: "dayOfWeek"},
                                {title: "Object of Interest", field: "objectOfInterest",},
                                // {title: "Archive Video", field: "archiveVideo",},
                            ]}
                            data={isLoading ? [] : generateScheduledMissionDataForTable()}
                            title={
                                <div style={{
                                    color: "#2A2E43",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "1.25rem"
                                }}>
                                    Schedule
                                </div>
                            }
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Add Scheduled Mission',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        setScheduleFormError(false)
                                        setOpenDialog(true)
                                        setNewScheduleInfo(newScheduleInfo => ({
                                            ...newScheduleInfo,
                                            missionId: "",
                                            startDate: new Date(),
                                            endDate: new Date(),
                                            timeToRun: "00:00",
                                            repeatWeekly: true,
                                            archiveVideo: true,
                                            dayOfTheWeekToRun: ["0", "0", "0", "0", "0", "0", "0"]
                                        }))
                                    }
                                },
                                {
                                    icon: 'remove',
                                    tooltip: 'Remove Scheduled Mission',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        setOpenRemoveScheduleDialog(true)
                                    }
                                },
                                {
                                    icon: 'update',
                                    tooltip: 'Update Scheduled Mission',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        setOpenUpdateScheduleDialog(true)
                                    }
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RobotDetails;