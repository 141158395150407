import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";
import axios from "axios";

import {useHistory} from "react-router-dom";
import { Auth } from 'aws-amplify';

const UpdateFleetDialog = ({
                               openUpdateFleet,
                               setOpenUpdateFleet,
                               generateFleetDataForDataGrid,
                               fleets,
                               isLoading,
                               fleetRobots,
                               robotInfo,
                               setOpenConfirmationDialog
                           }) => {

    const [fleetToBeUpdated, setFleetToBeUpdated] = useState({});
    const [openFleetAssignment, setOpenFleetAssignment] = useState(false);
    const [assignedRobots, setAssignedRobots] = useState([]);
    const [selectedRobots, setSelectedRobots] = useState([]);
    const history = useHistory();

    const generateFleetRobotInfo = () => {
        return robotInfo.map(robot => {
            const fleetRobot = fleetRobots.find(fleetRobot => fleetRobot.robotId === robot.robotId)
            const associatedFleets = fleetRobots.filter(fleetRobot => fleetRobot.robotId === robot.robotId).map(fleetRobot => fleetRobot.fleetId)
            const fleetNameArray = associatedFleets.map(associatedFleet => {
                return fleets.find(fleet => fleet.fleetId === associatedFleet).fleetName.trim()

            })

            return {
                id: robot.robotId,
                robotName: robot.robotName,
                fleetName: fleetNameArray.join(", "),
                fleetId: fleetRobot !== undefined ? fleetRobot.fleetId : "None",
                robotId: robot.robotId
            }
        })
    }

    const handleCreation = async (robotsToBeCreated) => {
        const newFleetRobots = robotsToBeCreated.map(robotId => {
            return {
                robotId: robotId,
                fleetId: fleetToBeUpdated.fleetId,
            }
        })

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.post('/api/fleetrobotmethods', { FleetRobots: newFleetRobots, Username: email }, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
            .then(() => {})
    }

    const handleRemoval = async (robotsToBeRemoved) => {
        const oldFleetRobots = robotsToBeRemoved.map(robotId => {
            return fleetRobots.find(fleetRobot => fleetRobot.robotId === robotId && fleetRobot.fleetId === fleetToBeUpdated.fleetId).frmapId

        })

        const email = await Auth.currentAuthenticatedUser().then((user) => {
            return user.attributes.email;
        })
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        await axios.delete('/api/fleetrobotmethods',
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                data: { FRMapIds: oldFleetRobots, Username: email }
            })
            .then(() => {})
    }

    const handleFleetRobotRequests = async () => {

        const robotsToBeRemoved = assignedRobots.filter(x => !selectedRobots.includes(x))
        const robotsToBeCreated = selectedRobots.filter(x => !assignedRobots.includes(x))

        try {
            if (robotsToBeCreated.length > 0) {
                await handleCreation(robotsToBeCreated)
            }
            if (robotsToBeRemoved.length > 0) {
                await handleRemoval(robotsToBeRemoved)
            }
            setOpenConfirmationDialog({open: true, message: "Fleet assignment successful"})
        } catch (e) {
            setOpenConfirmationDialog({open: true, message: "Fleet assignment was not successful. Please try again"})
        }
    }

    return (

        <>
            <Dialog open={openUpdateFleet} id="update-fleet-dialog">
                <DialogTitle>Update Fleet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select Fleet to update:
                    </DialogContentText>
                </DialogContent>
                <div style={{height: 371, width: 400}}>
                    <DataGrid
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        columns={[
                            {headerName: "Fleet Name", field: "fleetName", width: 125},
                            {headerName: "Number of Associated Robots", field: "numRobots", width: 250},
                        ]}
                        rows={isLoading ? [] : generateFleetDataForDataGrid()}
                        onRowClick={(selectedRow) => {
                            const fleetRow = fleets.find(fleet => fleet.fleetId === selectedRow.row.id)
                            setFleetToBeUpdated(fleetRow)
                        }}
                    />
                </div>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenUpdateFleet(false)
                    }}>
                        Close
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "rgb(25, 118, 210)",
                            borderRadius: ".25rem",
                            padding: ".375rem 1rem",
                            color: "white"
                        }}
                        onClick={() => {
                            if (fleetToBeUpdated !== {}) {
                                const currentFleetRobots = fleetRobots.filter(fleetRobot => fleetRobot.fleetId === fleetToBeUpdated.fleetId).map(fleetRobot => fleetRobot.robotId)
                                setSelectedRobots(currentFleetRobots)
                                setAssignedRobots(currentFleetRobots)
                                setOpenUpdateFleet(false)
                                setOpenFleetAssignment(true)
                            }
                        }}>
                        Configure
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openFleetAssignment} id="fleet-assignment-dialog">
                <DialogTitle>Fleet Assignment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select Robots to associate to this Fleet:
                    </DialogContentText>
                </DialogContent>
                <div style={{height: 371, width: 800}}>
                    <DataGrid
                        checkboxSelection
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        columns={[
                            {headerName: "Robot Name", field: "robotName", width: 125},
                            {headerName: "Current Assigned Fleet", field: "fleetName", width: 620},
                        ]}
                        rows={isLoading ? [] : generateFleetRobotInfo()}
                        selectionModel={selectedRobots}
                        onSelectionModelChange={(currentSelection) => {
                            setSelectedRobots(currentSelection)
                        }}
                    />
                </div>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenFleetAssignment(false)
                    }}>
                        Close
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "rgb(25, 118, 210)",
                            borderRadius: ".25rem",
                            padding: ".375rem 1rem",
                            color: "white"
                        }}
                        onClick={() => {
                            handleFleetRobotRequests()
                            setOpenFleetAssignment(false)
                        }}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateFleetDialog;