import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from "../NavMenu/NavMenu";

export class Layout extends Component {
  static displayName = Layout.name;

  static isAgnet = window.location.pathname === '/agnet';
  
  render () {
    return (
      <div className="Layout">
          {!Layout.isAgnet && <NavMenu/>}
        <Container style={{width: !Layout.isAgnet ? "87.5%" : "100%"}}>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
