import React from "react"
import {delay} from "../../constants";
import {useContext, useEffect, useState} from "react";
import {TileContext} from "./SensorData";
import {clearIntervalAsync, setIntervalAsync} from "set-interval-async/dynamic";
import {Auth} from "aws-amplify";
import axios from "axios";
import moment from "moment";
import {Link, useHistory} from "react-router-dom";

const NumericalTile = ({robot, sensors, myRobotSensors, tileNumber}) => {

    const history = useHistory()

    const [robotSensorsIdsWithEvent, setRobotSensorsIdsWithEvent] = useState([])
    const [recentData, setRecentData] = useState(myRobotSensors.map((robotSensor) => {
        return {
            robotSensorsId: robotSensor.robotSensorsId,
            data: null,
            timestamp: moment.utc()
        }
    }))
    
    const checkForBadData = (data) => {
        if(data.timestamp) {
            if(data.timestamp.isSameOrAfter(moment.utc().subtract(10, "seconds"))) {
                return ""
            } else {
                return "bad-data"
            }
        } else {
            return "bad-data"
        }
    }

    const generateSensorData = () => {
        let idCounter = 0

        return (
            <div className="sensor-data">
                {myRobotSensors.map((mySensor) => {
                    let myData = recentData.find((datum) => datum.robotSensorsId === mySensor.robotSensorsId)
                    
                    return <div className={`data-row ${robotSensorsIdsWithEvent.includes(mySensor.robotSensorsId) ? "active-event" : ""} ${checkForBadData(myData)}`}>
                        <Link key={idCounter++}
                              className={`sensor-name`}
                              to={{pathname: "/sensorGraph?sensor=" + mySensor.robotSensorsId,}}
                              target="_blank">
                            {sensors.find((sensor) => sensor.sensorId == mySensor.sensorId).sensorName}:
                        </Link>
                        <div 
                            key={idCounter++}>{myData.data ? myData.data.toFixed(2) : ""} {sensors.find((sensor) => sensor.sensorId == mySensor.sensorId).units}</div>
                    </div>
                })}
            </div>
        )
    }

    useEffect(() => {
        const asyncInterval = setIntervalAsync(async () => {
            if (window.location.pathname !== '/sensorData') {
                await clearIntervalAsync(asyncInterval)
            }
            const email = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            })
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()

            await axios.post('/api/sensordatummethods/getLatest', {
                    RobotSensorsIds: myRobotSensors.map((robotSensor) => robotSensor.robotSensorsId),
                    Timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
                    Username: email
                },
                {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then(async response => {
                    const newData = response.data
                    let updatedData = recentData.map((recentDatum) => {
                        const filteredDatapoint = newData.find((newDatum) => newDatum.robotSensorsId === recentDatum.robotSensorsId)
                        return {
                            robotSensorsId: recentDatum.robotSensorsId,
                            data: filteredDatapoint ? filteredDatapoint.data : recentDatum.data,
                            timestamp: filteredDatapoint ? moment.utc(filteredDatapoint.creationTimestamp) : recentDatum.timestamp
                        }
                    })

                    setRecentData(updatedData)
                }).catch(error => {

                })

            await axios.post('/api/sensoreventmethods/findActiveEvent', {
                RobotSensorsIds: myRobotSensors.map((robotSensor) => robotSensor.robotSensorsId),
                Username: email
            }, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setRobotSensorsIdsWithEvent(response.data)
                }).catch(() => {
                })


        }, 5000)

        return () => {
            clearIntervalAsync(asyncInterval).then(() => {
            })
        }
    }, [])

    return (
        <div className="NumericalTile">
            <div className="tile-header">
                {robot.robotName} {tileNumber !== 1 ? "(EXT)" : ""}
            </div>
            {generateSensorData()}
        </div>
    )
}

export default NumericalTile;